import * as Yup from "yup";
import {toast} from "react-toastify";
import React, {useEffect, useRef, useState} from "react";
import messageTypes from "../../../constants/MessagesTypes";
import {Form} from "@unform/web";
import CustomButton from "../../../components/Form/Button";
import {Link, useHistory} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import {IoMdSave} from "react-icons/io";
import InputMask from "../../../components/Form/InputMask";
import {currencyFormat} from "../../../utils/masks";
import {InputAdornment} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {edit, getMeta, save} from "../../../services/Meta";
import AlertDelete from "../../../components/Alert/AlertDelete";
import {getBank} from "../../../services/Bank";
import {actions} from "../../../redux/actions";
import {useTranslation} from "react-i18next";

export default function SaveMeta({match}) {
    const [loading, setLoading] = useState(true);
    const [isDelete, setDelete] = useState(null);
    const [meta, setMeta] = useState(0);
    const formRef = useRef(null);
    const history = useHistory();
    const { coinType } = useSelector((state) => state.BankReducer);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    async function handleSubmmit(data) {
        try {
            if (loading) {
                return;
            }

            setLoading(true);
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                stopWin: Yup.string().required(t('input_required')),
                stopLoss: Yup.string().required(t('input_required')),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const formData = new FormData();
            let stopLoss = parseFloat(data.stopLoss.replace(/[^0-9,-]*/g, '').replace(',', '.'));
            stopLoss = stopLoss > 0 ? stopLoss * -1 : stopLoss;
            formData.append('stopWin', data.stopWin.replace(/[^0-9,-]*/g, '').replace(',', '.'));
            formData.append('stopLoss', stopLoss);
            const response = (meta) ? await edit(meta.id, formData) : await save(formData);

            if (response && response.status === 200) {
                toast.success(messageTypes.SUCCESS);
                getBank().then((data) => {
                    dispatch(actions.updateBank(data));
                });
                history.push('/meta');
            } else {
                toast.error(messageTypes.ERROR_SAVE);
            }
            setLoading(false);

        } catch (err) {
            setLoading(false);
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    useEffect(() => {
        getMeta().then((data) => {
            setMeta(data && Object.keys(data).length? data : null);
        }).finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (isDelete) {
            getBank().then((data) => {
                dispatch(actions.updateBank(data));
            });
            history.push('/dashboard');
        }
    }, [isDelete]);

    return (
        <>
            {!loading && <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-5 col-xs-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col d-flex flex-column justify-content-center">
                                    <span className="m-0 font-weight-bold">
                                        <nobr><i className="fa fa-check-square" aria-hidden="true"></i> {t('goal_card_title')}</nobr>
                                    </span>
                                </div>
                                {meta && <div className="col">
                                    <div className="float-right">
                                        <div className="btn btn-xs btn-danger font-weight-bold" onClick={() => {
                                            setDelete(false);
                                        }}>
                                            <i className="fa fa-trash"/> {t('delete')}
                                        </div>
                                    </div>
                                    {isDelete === false && <AlertDelete
                                        text={t('goal_alert_confirm_delete')}
                                        setSuccessDelete={setDelete}
                                        urlDelete={process.env.REACT_APP_API_ENDPOINT+`/api/meta/delete/${meta.id}`}
                                    />}
                                </div>}
                            </div>
                        </div>
                        <div className="card-body pt-4">
                            <Form ref={formRef} onSubmit={handleSubmmit}>
                                <div className="row mb-4">
                                    <div className="col-md-6 col-xs-12 mt-4">
                                        <InputMask
                                            type="text"
                                            name="stopWin"
                                            label={t('goal_label_input_stop_win') + ' *'}
                                            mask={'currency'}
                                            defaultValue={(meta) ? currencyFormat(meta.stopWin) : null}
                                            InputProps={{
                                                startAdornment: <InputAdornment
                                                    position="start">{coinType}</InputAdornment>,
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6 col-xs-12 mt-4">
                                        <InputMask
                                            type="text"
                                            name="stopLoss"
                                            label={t('goal_label_input_stop_loss') + ' *'}
                                            mask={'currency'}
                                            defaultValue={(meta) ? currencyFormat(meta.stopLoss) : null}
                                            InputProps={{
                                                startAdornment: <InputAdornment
                                                    position="start">{coinType}</InputAdornment>,
                                            }}
                                        />
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/meta">
                                            <CustomButton
                                                variant="outlined"
                                                className="btn btn-secondary col-4 float-left font-weight-bold text-capitalize"
                                                startIcon={<BiArrowBack/>}
                                            >
                                                {t('btn_back')}
                                            </CustomButton>
                                        </Link>
                                        <CustomButton
                                            className="btn btn-primary col-4 float-right font-weight-bold text-capitalize"
                                            loading={loading}
                                            type="submit"
                                            startIcon={<IoMdSave/>}
                                        >
                                            {t('btn_save')}
                                        </CustomButton>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}