import styled from 'styled-components';

export const Container = styled.div`
        position: absolute;
        width: 100%;
        height: 250px;
        top: -24px;
        left: 0;
        right: 0;
        z-index: 0;
        background: linear-gradient(to right, #4b5570, #35405a, #2e3851);

        :after {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                content: '';
                background: inherit;
                transform-origin: top right;
                ${(props) => props.isDiagonal ? 'transform: skewY(-3deg);' : ''};
        }
`;
