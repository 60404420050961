import React, {useEffect, useRef, useState} from "react";
import {Form} from "@unform/web";
import * as Yup from "yup";
import {toast} from "react-toastify";
import messageTypes from "../../../constants/MessagesTypes";
import {AiOutlineSearch} from "react-icons/ai";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import CustomButton from "../../../components/Form/Button";
import {dateCurrent, dateFirstDayCurrent, firstDayMonth} from "../../../utils/date";
import InputDate from "../../../components/Form/InputDate";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Alert} from "@material-ui/core";
import TableFooter from "@mui/material/TableFooter";
import {useSelector} from "react-redux";
import {getHistoricoPlacar} from "../../../services/Trading";
import {Container} from "./styles";
import {useTranslation} from "react-i18next";
import {currencyFormat} from "../../../utils/masks";

export default function Placar() {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const {isActiveSignature} = useSelector(
        (state) => state.UserReducer
    );

    const {coinType} = useSelector(
        (state) => state.BankReducer
    );

    const formRef = useRef(null);

    const [filter, setFilter] = useState([
        {
            startDate: dateFirstDayCurrent(),
            endDate: dateCurrent(),
        }
    ]);

    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        getHistoricoPlacar({
            page: page,
            per_page: 25,
            startDate: filter[0].startDate,
            endDate: filter[0].endDate,
        }).then((data) => {
            if (!data || data === '') {
                return;
            }

            setList(data.data);
            setLoading(false);
        });

        return function cleanup() {
            setList([]);
        };
    }, [page, filter]);

    async function handleSubmit(data) {
        try {

            formRef.current.setErrors({});
            const schema = Yup.object().shape({});

            await schema.validate(data, {
                abortEarly: false,
            });

            setFilter(
                [{
                    startDate: data.startDate,
                    endDate: data.endDate,
                }]
            );

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    return (
        <>
            {isActiveSignature && <>
                <Container className="row justify-content-center">
                    <div className="col-lg-10 col-xl-6">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <div className="row">
                                    <div className="col d-flex flex-column justify-content-center">
                                    <span className="m-0 font-weight-bold">
                                        <i className="fa fa-list-ul" aria-hidden="true"></i> {t('history_score_card_title')}
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-4 p-4">
                                <div className="bg-gray-100">
                                    <div className="row">
                                        <div className="col">
                                            <Form ref={formRef} onSubmit={handleSubmit} className="p-3">
                                                <div className="row">
                                                    <div className="col-md-12 pull-left d-flex align-items-center ml-1">
                                                        <div className="font-weight-bold mb-1"><nobr><i className="fas fa-funnel-dollar"></i> {t('filter')}</nobr></div>
                                                    </div>
                                                    <div className="col-md-4 col-xs-12 mt-3">
                                                        <InputDate
                                                            name="startDate"
                                                            label={t('filter_start_date')}
                                                            value={firstDayMonth()}
                                                        />
                                                    </div>
                                                    <div className="col-md-4 col-xs-12 mt-3">
                                                        <InputDate
                                                            name="endDate"
                                                            label={t('filter_end_date')}
                                                            value={new Date()}
                                                        />
                                                    </div>
                                                    <CustomButton
                                                        className="btn btn-primary float-right font-weight-bold text-capitalize mt-3 ml-3 mr-3"
                                                        type="submit"
                                                        startIcon={<AiOutlineSearch/>}
                                                    >
                                                        {t('filter_btn_submmit')}
                                                    </CustomButton>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                {loading ?
                                    <div className="row pl-4 pr-4 pb-4 pt-0">
                                        <div className="col">
                                            <CustomButton
                                                className="btn btn-primary col-12 text-capitalize font-weight-bold"
                                                loading={loading}
                                                type="button"
                                            >
                                                {t('loading')}
                                            </CustomButton>
                                        </div>
                                    </div> :
                                    <>
                                        {list && list.data && list.data.length ?
                                            <>
                                                <div className="row justify-content-center d-flex align-items-center mt-5">
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{width: 450}} aria-label="caption table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell className="font-weight-bold text-muted" align="center">{t('history_score_table_col_date')}</TableCell>
                                                                    <TableCell className="font-weight-bold text-muted" align="center">{t('history_score_table_col_score')}</TableCell>
                                                                    <TableCell className="font-weight-bold text-muted" align="center">{t('profit_prejudice')}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {list.data.map((row) => (
                                                                    <TableRow key={row.id} style={
                                                                        row.profitObtained > 0 ? { background: 'rgb(191 245 226)'} :
                                                                            row.profitObtained < 0 ? { background: 'rgb(251 223 221)'} : {}
                                                                    }>
                                                                        <TableCell align="center" component="th" scope="row">
                                                                            {new Date(row.created.date).toLocaleDateString()}
                                                                        </TableCell>
                                                                        <TableCell align="center">{row.qtdeWin} X {row.qtdeLoss}</TableCell>
                                                                        <TableCell align="center">{coinType} {currencyFormat(parseFloat(row.profitObtained))}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                            <TableFooter>
                                                                <TableCell colSpan="5">
                                                                    <span className="text-muted text-info float-left pl-4 pt-2">
                                                                        {t('table_pagination_text', {
                                                                            'page': page,
                                                                            'totalPage': list.total_page + 1
                                                                        })}
                                                                    </span>
                                                                    <button
                                                                        style={{ cursor: !list.next_page ? 'not-allowed' : 'pointer'}}
                                                                        type="button"
                                                                        onClick={() => setPage(page + 1)}
                                                                        disabled={!list.next_page}
                                                                        className="btn btn-light mr-3 float-right">
                                                                        {t('table_pagination_next')} <IoIosArrowForward
                                                                    />
                                                                    </button>
                                                                    <button
                                                                        style={{ cursor: page <= 1 ? 'not-allowed' : 'pointer'}}
                                                                        type="button"
                                                                        onClick={() => setPage(page - 1)}
                                                                        disabled={page <= 1}
                                                                        className="btn btn-light mr-3 float-right"
                                                                    >
                                                                        <IoIosArrowBack/> {t('table_pagination_back')}
                                                                    </button>
                                                                </TableCell>
                                                            </TableFooter>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            </> :
                                            <div className="row pl-4 pr-4 pb-4 pt-0">
                                                <div className="col">
                                                    <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Container>
            </>}
        </>
    );
}