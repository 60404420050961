import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import Route from './Router';
import Dashboard from '../pages/Dashboard';
import SignUp from "../pages/SignUp";
import SignIn from "../pages/SignIn";
import Trading from "../pages/Trading";
import NewTrading from "../pages/Trading/New";
import ImportTrading from "../pages/Trading/Import";
import TradingReport from "../pages/Report/Trading";
import Deposit from "../pages/Bank/Deposit";
import Retire from "../pages/Bank/Retire";
import Bank from "../pages/Bank";
import Movement from "../pages/Bank/Movement";
import ViewBank from "../pages/Bank/View";
import Profile from "../pages/Profile";
import Mobile from "../pages/Mobile";
import EconomicCalendar from "../pages/EconomicCalendar";
import Plans from "../pages/Plan";
import Calculadora from "../pages/Calculadora";
import Disciplina from "../pages/Disciplina";
import NewDisciplina from "../pages/Disciplina/New";
import DisciplinaReport from "../pages/Report/Disciplina";
import Placar from "../pages/Report/Placar";
import NewBank from "../pages/Bank/New";
import Home from "../pages/Home";
import NewStrategy from "../pages/Strategy/New";
import Strategy from "../pages/Strategy";
import SaveMeta from "../pages/Meta/Save";
import ViewMeta from "../pages/Meta";
import NewEvent from "../pages/Event/New";
import NewFundamentalTrading from "../pages/FundamentalTrading/New";
import PerformanceReport from "../pages/Report/Performance";
import PolicyPrivacy from "../pages/PolicyPrivacy";
import Brokers from "../pages/Brokers";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/login" exact component={SignIn} />
        <Route path="/register" exact component={SignUp} />
        <Route path="/profile" exact component={Profile} isPrivate />
        <Route path="/dashboard" component={Dashboard} isPrivate />
        <Route path="/bank" exact component={Bank} isPrivate />
        <Route path="/bank/view" component={ViewBank} isPrivate />
        <Route path="/bank/deposit" component={Deposit} isPrivate />
        <Route path="/bank/retire" component={Retire} isPrivate />
        <Route path="/bank/new" component={NewBank} isPrivate />
        <Route path="/bank/movements" component={Movement} isPrivate />
        <Route path="/trading" exact component={Trading} isPrivate />
        <Route path="/economic-calendar" exact component={EconomicCalendar} isPrivate />
        <Route path="/trading/report" component={TradingReport} isPrivate />
        <Route path="/trading/report-performance" component={PerformanceReport} isPrivate />
        <Route path="/trading/new" component={NewTrading} isPrivate />
        <Route path="/trading/import" component={ImportTrading} isPrivate />
        <Route path="/trading/:id/edit" component={NewTrading} isPrivate />
        <Route path="/plans" component={Plans} isPrivate />
        <Route path="/calc" component={Calculadora} isPrivate />
        <Route path="/estrategias" exact component={Strategy} isPrivate />
        <Route path="/estrategia/new" component={NewStrategy} isPrivate />
        <Route path="/estrategia/:id/edit" component={NewStrategy} isPrivate />
        <Route path="/meta/save" exact component={SaveMeta} isPrivate />
        <Route path="/meta" exact component={ViewMeta} isPrivate />
        <Route path="/disciplina" exact component={Disciplina} isPrivate />
        <Route path="/disciplina/new" component={NewDisciplina} isPrivate />
        <Route path="/disciplina/:id/edit" component={NewDisciplina} isPrivate />
        <Route path="/disciplina/report" component={DisciplinaReport} isPrivate />
        <Route path="/placar" component={Placar} isPrivate />
        <Route path="/mobile" component={Mobile}/>
        <Route path="/" component={Home} exact/>
        <Route path="/policy-privacy" component={PolicyPrivacy} exact/>
        <Route path="/event/new" component={NewEvent} isPrivate />
        <Route path="/event/:id/edit" component={NewEvent} isPrivate />
        <Route path="/fundamental-trading/new" component={NewFundamentalTrading} isPrivate />
        <Route path="/fundamental-trading/:id/edit" component={NewFundamentalTrading} isPrivate />
        <Route path="/brokers" component={Brokers}/>
        <Route component={Home} />
      </Switch>
    </Router>
  );
}
