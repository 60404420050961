import * as Yup from "yup";
import {toast} from "react-toastify";
import React, {useRef, useState} from "react";
import messageTypes from "../../../constants/MessagesTypes";
import {getBanks, newBank} from "../../../services/Bank";
import {Form} from "@unform/web";
import CustomButton from "../../../components/Form/Button";
import {Link, useHistory} from "react-router-dom";
import InputMask from "../../../components/Form/InputMask";
import {IoMdSave} from "react-icons/io";
import {FaRegMoneyBillAlt} from "react-icons/fa";
import {BiArrowBack} from "react-icons/bi";
import Fieldset from "../../../components/Fieldset";
import InputSelect from "../../../components/Form/InputSelect";
import brokerTypes from "../../../constants/BrokerTypes";
import CkeckboxInput from "../../../components/Form/Checkbox";
import {InputAdornment} from "@material-ui/core";
import {actions} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

export default function NewBank() {
    const [loading, setLoading] = useState(false);
    const [coinType, setCoinType] = useState('R$');
    const formRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    async function handleSaveBank(data) {
        try {
            const initialValue = data.initialValue.replace(/[^0-9,-]*/g, '').replace(',', '.');
            if (initialValue === '0.00') {
                toast.warning(t('bank_warning_value_initial_required'));
                return;
            }

            if (loading) {
                return;
            }

            setLoading(true);
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                initialValue: Yup.string().required(t('input_required')),
                broker: Yup.string().required(t('input_required')),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const formData = new FormData();
            formData.append('initialValue', initialValue);
            formData.append('investmentRate', data.investmentRate);
            formData.append('broker', data.broker);
            formData.append('coinType', coinType);
            const response = await newBank(formData);

            if (response && response.status === 200) {
                toast.success(messageTypes.NEW_BANK);
                getBanks().then((banks) => {
                    dispatch(actions.setBanks(banks));
                    history.push('/dashboard');
                });
            } else {
                toast.error(messageTypes.ERROR);
            }
            setLoading(false);

        } catch (err) {
            setLoading(false);
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold">
                                <i className="fas fa-plus"/> {t('bank_text_add')}
                            </h6>
                        </div>
                        <div className="card-body pt-4">
                            <Form ref={formRef} onSubmit={handleSaveBank}>
                                <div className="row mb-3">
                                    <div className="col">
                                        <InputSelect
                                            type="text"
                                            name="broker"
                                            defaultValue={''}
                                            label={t('bank_broker') + ' *'}
                                            options={brokerTypes}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-6">
                                        <InputMask
                                            type="text"
                                            name="initialValue"
                                            label={t('bank_initial_value') + ' *'}
                                            mask={'currency'}
                                            defaultValue={null}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">{coinType}</InputAdornment>,
                                            }}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <InputMask
                                            type="text"
                                            name="investmentRate"
                                            label={t('bank_investment_rate')}
                                            mask={'percent'}
                                            defaultValue={(3).toFixed(2)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position={'end'}>%</InputAdornment>,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col">
                                        <Fieldset iconLegend={<FaRegMoneyBillAlt/>} textLegend={t('bank_type_currency')} className="scheduler-border">
                                            <div className="form-check">
                                                <CkeckboxInput
                                                    id="coinType"
                                                    name="coinType"
                                                    setValue={setCoinType}
                                                    value={'R$'}
                                                    options={[
                                                        {name: 'coinTypeReal', value: 'R$', label: t('currency_real')},
                                                        {name: 'coinTypeDolar', value: '$', label: t('currency_usd')},
                                                        {name: 'coinTypeDolar', value: '€', label: t('currency_euro')},
                                                    ]}
                                                />
                                            </div>
                                        </Fieldset>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/bank/view">
                                            <CustomButton
                                                variant="outlined"
                                                className="btn btn-secondary col-4 float-left font-weight-bold text-capitalize"
                                                startIcon={<BiArrowBack/>}
                                            >
                                                {t('btn_back')}
                                            </CustomButton>
                                        </Link>
                                        <CustomButton
                                            className="btn btn-primary col-4 float-right font-weight-bold text-capitalize"
                                            loading={loading}
                                            type="submit"
                                            startIcon={<IoMdSave/>}
                                        >
                                            {t('btn_save')}
                                        </CustomButton>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}