import styled from 'styled-components';

export const Container = styled.div`
  label {
    color: #d3d1d1;
  }
  
  input {
    color: white;
  }

  .card {
    border-radius: 10px;
  }

  .btn-facebook, .btn-google {
    background: #f5f5f5;
    height: 50px;
    border-radius: 10px;
  }
  
  .btn-criar {
    height: 50px;
    border-radius: 10px;
  }

  @media(max-width: 1000px) {
    .div-logo {
      display: none;
    }
  }
  
`;
