import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {currencyFormat} from "../../utils/masks";
import {useTranslation} from "react-i18next";

export default function ViewMeta() {
    const {meta, coinType, profitObtainedToday} = useSelector((state) => state.BankReducer);
    const { t } = useTranslation();

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-5 col-sm-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col d-flex flex-column justify-content-center">
                                    <span className="m-0 font-weight-bold">
                                        <nobr><i className="fas fa-wallet"/> {t('goal_card_title')}</nobr>
                                    </span>
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <Link className="btn btn-xs btn-secondary font-weight-bold" to="/meta/save">
                                            <i className="fas fa-edit"/> {t('edit')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-4">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item pl-0 pr-0">
                                            Stop Loss <span className="float-right font-weight-bold">{coinType} {currencyFormat(parseFloat(meta ? meta.stopLoss : 0))}</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center pl-0 pr-0">
                                            Stop Win <span className="float-right font-weight-bold">{coinType} {currencyFormat(parseFloat(meta ? meta.stopWin : 0))}</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center pl-0 pr-0">
                                            {t('goal_profit_prejudice')} <span className="float-right font-weight-bold">{coinType} {currencyFormat(parseFloat(profitObtainedToday ? profitObtainedToday : 0))}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}