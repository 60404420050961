import React from "react";
import {Tooltip} from "@mui/material";

export default function TooltipDefault({ children, ...props}) {

    return (
        <Tooltip arrow {...props}>
            {children}
        </Tooltip>
    );
}