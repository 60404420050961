import i18n from '../services/i18n';

const messageTypes = {
    SUCCESS: i18n.t('alert_success'),
    NEW_BANK: i18n.t('alert_new_bank'),
    SUCCESS_IMPORT: i18n.t('alert_success_import'),
    ERROR_IMPORT: i18n.t('alert_error_import'),
    ERROR: i18n.t('alert_error'),
    ERROR_LOAD: i18n.t('alert_error_load'),
    ERROR_SAVE: i18n.t('alert_error_save'),
    REQUIRED_FIELDS: i18n.t('alert_required_fields'),
    REGISTER_NOT_FOUNT: i18n.t('alert_register_not_found'),
    ERROR_SIGNATURE: i18n.t('alert_error_signature'),
    ERROR_TEST_SIGNATURE: i18n.t('alert_test_signature'),
    ERROR_LOAD_DATA_BANK: i18n.t('alert_error_load_data_bank'),
    SESSION_EXPIRED: i18n.t('alert_session_expired'),
};

export default messageTypes;