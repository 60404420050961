import React, {useEffect, useState} from "react";
import swal from "@sweetalert/with-react";
import api from "../../../services/api";
import {toast} from "react-toastify";
import messageTypes from "../../../constants/MessagesTypes";
import {Redirect} from "react-router-dom";
import i18n from "../../../services/i18n";

function AlertConfirm({
    title = i18n.t('alert_confirm_title'),
    text = '',
    urlCallback,
    functionCallback,
    functionCallbackCancel,
    urlAction,
    data = [],
}) {
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        swal(
            {
                title,
                text,
                icon: "warning",
                buttons: [i18n.t('alert_cancel'), i18n.t('alert_confirm')],
            }
        ).then((isConfirm) => {
            if (isConfirm) {
                api.post(urlAction, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        auth: true
                    },
                }).then((response => {
                    toast.success(messageTypes.SUCCESS);
                    if (functionCallback) {
                        functionCallback();
                    }

                    if (urlCallback) {
                        setRedirect(true)
                    }
                })).catch((exception) => {
                    swal(messageTypes.ERROR, {
                        icon: "error",
                    })
                } );
            } else {
                if (functionCallbackCancel) {
                    functionCallbackCancel();
                }
            }
        });
    }, []);

    return (
        <>
            {redirect && <Redirect to={urlCallback}></Redirect>}
        </>
    );
}

export default AlertConfirm;