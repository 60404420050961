import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {Link, useHistory} from 'react-router-dom';
import {Form} from '@unform/web';
import CryptoJS from 'crypto-js';

import BoxSocial from '../../components/Form/BoxSocial';
import CustomButton from '../../components/Form/Button';
import Input from '../../components/Form/Input';

import {login} from '../../services/Auth';

import {Divider} from "@material-ui/core";
import {Container} from "./styles";
import {MdOutlineLogin} from "react-icons/md";
import logo from "../../assets/img/logo.png";
import Copyright from "../../components/Copyright";
import {useTranslation} from "react-i18next";

function SignIn(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isRedirectMobile, setRedirectMobile] = useState(false);
  const formRef = useRef(null);
  const history = useHistory();
  const [isLoadingAutoLogin, setLoadingAutoLogin] = useState(false);

  const autoLogin = useCallback(() => {
    async function initAutoLogin() {
      let username = localStorage.getItem('username');
      let password = localStorage.getItem('password');

      if (username && password) {
        username = CryptoJS.AES.decrypt(username, process.env.REACT_APP_CRYPTO_SECRET_KEY);
        username = username.toString(CryptoJS.enc.Utf8);

        password = CryptoJS.AES.decrypt(password, process.env.REACT_APP_CRYPTO_SECRET_KEY);
        password = password.toString(CryptoJS.enc.Utf8);

        console.log('fazendo auto login: ');
        const response = await login({
          username,
          password,
        });

        if (response && response.status === 200) {
          localStorage.setItem('@name', username);
          localStorage.setItem('@user_token', response.data.token);

          const redirectUrl = localStorage.getItem('redirectUrl');
          if (redirectUrl) {
            localStorage.removeItem('redirectUrl')
            history.push(redirectUrl);
          } else {
            history.push('/dashboard');
          }
        }
      }
      setLoadingAutoLogin(true);
    }
    initAutoLogin();
  }, [history]);

  useLayoutEffect(autoLogin, []);

  useEffect(() => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const isApp = params.get('isRedirectMobile') || (props.location.state && props.location.state.params && props.location.state.params.isRedirectMobile);
    if (isApp) {
      localStorage.clear();
    }
    setRedirectMobile(isApp);
  }, []);

  async function handleLogin(data) {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
            .email(t('email_invalid'))
            .required(t('blank_email')),
        password: Yup.string().required(t('blank_password'))
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await login({
        username: data.email,
        password: data.password,
      });

      if (response && response.status === 200) {
        if (isRedirectMobile) {
          setLoading(false);
          window.ReactNativeWebView.postMessage('{"name": "'+data.email+'", "user_token" : "'+response.data.token+'"}');
          return;
        }
        localStorage.setItem('@name', data.email);
        localStorage.setItem('userKey', data.email);
        localStorage.setItem('@user_token', response.data.token);

        const username = CryptoJS.AES.encrypt(data.email, process.env.REACT_APP_CRYPTO_SECRET_KEY).toString();
        const password = CryptoJS.AES.encrypt(data.password, process.env.REACT_APP_CRYPTO_SECRET_KEY).toString();

        localStorage.setItem('username', username);
        localStorage.setItem('password',password);

        toast.success(t('login_success'));
        history.push('/dashboard');
      } else {
        toast.error(t('login_invalid'));
      }
      setLoading(false);
    } catch (err) {
      toast.error(t('login_invalid'));
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  useEffect(async () => {
    if (localStorage.getItem('autoLogin')) {
      localStorage.removeItem('autoLogin');
      let data = null;
      try {
        data = await login({
          username: localStorage.getItem('username'),
          password: localStorage.getItem('socialId'),
        });
      } catch (err) {
      }

      if (data && data.status === 200) {
        localStorage.setItem('@user_token', data.data.token);
        toast.success(t('login_success'));
        history.push('/dashboard');
      } else if (data) {
        toast.error(t('login_invalid'));
      }
    }
  }, []);

  return (
      <>
        {isLoadingAutoLogin && <Container className="container">
            <div className="col-12 d-flex flex-column justify-content-center pb-4 logo-sm pt-4">
              <Link to={"/"}>
                <img
                    alt={"Logo GBinary"}
                    width={150}
                    src={logo}
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto'
                    }}
                />
              </Link>
              <br/>
              <p className={"text-white text-center"}>
                {t('login_title')}
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-5 col-sm-12 col-md-7">
                <div className="card o-hidden shadow-lg my-3">
                  <div className="card-body p-0">
                    {/* <!-- Nested Row within Card Body --> */}
                    <div className="row">
                      <div className="col-12" style={{background: '#7e859d'}}>
                        <div className="pl-sm-5 pr-sm-5 pt-sm-2 px-3 mt-xs-5 pt-lg-4 pb-2">
                          <div className="text-center">
                            <br/>
                            <h1 className="h4 mb-4 font-weight-bold text-white">
                              {t('login_enter_account')}
                            </h1>
                          </div>
                          <Form ref={formRef} onSubmit={handleLogin}>
                            <div className="row mb-4 mt-4">
                              <div className="col">
                                <Input
                                    className="text-white"
                                    type="text"
                                    name="email"
                                    label={t('login_label_input_user_email')}
                                />
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col">
                                <Input
                                    type="password"
                                    name="password"
                                    label={t('login_label_input_user_password')}
                                />
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col">
                                <CustomButton startIcon={<MdOutlineLogin/>} className="btn btn-primary col-12 text-capitalize font-weight-bold btn-entrar" loading={loading} type="submit">
                                  {t('login_btn')}
                                </CustomButton>
                              </div>
                            </div>
                            <div className="row  mb-3">
                              <div className="col text-white">
                                <Divider>{t('login_label_login_google')}</Divider>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <BoxSocial/>
                            </div>
                            <hr/>
                            <div className="row">
                              <div className="col">
                                <p className="text-center text-white">
                                  {t('login_not_account')}
                                  <Link className="font-weight-bold text-white pl-1"
                                        to={{
                                          pathname: '/register',
                                          state: {
                                            params: {
                                              isRedirectMobile: `${isRedirectMobile}`,
                                            }
                                          }
                                        }}
                                  >
                                    <u>{t('login_register')}</u>
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-4">
              <Copyright/>
            </div>
          </Container>}
      </>
  );
}

export default SignIn;
