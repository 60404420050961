import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Alert, IconButton} from "@material-ui/core";
import {MdDeleteForever, MdEdit} from "react-icons/md";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import AlertDelete from "../../components/Alert/AlertDelete";
import CustomButton from "../../components/Form/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import messageTypes from "../../constants/MessagesTypes";
import {useSelector} from "react-redux";
import {getFundamentalistTradings} from "../../services/FundamentalTrading";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import swal from "@sweetalert/with-react";
import {useTranslation} from "react-i18next";

export default function FundamentalTrading({deleteItem, setDeleteItem}) {
    const [open, setOpen] = React.useState(null);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [page, setPag] = useState(1);
    const { t } = useTranslation();
    const {isActiveSignature} = useSelector(
        (state) => state.UserReducer
    );

    useEffect(() => {
        setLoading(true);
        getFundamentalistTradings({
            page: page,
            per_page: 25,
        }).then((data) => {
            if (!data || data === '') {
                return;
            }

            setList(data.data);
            setLoading(false);
        });

        return function cleanup() {
            setList([]);
        };
    }, [page, deleteItem === true]);

    function showImage(urlImage) {
        swal(
            {
                showCloseButton: true,
                className: 'trading-modal',
                content: <div>
                    <div className="work_item">
                        <div className={"img-trading-modal"}>
                            <img src={urlImage} width={'100%'} height={"100%"} alt={'Imagem da negociação'}/>
                        </div>
                    </div>
                </div>,
            }
        );
    }

    return (
        <>
            {isActiveSignature && <>
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="">
                            <div className="card-header py-3">
                                <div className="row">
                                    <div className="col">
                                        <div className="float-right">
                                            <Link className="ml-2 btn btn-xs btn-success font-weight-bold text-white" to="/fundamental-trading/new">
                                                <i className="fas fa-plus"/> {t('fundamentalist_add')}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-4 p-0">
                                {loading ?
                                    <div className="row pl-4 pr-4 pb-4 pt-0">
                                        <div className="col">
                                            <CustomButton
                                                className="btn btn-primary col-12 text-capitalize font-weight-bold"
                                                loading={loading}
                                                type="button"
                                            >
                                                {t('loading')}
                                            </CustomButton>
                                        </div>
                                    </div> :
                                    <>
                                        {list && list.data && list.data.length ?
                                            <>
                                                <TableContainer component={Paper}>
                                                    <Table aria-label="collapsible table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className="font-weight-bold text-muted" align="center"/>
                                                                <TableCell className="font-weight-bold text-muted" align="center">{t('fundamentalist_table_col_date')}</TableCell>
                                                                <TableCell className="font-weight-bold text-muted" align="center">{t('fundamentalist_table_col_event')}</TableCell>
                                                                <TableCell className="font-weight-bold text-muted" align="center">{t('fundamentalist_table_col_options')}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {list.data.map((row, index) => (
                                                                <>
                                                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={"trading_"+index}>
                                                                        <TableCell>
                                                                            <IconButton
                                                                                aria-label="expand row"
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    setOpen(row.id !== open ? row.id : null);
                                                                                }}
                                                                            >
                                                                                {open === row.id ? <IoIosArrowUp/> : <IoIosArrowDown/>}
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row" align="center">
                                                                            <small>
                                                                                {(new Date(row.created.date).toLocaleString()).replace(/,/g, "").slice(0, -3)}
                                                                            </small>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <small>
                                                                                {row.event.name}
                                                                            </small>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <Link to={`/fundamental-trading/${row.id}/edit`} className={"d-none"}>
                                                                                <IconButton
                                                                                    aria-label="edit"
                                                                                >
                                                                                    <MdEdit/>
                                                                                </IconButton>
                                                                            </Link>
                                                                            <IconButton
                                                                                aria-label="delete"
                                                                                onClick={() => {
                                                                                    setDeleteItem(row.id)
                                                                                }}
                                                                            >
                                                                                <MdDeleteForever/>
                                                                                {row.id === deleteItem && <AlertDelete
                                                                                    setSuccessDelete={setDeleteItem}
                                                                                    urlDelete={process.env.REACT_APP_API_ENDPOINT+`/api/fundamental-trading/delete/${row.id}`}
                                                                                />}
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow key={index+"_times"}>
                                                                        <TableCell style={{ padding: 0 }} colSpan={6}>
                                                                            <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                                                                                <Box  className={"p-5"} style={{
                                                                                    background: '#d1d1d5'
                                                                                }}>
                                                                                    <h6 className={"text-muted mb-4"}>{t('fundamentalist_table_col_result')}</h6>
                                                                                    <Table size="small" aria-label="purchases">
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                <TableCell/>
                                                                                                <TableCell className="font-weight-bold text-muted" align="center">{t('fundamentalist_table_col_time')}</TableCell>
                                                                                                <TableCell className="font-weight-bold text-muted" align="center">{t('fundamentalist_table_col_img')}</TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {row.expireTimes.map((expireTime) => (
                                                                                                <TableRow key={expireTime.id}>
                                                                                                    <TableCell component="th" scope="row">
                                                                                                        {expireTime.win && <span className="badge badge-success">Win</span>}
                                                                                                        {expireTime.loss && <span className="badge badge-danger">Loss</span>}
                                                                                                    </TableCell>
                                                                                                    <TableCell align="center">
                                                                                                        {expireTime.time}
                                                                                                    </TableCell>
                                                                                                    <TableCell align="center" className={"d-flex flex-column align-items-center"}>
                                                                                                        {expireTime.image ? <div className="img-trading cursor-pointer">
                                                                                                            <img
                                                                                                                onClick={() => {
                                                                                                                    showImage(`${process.env.REACT_APP_API_ENDPOINT}/${expireTime.image}`);
                                                                                                                }}
                                                                                                                width={'100%'}
                                                                                                                height={"100%"}
                                                                                                                src={`${process.env.REACT_APP_API_ENDPOINT}/${expireTime.image}`}
                                                                                                                alt={"Imagem da negociação em "+ expireTime.time}
                                                                                                            />
                                                                                                        </div> : <br/>}

                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            ))}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </Box>
                                                                            </Collapse>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </>
                                             :
                                            <div className="row pl-4 pr-4 pb-4 pt-0">
                                                <div className="col">
                                                    <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    );
}