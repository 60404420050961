import React from 'react';
import googlePlay from "../../assets/img/google-play.png";
import logo from "../../assets/img/logo.png";

function Mobile() {

    return (
        <>
            <div className="col-md-12 text-center">
                <div className="row">
                    <div className="col-md-12 text-center justify-content-center d-flex">
                        <img
                            width={200}
                            src={logo}
                            style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12 text-white text-center h4">
                        <p>Plataforma para gerenciamento de operações de opções binárias.</p>
                        <p>Nossos recursos exclusivos vão impulsionar suas análises, otimizar seu desempenho, facilitar o gerenciamento e aumentar sua assertividade.</p>
                    </div>
                </div>
                <div className="row justify-content-center d-flex mt-4">
                    <div className="h4 text-white col-md-6 col-sm-12 col-lg-6">
                        Atenção! Nossa plataforma ainda não está totalmente compatível para ser acessada pelo celular, enquanto isso você pode realizar seu acesso normalmente pelo computador.
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12 text-center justify-content-center d-flex">
                        <img
                            width={250}
                            src={googlePlay}
                            style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Mobile;
