import React, {useEffect, useState} from "react";
import {getDataGroupByMonth} from "../../../services/Trading";
import {Alert} from "@material-ui/core";
import messageTypes from "../../../constants/MessagesTypes";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useTranslation} from "react-i18next";

export default function TradingAgroupMonth({year, filter, ...props}) {
    const [isData, setData] = useState(null);
    const [options, setOptions] = useState([]);
    const { t } = useTranslation();

    useEffect( () => {

        getDataGroupByMonth({
            year: year,
            direction: filter[0].direction,
        }).then((data) => {
            if (!data || data === '' || !data.data) {
                setData(false);
                return;
            }

            setData(true);

            var dataWin = [];
            var dataLoss = [];
            var dataDraw = [];
            for (var key in data.data.data) {
                var item = data.data.data[key];
                dataWin.push(item['qtdeWin']);
                dataLoss.push(item['qtdeLoss']);
                dataDraw.push(item['qtdeDraw']);
            }

            setOptions({
                title: {
                    text: ''
                },
                subtitle: {
                    text: ``
                },
                chart: {
                    type: 'column'
                },
                xAxis: {
                    categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                    crosshair: false
                },
                series: [
                    {
                        name: t('defeats'),
                        color: '#e74a3b',
                        data: dataLoss
                    },
                    {
                        name: t('draws'),
                        color: '#848796',
                        data: dataDraw
                    },
                    {
                        name: t('victories'),
                        color: '#1cc88a',
                        data: dataWin
                    },
                ],
                yAxis: {
                    min: 0,
                    title: {
                        text: t('operations')
                    }
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
            });
        });
    }, [filter]);

    return (
        <div {...props}>
            {isData ?
                <>
                    <div className="col">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                </> :
                <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
            }
        </div>
    );
}
