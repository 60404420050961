import React, {useRef, useState} from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import {Checkbox, FormControlLabel} from "@material-ui/core";

function CkeckboxInput({ name, value, options, setValue, ...props }) {
    const { fieldName, registerField, defaultValue } = useField(name);
    const inputRef = useRef(null)
    const formGroupRef = useRef(null)
    const [valueChecked, setValueChecked] = useState(value);

    React.useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    React.useEffect(() => {
        setValue(valueChecked)
    }, [valueChecked]);

    React.useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            clearValue: (refs) => {
                refs.forEach(ref => {
                    ref.checked = false;
                });
            },
            setValue: (refs, values) => {
                refs.forEach(ref => {
                    if (values.includes(ref.id)) {
                        ref.checked = true;
                    }
                });
            }
        });
    }, [defaultValue, fieldName, registerField]);

    return (
        <>
            <div ref={formGroupRef} className='row'>
                {options.length && options.map((option, index) => (
                    <div className="col-6" key={index}>
                        <FormControlLabel
                            key={index}
                            label={<span style={{color: 'rgba(0, 0, 0, 0.6)'}}>{option.label}</span>}
                            control={
                                <Checkbox
                                    checked={valueChecked === option.value}
                                    onChange={e => setValueChecked(e.target.value)}
                                    name={option.name}
                                    inputRef={inputRef}
                                    value={option.value}
                                    {...props}
                                />
                            }
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

export default CkeckboxInput;

CkeckboxInput.propTypes = {
    name: PropTypes.string.isRequired,
};

