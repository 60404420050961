export function cep(value) {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{5})(\d)/, "$1-$2");

    return value;
}

export function currencyChange(value, prefix = null) {
    if (value === null || value === undefined || value === '') {
       return value;
    }

    if (value === '-') {
        return value;
    }

    if(typeof value !== 'string') {
        value = value.toString();
    }

    if (value.includes('-')) {
        value = value.replace('-', '');
        value = '-' + value;
    }

    value = value.replace(/[^0-9.-]+/g,"");
    value = value.replace('.', '').replace(',', '')

    const options = { minimumFractionDigits: 2 }
    value = new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(value) / 100
    )
    value = prefix && value ? prefix+' '+value : value;
    if (value === '-0,00') {
        value = '0,00'
    }

    return value;
}

export function percentChange(value, prefix = null) {
    if (value === null || value === undefined || value === '') {
       return value;
    }

    if (value === '-') {
        return value;
    }

    if(typeof value !== 'string') {
        value = value.toString();
    }
    value = value.replace(/[^0-9.]+/g,"");
    value = prefix && value ? prefix+' '+value : value;
    return value;
}

export function currencyFormat(value, prefix = null) {
    if (value === null || value === undefined || value === '') {
        value = '0,00';
    }

    if(typeof value !== 'string') {
        value = value.toFixed(2);
        value = value.toString();
    }

    value = value.replace('.', '').replace(',', '')

    const options = { minimumFractionDigits: 2 }
    value = new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(value) / 100
    )
    value = prefix && value ? prefix+' '+value : value;
    return value;
}

export function date(value) {
    value = value.replace(/\D/g,'').slice(0, 10);
    if (value.length >= 5) {
        return `${value.slice(0,2)}/${value.slice(2,4)}/${value.slice(4)}`;
    }
    else if (value.length >= 3) {
        return `${value.slice(0,2)}/${value.slice(2)}`;
    }
    return value;
}