import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import {Container} from "./styles";

const WhatsAppButton = () => {
    const handleClick = () => {
        const url = `https://web.whatsapp.com/send?phone=38999361310`;
        window.open(url, '_blank');
    };

    return (
        <Container onClick={handleClick}>
            <FaWhatsapp size={32} />
        </Container>
    );
};

export default WhatsAppButton;
