import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        background: linear-gradient(0deg, #1c1f2d, #1c1f2d), #f2f4f9;
        box-sizing: border-box;
        font-family: 'Nunito', Helvetica, Sans-Serif !important;
    }

    .align-vertical {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .broker-binolla {
        height: 70px;
        width: 130px;
        border-radius: 5px;
        display: inline-block;
        font-size: 30px;
        color: #222222;
        margin-bottom: 30px;

        img {
            object-fit: contain;
            border-radius: 10px;
        }
    }

    .img-trading-modal {
        border-radius: 5px;
        display: inline-block;
        font-size: 30px;
        color: #222222;
        width: 90%;

        img {
            object-fit: contain;
            border-radius: 10px;
            width: 100%;
            max-width: 720px;
            max-height: 403px;
        }
    }

    .trading-modal {
        width: 50%;
        height: 62%;
    }

    .img-trading {
        height: 50px;
        width: 65px;
        border: 1px solid rgba(134, 136, 134, 0.63);
        border-radius: 5px;
        background: #f9f9ff;
        font-size: 30px;
        color: #222222;

        img {
            border-radius: 5px;
            object-fit: cover;
        }
    }

    .kep-login-facebook {
        text-transform: capitalize !important;
        background-color: transparent !important;
        border: none !important;
        padding: 0px !important;
        color: blue !important;
        font-size: 0.875rem !important;
    }

    .dropdown-item {
        cursor: pointer;
    }

    .btn-group-xs > .btn, .btn-xs {
        padding: 1px 5px !important;
        font-size: 12px !important;
        line-height: 1.5 !important;
        border-radius: 3px !important;
    }

    .swal-text {
        text-align: center;
    }

    .fa-1x {
        font-size: 20px;
    }

    .cursor-pointer {
        cursor: pointer;
    }
`;

export default GlobalStyle;
