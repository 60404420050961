import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Alert, IconButton} from "@material-ui/core";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {MdDeleteForever, MdEdit} from "react-icons/md";
import AlertDelete from "../../components/Alert/AlertDelete";
import CustomButton from "../../components/Form/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import messageTypes from "../../constants/MessagesTypes";
import {useSelector} from "react-redux";
import {getEvents} from "../../services/Event";
import {useTranslation} from "react-i18next";

export default function Events() {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [deleteEvent, setDeleteEvent] = useState(null);
    const { t } = useTranslation();
    const {isActiveSignature} = useSelector(
        (state) => state.UserReducer
    );

    useEffect(() => {
        setLoading(true);
        getEvents({
            page: page,
            per_page: 25,
        }).then((data) => {
            if (!data || data === '') {
                return;
            }

            setList(data.data);
            setLoading(false);
        });

        return function cleanup() {
            setList([]);
        };
    }, [page, deleteEvent === true]);

    return (
        <>
            {isActiveSignature && <>
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="">
                            <div className="card-header py-3">
                                <div className="row">
                                    <div className="col">
                                        <div className="float-right">
                                            <Link className="ml-2 btn btn-xs btn-success font-weight-bold text-white" to="/event/new">
                                                <i className="fas fa-plus"/> {t('event_add')}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-4 p-0">
                                {loading ?
                                    <div className="row pl-4 pr-4 pb-4 pt-0">
                                        <div className="col">
                                            <CustomButton
                                                className="btn btn-primary col-12 text-capitalize font-weight-bold"
                                                loading={loading}
                                                type="button"
                                            >
                                                {t('loading')}
                                            </CustomButton>
                                        </div>
                                    </div> :
                                    <>
                                        {list && list.data && list.data.length ?
                                            <TableContainer component={Paper}>
                                                <Table aria-label="caption table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className="font-weight-bold text-muted" align="center">{t('event_table_col_event')}</TableCell>
                                                            <TableCell className="font-weight-bold text-muted" align="center">{t('event_table_col_options')}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {list.data.map((row) => (
                                                            <TableRow key={row.id}>
                                                                <TableCell align="center">
                                                                    <small>
                                                                        {row.name}
                                                                    </small>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Link to={`/event/${row.id}/edit`}>
                                                                        <IconButton
                                                                            aria-label="edit"
                                                                        >
                                                                            <MdEdit/>
                                                                        </IconButton>
                                                                    </Link>
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        onClick={() => {
                                                                            setDeleteEvent(row.id)
                                                                        }}
                                                                    >
                                                                        <MdDeleteForever/>
                                                                        {row.id === deleteEvent && <AlertDelete
                                                                            setSuccessDelete={setDeleteEvent}
                                                                            urlDelete={process.env.REACT_APP_API_ENDPOINT+`/api/event/delete/${row.id}`}
                                                                        />}
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableCell colSpan="5">
                                                            <span className="text-muted text-info float-left pl-4 pt-2">
                                                                {t('table_pagination_text', {
                                                                    'page': page,
                                                                    'totalPage': list.total_page + 1
                                                                })}
                                                            </span>
                                                            <button
                                                                style={{ cursor: !list.next_page ? 'not-allowed' : 'pointer'}}
                                                                type="button"
                                                                onClick={() => setPage(page + 1)}
                                                                disabled={!list.next_page}
                                                                className="btn btn-light mr-3 float-right">
                                                                {t('table_pagination_next')} <IoIosArrowForward
                                                            />
                                                            </button>
                                                            <button
                                                                style={{ cursor: page <= 1 ? 'not-allowed' : 'pointer'}}
                                                                type="button"
                                                                onClick={() => setPage(page - 1)}
                                                                disabled={page <= 1}
                                                                className="btn btn-light mr-3 float-right"
                                                            >
                                                                <IoIosArrowBack/> {t('table_pagination_back')}
                                                            </button>
                                                        </TableCell>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer> :
                                            <div className="row pl-4 pr-4 pb-4 pt-0">
                                                <div className="col">
                                                    <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    );
}