import React, {useEffect} from 'react';


import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../../redux/actions';
import userImg from '../../../assets/img/undraw_profile.svg';
import {getBank, getBanks, setDefaultBank, setHideAmount} from "../../../services/Bank";
import {currencyFormat} from "../../../utils/masks";
import {BiCaretDown,} from "react-icons/bi";
import {Link, useHistory} from "react-router-dom";
import {Container} from "./styles";
import {getShowModalInfo, getSignature, setShowModalInfo} from "../../../services/Profile";
import swal from "@sweetalert/with-react";
import brokerTypes from "../../../constants/BrokerTypes";
import googlePlayImg from "../../../assets/img/google-play.png";
import {useTranslation} from "react-i18next";
import ImgOptinary from "../../../assets/img/optinary.png";

function Topbar () {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {id, hideAmount, amount, coinType, banks, broker} = useSelector(
      (state) => state.BankReducer
  );

  const {isActiveSignature, isFreeSignature} = useSelector(
      (state) => state.UserReducer
  );

  function logout() {
    localStorage.clear();
    window.location="/";
  }

  function changeHideAmount(hideAmount) {
    const data = new FormData();
    data.append('hideAmount', hideAmount);
    setHideAmount(data).then(() => {
      dispatch(actions.setHideAmount(hideAmount));
    });
  }

  useEffect(() => {
    getBank().then((data) => {
      dispatch(actions.updateBank(data));
    });

    getBanks().then((banks) => {
      dispatch(actions.setBanks(banks));
    });

    getSignature().then((data) => {
      if (!data) {
        return;
      }
      dispatch(actions.setActiveSignature(data.data.isSignatureActive));
      dispatch(actions.setIsEnableFreeSignature(data.data.isEnableFreeSignature));
      dispatch(actions.setIsFreeSignature(data.data.isFree));
      dispatch(actions.setDueDateSignature(data.data.dueDate ? new Date(data.data.dueDate.date).toLocaleDateString() : null));
    });

    getShowModalInfo().then((data) => {
      if (data.data.showModalInfo) {
        dispatch(actions.setShowModalInfo(true));
        setShowModalInfo(false).then(r => {});

        swal(
            {
              buttons: ["Fechar", "Criar conta"],
              icon: "info",
              content: <div>
                <h1 className={"font-weight-bolder"}>Olá! Conheça a corretora Optinary</h1>
                <br/>
                Como um investidor em opções binárias, você sabe que é importante diversificar sua carteira de investimentos para minimizar o risco e maximizar os lucros. E uma maneira de fazer isso é ter contas em diferentes corretoras de opções binárias.
                <br/>
                <br/>
                Gostaríamos de apresentar a você uma nova corretora de opções binárias que pode atender às suas necessidades de investimento.
                <br/>
                <br/>
                <div className="work_item">
                  <div className={"broker-binolla"}>
                    <a href={"https://trade.optinary.com/register?aff=407200&aff_model=revenue&afftrack="} target={"_blank"}>
                      <img src={ImgOptinary} width={'100%'} height={"100%"} alt={'Imagem corretora Optinary'}/>
                    </a>
                  </div>
                </div>
              </div>,
            }
        ).then((isConfirm) => {
          if (isConfirm) {
            window.open('https://trade.bull-ex.com/register?aff=381650&aff_model=revenue&afftrack=', '_blank');
          }
        });


        /*swal(
            {
              button: false,
              content: <div>
                <h1 className={"font-weight-bolder"}>{t('header_modal_app_text_1')}</h1>
                <br/>
                {t('header_modal_app_text_2')}
                <br/>
                <br/>
                {t('header_modal_app_text_3')}
                <br/>
                <br/>
                <div className="work_item">
                  <div>
                    <a href={"https://play.google.com/store/apps/details?id=com.appgbinary"} target={"_blank"}>
                      <img src={googlePlayImg} width={'100%'} height={"100%"} alt={'Imagem Google Play'}/>
                    </a>
                  </div>
                </div>
              </div>,
            }
        );*/
      }
    });
  }, []);

  function getBroker(value) {
    for (var index in brokerTypes) {
      if (brokerTypes[index].key === value) {
        return brokerTypes[index].value
      }
    }

    return null
  }

  function selectBank(bankId) {
    setDefaultBank(bankId).then((data) => {
      window.location.href = '/dashboard';
    });
  }

  return (
    <Container className="navbar navbar-expand navbar-light topbar mb-4 static-top shadow">
      <button onClick={() => { dispatch(actions.clickMenuOpen()); }}  id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
        <i className="fa fa-bars"/>
      </button>
      {isActiveSignature === false && <>
        <div className={"cursor-pointer"} onClick={() => {
          history.push('/plans');
        }}>
        <span className="badge p-2 text-warning" style={{ background: '#2e3851', border: '2px solid white'}}>
          <span className={"font-weight-bolder h5"}>{t('header_signature_expired')}</span>
          <br/>
          <small className={"font-weight-bolder text-white"}>
            <i className="fa fa-info-circle" aria-hidden="true"/> {t('header_signature_active')}
          </small>
        </span>
        </div>
      </>}
      {isFreeSignature === true && <>
        <div className={"cursor-pointer"} onClick={() => {
          history.push('/plans');
        }}>
        <span className="badge p-2 text-success" style={{ background: '#2e3851', border: '2px solid white'}}>
          <span className={"font-weight-bolder h5"}>{t('header_btn_signature_text_1')}</span>
          <br/>
          <small className={"font-weight-bolder text-white"}>
            <i className="fa fa-info-circle" aria-hidden="true"/> {t('header_btn_signature_text_2')}
          </small>
        </span>
        </div>
      </>}
      <ul className="navbar-nav ml-auto">
        {id && <li className="nav-item dropdown no-arrow">
          <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="text-white small">
                {t('header_bank')} <b>{broker ? getBroker(broker) : ''}</b>:
            </span>
            <span
                className="ml-1 d-flex justify-content-center font-weight-bold h5 mr-1"
                style={{cursor: 'pointer', color: 'rgb(10 255 25)'}}
            >
              {!hideAmount ?
                  <nobr className="mt-2">{coinType} {currencyFormat(amount)}</nobr> :
                  <>
                    <label style={{marginTop: '21px'}}>{coinType}</label>
                    <label className="mr-2 ml-2" style={{marginTop: '25px'}}>******</label>
                  </>
              }
            </span>
            <span className="text-white small font-weight-bolder">
              <BiCaretDown size={22}/>
            </span>
          </a>
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <div className="dropdown-item font-weight-bolder">
              <span>{t('header_options')}</span>
              <hr className={"mt-1 mb-0"}/>
            </div>
            <span className="dropdown-item" onClick={() => changeHideAmount(!hideAmount)}>
              {!hideAmount ?
                  <>
                    <i className="fa fa-eye-slash fa-sm fa-fw mr-2 text-gray-400"></i>
                    {t('header_show_balance')}
                  </> :
                  <>
                    <i className="fa fa-eye fa-sm fa-fw mr-2 text-gray-400"></i>
                    {t('header_hide_balance')}
                  </>
              }
            </span>
            <Link to="/bank/new" className="dropdown-item">
              <i className="fa fa-plus fa-sm fa-fw mr-2 text-gray-400"></i>
              {t('header_add_bank')}
            </Link>
            {banks.length && <div className="dropdown-item font-weight-bolder mt-2">
              <span>{t('header_select_bank')}</span>
              <hr className={"mt-1 mb-0"}/>
            </div>}
            {banks.length && banks.map((bank, index) => (
                <span className="dropdown-item" key={index} onClick={() => {selectBank(bank.id)}}>
                {getBroker(bank.broker)}
              </span>
            ))}
          </div>
        </li>}
        <div className="topbar-divider d-none d-sm-block"></div>
        <li className="nav-item dropdown no-arrow">
          <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="mr-2 d-none d-lg-inline text-white small font-weight-bold">{localStorage.getItem('@name')}</span>
            <img className="img-profile rounded-circle" src={userImg}/>
          </a>
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <Link to="/profile" className="dropdown-item">
              <i className="far fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
              {t('header_perfil')}
            </Link>
            <span className="dropdown-item" onClick={() => { logout() }}>
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              {t('header_logout')}
            </span>
          </div>
        </li>
      </ul>
    </Container>
  )
}

export default Topbar;