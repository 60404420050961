import React, {useEffect, useRef, useState} from "react";
import CustomButton from "../../Form/Button";
import {Alert} from "@material-ui/core";
import messageTypes from "../../../constants/MessagesTypes";
import {getReport} from "../../../services/FundamentalTrading";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {dateCurrent, dateFirstDayCurrent, firstDayMonth} from "../../../utils/date";
import {Form} from "@unform/web";
import InputDate from "../../Form/InputDate";
import {AiOutlineSearch} from "react-icons/ai";
import * as Yup from "yup";
import {toast} from "react-toastify";
import InputSelect from "../../Form/InputSelect";
import {getEvents} from "../../../services/Event";
import {useTranslation} from "react-i18next";

export default function EventReport({deleteItem, ...props}) {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [listReportTime, setListReportTime] = useState(null);
    const formRef = useRef(null);
    const [events, setEvents] = useState(null);
    const { t } = useTranslation();

    const [filter, setFilter] = useState([
        {
            startDate: dateFirstDayCurrent(),
            endDate: dateCurrent(),
            eventId: null,
        }
    ]);

    useEffect(() => {
        getEvents().then((data) => {
            if (data && data.data && data.data.data && data.data.data.length) {
                const list = data.data.data;
                let arrayList = [];
                for (var index in list) {
                    const events = list[index];
                    arrayList.push({
                        key: events.id,
                        value: events.name
                    });
                }
                setEvents(arrayList);
            } else {
                setEvents([]);
            }
        });
    }, [deleteItem]);

    useEffect(() => {
        setLoading(true);
        getReport({
            startDate: filter[0].startDate,
            endDate: filter[0].endDate,
            eventId: filter[0].eventId,
        }).then((data) => {
            if (!data || data === '' || !data.data) {
                return;
            }

            setList(data.data.data);
            setListReportTime(data.data.dataReportTime);
            setLoading(false);
        });

        return function cleanup() {
            setList([]);
        };
    }, [filter, deleteItem]);

    async function handleSubmit(data) {
        try {

            formRef.current.setErrors({});
            const schema = Yup.object().shape({});

            await schema.validate(data, {
                abortEarly: false,
            });

            setFilter(
                [{
                    startDate: data.startDate,
                    endDate: data.endDate,
                    eventId: data.eventId,
                }]
            );

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    return (
        <div {...props}>
            <div className="row mb-5">
                <div className="col-12">
                    <div className="bg-gray-100">
                        <div className="row">
                            <div className="col">
                                <Form ref={formRef} onSubmit={handleSubmit} className="p-3">
                                    <div className="row">
                                        <div className="col-md-12 pull-left d-flex align-items-center ml-1">
                                            <div className="font-weight-bold mb-1"><nobr><i className="fas fa-funnel-dollar"></i> {t('filter')}</nobr></div>
                                        </div>
                                        <div className="col-md-4 col-xs-12 mt-3">
                                            <InputDate
                                                name="startDate"
                                                label={t('filter_start_date')}
                                                value={firstDayMonth()}
                                            />
                                        </div>
                                        <div className="col-md-4 col-xs-12 mt-3">
                                            <InputDate
                                                name="endDate"
                                                label={t('filter_end_date')}
                                                value={new Date()}
                                            />
                                        </div>
                                        <div className="col-md-4 col-xs-12 mt-3">
                                            {events && <InputSelect
                                                type="text"
                                                name="eventId"
                                                label={t('filter_select_event')}
                                                options={events}
                                                defaultValue={''}
                                            />}
                                        </div>
                                        <CustomButton
                                            className="btn btn-primary float-right font-weight-bold text-capitalize mt-3 ml-3 mr-3"
                                            type="submit"
                                            startIcon={<AiOutlineSearch/>}
                                        >
                                            {t('filter_btn_submmit')}
                                        </CustomButton>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 p-0">
                {loading ?
                    <CustomButton
                        className="btn btn-primary col-12 text-capitalize font-weight-bold"
                        loading={loading}
                        type="button"
                    >
                        {t('loading')}
                    </CustomButton> :
                    <>
                        {list && list.length && listReportTime ?
                            <TableContainer component={Paper}>
                                <Table aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                className="font-weight-bold text-muted"
                                            >
                                            </TableCell>
                                            <TableCell
                                                className="font-weight-bold text-muted"
                                            >
                                                <nobr>{t('fundamentalist_report_table_col_operations')}</nobr>
                                            </TableCell>
                                            <TableCell
                                                className="font-weight-bold text-muted"
                                            >
                                                <nobr>{t('fundamentalist_report_table_col_time')}</nobr>
                                            </TableCell>
                                            <TableCell
                                                className="font-weight-bold text-muted"
                                            >
                                                <nobr>{t('fundamentalist_report_table_col_assertiveness')}</nobr>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{row.nameEvent}</TableCell>
                                                <TableCell>
                                                    {row.count} {t('fundamentalist_report_table_col_input')}<br/>
                                                    <span className="text-muted text-xs">{row.qtdeWin} {t('victories')} / </span>
                                                    <span className="text-muted text-xs">{row.qtdeLoss} {t('defeats')}</span>
                                                </TableCell>
                                                <TableCell>
                                                    {listReportTime[row.idEvent].time}
                                                </TableCell>
                                                <TableCell>
                                                    {parseInt(row.qtdeLoss) + parseInt(row.qtdeWin) && <>
                                                        {parseFloat((parseInt(row.qtdeLoss) + parseInt(row.qtdeWin)) ? row.qtdeWin * 100 / (parseInt(row.qtdeLoss) + parseInt(row.qtdeWin)) : 0) >= 70 && <span className="badge badge-success" style={{ fontSize: '14px'}}>
                                                            {parseFloat((parseInt(row.qtdeLoss) + parseInt(row.qtdeWin)) ? row.qtdeWin * 100 / (parseInt(row.qtdeLoss) + parseInt(row.qtdeWin)) : 0).toFixed(2) + '%'}
                                                        </span>}
                                                        {parseFloat((parseInt(row.qtdeLoss) + parseInt(row.qtdeWin)) ? row.qtdeWin * 100 / (parseInt(row.qtdeLoss) + parseInt(row.qtdeWin)) : 0) < 70 && <span className="badge badge-danger txt-lg" style={{ fontSize: '14px'}}>
                                                            {parseFloat((parseInt(row.qtdeLoss) + parseInt(row.qtdeWin)) ? row.qtdeWin * 100 / (parseInt(row.qtdeLoss) + parseInt(row.qtdeWin)) : 0).toFixed(2) + '%'}
                                                        </span>}
                                                    </>}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> :
                            <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                        }
                    </>
                }
            </div>
        </div>
    );
}