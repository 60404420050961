import React, {useCallback, useRef, useState} from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';
import {cep, currencyChange, percentChange} from '../../../utils/masks'

import {TextField} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";

const CustomTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
        },
    },
})(TextField);

function InputMask({ name, label, mask, prefix, ...props }) {
    const inputRef = React.useRef(null);
    const materialInputRef = useRef(null);
    const { fieldName, registerField, error } = useField(name);
    const [value,] = useState(props.value);

    const handleKeyUp = useCallback(
      (e) => {

        let value = inputRef.current.value;
        if (mask === 'cep') {
            inputRef.current.maxLength = 9;
            value = cep(value);
            inputRef.current.value = value;
        } else if (mask === 'currency') {
            value = currencyChange(value);
        } else if (mask === 'percent') {
            value = percentChange(value);
        }

        inputRef.current.value = value;
      }, [mask]);

    React.useEffect(() => {
        registerField({
          name: fieldName,
          ref: inputRef.current,
          path: 'value',
            setValue(ref, value) {
                ref.value = value;

                materialInputRef.current
                    .querySelector("label")
                    .classList.add("MuiFormLabel-filled", "MuiInputLabel-shrink")
                ;
            }
        });
    }, [fieldName, registerField]);

    return (
        <CustomTextField
            name={fieldName}
            error={!!error}
            ref={materialInputRef}
            helperText={error ? error : null}
            inputRef={inputRef}
            defaultValue={value}
            value={value}
            label={label}
            fullWidth
            {...props}
            onKeyUp={handleKeyUp}
        />
    );
}

export default InputMask;

InputMask.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
