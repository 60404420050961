import api from '../api';
import {toast} from "react-toastify";
import messageTypes from "../../constants/MessagesTypes";

export async function loadList(data) {
    return api.get(`/api/trading`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function getReport(data) {
    return api.get(`/api/trading/report`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function getDataGroupByMonth(data) {
    return api.get(`/api/trading/report-group-by-month`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function getRanking(data) {
    return api.get(`/api/trading/ranking`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function find($id) {
    try {

        const response = await api.get(`/api/trading/${$id}/find`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                auth: true
            },
        });

        return response.data.data ? response.data.data : {};

    } catch (err) {
        toast.error(messageTypes.ERROR_LOAD);
        throw err;
    }
}

export function save($trading) {
    return api.post('/api/trading/new', $trading, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export function edit($id, $trading) {
    return api.post(`/api/trading/edit/${$id}`, $trading, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export async function importDataQuotex(data) {
    return await api.post('/api/trading/import?broker=quotex', data, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export async function importDataIqOption(data) {
    return await api.post('/api/trading/import?broker=iqoption', data, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export async function cancelImport(importId) {
    return await api.post(`/api/trading/${importId}/cancel-import`, [], {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export async function getHistoricoPlacar(data) {
    return api.get(`/api/trading/get-historico-placar`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function getDataGroupByDaily(data) {
    return api.get(`/api/trading/report-group-by-daily`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}