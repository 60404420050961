import api from '../api';
import {toast} from "react-toastify";
import messageTypes from "../../constants/MessagesTypes";

export async function getFundamentalistTradings(data) {
    return api.get(`/api/fundamental-trading/list`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return[];
    });
}

export async function getFundamentalTrading($id) {
    try {

        const response = await api.get(`/api/fundamental-trading/${$id}/find`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                auth: true
            },
        });

        return response.data.data ? response.data.data : {};

    } catch (err) {
        toast.error(messageTypes.ERROR_LOAD);
        throw err;
    }
}

export function edit($id, $event) {
    return api.post(`/api/fundamental-trading/edit/${$id}`, $event, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export function save($event) {
    return api.post('/api/fundamental-trading/new', $event, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export async function getReport(data) {
    return api.get(`/api/fundamental-trading/report`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function getDataPerformanceMonthFundamental(data) {
    return api.get(`/api/fundamental-trading/report-performance-month`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function getDataPerfomanceDailyFundamental(data) {
    return api.get(`/api/fundamental-trading/report-performance-daily`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}