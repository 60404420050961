const actionsTypes = {
    CLICK_OPEN_MENU: 'CLICK_OPEN_MENU',
    UPDATE_BANK: 'UPDATE_BANK',
    SET_BANKS: 'SET_BANKS',
    HIDE_AMOUNT: 'HIDE_AMOUNT',
    SHOW_TOUR: 'SHOW_TOUR',
    SHOW_MODAL_INFO: 'SHOW_MODAL_INFO',
    SHOW_TOUR_IMPORT: 'SHOW_TOUR_IMPORT',
    SHOW_TOUR_TRADING: 'SHOW_TOUR_TRADING',
    SET_ACTIVE_SIGNATURE: 'SET_ACTIVE_SIGNATURE',
    SET_IS_ENABLE_FREE_SIGNATURE: 'SET_IS_ENABLE_FREE_SIGNATURE',
    SET_IS_FREE_SIGNATURE: 'SET_IS_FREE_SIGNATURE',
    SET_DUE_DATE_SIGNATURE: 'SET_DUE_DATE_SIGNATURE',
}

export default actionsTypes;