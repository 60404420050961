import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { login, register } from '../../../services/Auth';
import { Container } from './styles';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import CryptoJS from "crypto-js";

function BoxSocial() {
  const history = useHistory();

  /*const responseFacebook = async (response) => {
    const { name, email, userID } = response;
    let data = null;
    try {
      data = await login({
        username: email,
        password: `${userID + email}facebook`,
      });
    } catch (err) {
      if (email && userID) {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('username', email);
        formData.append('password', '');
        formData.append('provider', 'facebook');
        formData.append('socialId', userID);

        localStorage.setItem('autoLogin', true);
        localStorage.setItem('username', email);
        localStorage.setItem('socialId', `${userID + email}facebook`);
        localStorage.setItem('@name', name);

        data = await register(formData);
      }
    }
    if (data && data.status === 200) {
      localStorage.setItem('@name', name);
      localStorage.setItem('@user_token', data.data.token);
      toast.success('Autenticado com sucesso!');
      history.push('/dashboard');
    } else {
      toast.error('Credenciais inválidas');
    }
  };*/

  const responseGoogle = async (response) => {
    const profileObj = jwt_decode(response.credential);

    let data = null;
    try {
      data = await login({
        username: profileObj.email,
        password: `${profileObj.sub + profileObj.email}google`,
      });
      localStorage.setItem('userKey', profileObj.email);
    } catch (err) {
      if (profileObj) {
        const formData = new FormData();
        formData.append('name', profileObj.name);
        formData.append('username', profileObj.email);
        formData.append('password', '');
        formData.append('provider', 'google');
        formData.append('socialId', profileObj.sub);

        localStorage.setItem('autoLogin', true);
        localStorage.setItem('username', profileObj.email);
        localStorage.setItem('userKey', profileObj.email);
        localStorage.setItem('socialId', `${profileObj.sub + profileObj.email}google`);
        localStorage.setItem('@name', profileObj.name);

        data = await register(formData);
      }
    }
    if (data && data.status === 200) {
      localStorage.setItem('@name', profileObj.name);
      localStorage.setItem('@user_token', data.data.token);

      const username = CryptoJS.AES.encrypt(profileObj.email, process.env.REACT_APP_CRYPTO_SECRET_KEY).toString();
      const password = CryptoJS.AES.encrypt(`${profileObj.sub + profileObj.email}google`, process.env.REACT_APP_CRYPTO_SECRET_KEY).toString();

      localStorage.setItem('username', username);
      localStorage.setItem('password',password);

      toast.success('Autenticado com sucesso!');
      history.push('/dashboard');
    } else if (data) {
      toast.error('Credenciais inválidas!');
    }
  };

  return (
      <Container className="row">
        <div className="col">
          {/*<div className="col-6 float-left text-center p-0 mb-2">
          <Button variant="outlined" startIcon={<FaFacebook/>} className="text-capitalize font-weight-bold col-10 btn-facebook">
            <FacebookLogin
                className="btn btn-success"
                appId="993919524566261"
                autoLoad={false}
                fields="name,email,picture"
                onClick={() => {}}
                callback={responseFacebook}
                textButton="Facebook"
            />
          </Button>
        </div>*/}
          <div className="col-12 float-right text-center p-0">
            <GoogleLogin
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                onError={() => {
                  console.log('Login Failed');
                }}
            />
          </div>
        </div>
      </Container>
  );
}

export default BoxSocial;
