import React from "react";

export default function PolicyPrivacy() {
    return (
        <div className={"p-5 "}>
            <h1 className={"text-center mb-5"}>
                    Política de Privacidade e Segurança
                    <h4>Atualizado em: 03/07/2023</h4>
            </h1>

            <p>
                A privacidade e segurança dos usuários são de extrema importância para nós. Nesta política, explicamos como coletamos, usamos, armazenamos e protegemos as informações pessoais dos usuários, além de fornecer orientações sobre medidas de segurança que implementamos para garantir a segurança de suas transações e dados.
            </p>
            <p>
                Coleta de Informações:
                <ul>
                    <li>
                        Coletamos informações pessoais dos usuários, como nome, endereço de e-mail e detalhes de contato, para fornecer suporte adequado, personalizar a experiência do usuário e garantir a funcionalidade completa da aplicaçao.
                    </li>
                    <li>
                            Podemos coletar informações não pessoais, como dados demográficos e preferências do usuário, para melhorar nossos serviços e oferecer conteúdo relevante.
                    </li>
                </ul>
            </p>
                <p>
                        Uso de Informações:
                        <ul>
                                <li>
                                        As informações pessoais dos usuários são usadas para fornecer suporte técnico, enviar atualizações e notificações importantes relacionadas ao aplicativo e fornecer uma experiência personalizada.

                                </li>
                                <li>
                                        Podemos usar informações não pessoais para fins estatísticos, análise de dados e aprimoramento contínuo da aplicação.
                                </li>
                        </ul>
                </p>
                <p>Compartilhamento de Informações:
                        <ul>
                                <li>
                                        Não compartilhamos informações pessoais dos usuários com terceiros, a menos que seja estritamente necessário para fornecer serviços solicitados pelos usuários ou exigido por lei.

                                </li>
                                <li>
                                        Podemos compartilhar informações anônimas e agregadas com parceiros de negócios para fins de análise e marketing.
                                </li>
                        </ul>
                </p>
                <p>Segurança de Dados:
                        <ul>
                                <li>
                                        Implementamos medidas de segurança técnicas e organizacionais adequadas para proteger as informações pessoais dos usuários contra acesso não autorizado, uso indevido ou divulgação.
                                </li>
                                <li>
                                        Utilizamos criptografia de dados durante a transmissão de informações sensíveis e adotamos práticas de segurança avançadas para proteger o armazenamento das informações em nossos servidores.

                                </li>
                                <li>
                                    Ao se cadastrar em nosso aplicativo, solicitamos que você forneça seu endereço de e-mail para criar uma conta e permitir o acesso personalizado aos nossos serviços. O e-mail é utilizado como um identificador exclusivo para sua conta e facilita o processo de login em futuras visitas.
                                </li>
                                <li>
                                    O endereço de e-mail fornecido durante o processo de login é usado estritamente para autenticação e identificação do usuário. Ele não será compartilhado com terceiros não relacionados sem o seu consentimento prévio. Utilizaremos seu e-mail para enviar notificações importantes relacionadas à sua conta, como redefinição de senha, confirmação de login ou informações relevantes sobre os serviços prestados.
                                </li>
                        </ul>
                </p>
                <p>Alterações na Política de Privacidade:
                        <ul>
                                <li>
                                        Podemos atualizar esta política periodicamente para refletir mudanças em nossas práticas de privacidade e segurança. Recomendamos que os usuários revejam regularmente esta política para se manterem informados sobre como protegemos suas informações.
                                </li>
                        </ul>
                </p>
                <p>Entre em contato:
                        <ul>
                                <li>
                                        Se você tiver alguma dúvida ou preocupação sobre nossa política de privacidade e segurança, entre em contato conosco por meio dos canais de suporte fornecidos na aplicação.
                                </li>
                        </ul>
                </p>
                <p>
                        Esta Política de Privacidade e Segurança aplica-se apenas a aplicação de gerenciamento de opções binárias e não se estende a serviços ou sites de terceiros vinculados. Recomendamos que os usuários revisem as políticas de privacidade desses terceiros antes de fornecerem informações pessoais.
                </p>
        </div>
    );
}