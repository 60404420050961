import React from 'react';
import DivDiagonal from "../../components/DivDiagonal";
import {ContainerBroker} from "./styles";
import ImgBinolla from "../../assets/img/binolla.png";
import ImgPocketOption from "../../assets/img/pocketOption.png";
import {useTranslation} from "react-i18next";
import ImgBullex from "../../assets/img/bullex.png";
import ImgOptinary from "../../assets/img/optinary.png";

export default function Brokers() {
    const { t } = useTranslation();

    return (
        <>
            <DivDiagonal/>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <div className="row">
                    <div className="col-12 text-white">
                        <h1 className="h3 mb-0"><i className="fa fa-list" aria-hidden="true"></i> {t('home_header_binary_options')}</h1>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4 h-100">
                <div className="card-body">
                    <b>{t('diversify_investment')}</b> {t('create_account_brokers_part_1')}
                    <br/>
                    {t('create_account_brokers_part_2')}

                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col">
                    <div className="row">
                        <ContainerBroker className="col-xl-4 mb-4">
                            <div className="card shadow mb-4 h-100 p-3">
                                <div className={"text-center"}>
                                    <img src={ImgOptinary} width={'100%'} height={"100%"} alt={'Imagem corretora Optnary'}/>
                                </div>
                                <ul style={{
                                    listStyle: 'none',
                                }}>
                                    <li>{t('signature_bullex_item_1')}</li>
                                    <li>{t('signature_bullex_item_2')}</li>
                                    <li>{t('signature_bullex_item_3')}</li>
                                    <li>{t('signature_bullex_item_4')}</li>
                                </ul>
                                <div className="row mt-4">
                                    <div className="col-12 text-center">
                                        <a
                                            href={"https://trade.optinary.com/register?aff=407200&aff_model=revenue&afftrack="} target={"_blank"}
                                            className="btn text-capitalize btn-outline-success font-weight-bold cursor-pointer btn-criar-conta"
                                        >
                                            {t('signature_broker_btn_create')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ContainerBroker>
                        <ContainerBroker className="col-xl-4 mb-4">
                            <div className="card shadow mb-4 h-100 p-3">
                                <div className={"text-center"}>
                                    <img src={ImgBinolla} width={'100%'} height={"100%"} alt={'Imagem corretora binolla'}/>
                                </div>
                                <ul style={{
                                    listStyle: 'none',
                                }}>
                                    <li>{t('signature_binolla_item_1')}</li>
                                    <li>{t('signature_binolla_item_2')}</li>
                                    <li>{t('signature_binolla_item_3')}</li>
                                    <li>{t('signature_binolla_item_4')}</li>
                                </ul>
                                <div className="row mt-4">
                                    <div className="col-12 text-center">
                                        <a
                                            href={"https://binolla.com/signup?lid=199"} target={"_blank"}
                                            className="btn text-capitalize btn-outline-success font-weight-bold cursor-pointer btn-criar-conta"
                                        >
                                            {t('signature_broker_btn_create')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ContainerBroker>
                        <ContainerBroker className="col-xl-4 mb-4">
                            <div className="card shadow mb-4 h-100 p-3">
                                <div className={"text-center"}>
                                    <img src={ImgPocketOption} width={'100%'} height={"100%"} alt={'Imagem corretora Pocket Option'}/>
                                </div>
                                <ul style={{
                                    listStyle: 'none',
                                }}>
                                    <li>{t('signature_pocketoption_item_1')}</li>
                                    <li>{t('signature_pocketoption_item_2')}</li>
                                    <li>{t('signature_pocketoption_item_3')}</li>
                                    <li>{t('signature_pocketoption_item_4')}</li>
                                </ul>
                                <div className="row mt-4">
                                    <div className="col-12 text-center">
                                        <a
                                            href={"https://po8.cash/register?utm_source=affiliate&a=ZGmB4bDdGNKlOP&ac=gbinary"} target={"_blank"}
                                            className="btn text-capitalize btn-outline-success font-weight-bold cursor-pointer btn-criar-conta"
                                        >
                                            {t('signature_broker_btn_create')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ContainerBroker>
                        <ContainerBroker className="col-xl-4 mb-4">
                            <div className="card shadow mb-4 h-100 p-3">
                                <div className={"text-center"}>
                                    <img src={ImgBullex} width={'100%'} height={"100%"} alt={'Imagem corretora Bullex'}/>
                                </div>
                                <ul style={{
                                    listStyle: 'none',
                                }}>
                                    <li>{t('signature_bullex_item_1')}</li>
                                    <li>{t('signature_bullex_item_2')}</li>
                                    <li>{t('signature_bullex_item_3')}</li>
                                    <li>{t('signature_bullex_item_4')}</li>
                                </ul>
                                <div className="row mt-4">
                                    <div className="col-12 text-center">
                                        <a
                                            href={"https://trade.bull-ex.com/register?aff=381650&aff_model=revenue&afftrack="} target={"_blank"}
                                            className="btn text-capitalize btn-outline-success font-weight-bold cursor-pointer btn-criar-conta"
                                        >
                                            {t('signature_broker_btn_create')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ContainerBroker>
                    </div>
                </div>
            </div>
        </>
    )
}