import React, {useEffect, useState} from "react";
import {Container, VideoContainer} from "./styles";
import logo from "../../assets/img/logo.png";
import ImgBanner from "../../assets/img/pc_phone.png";
import ImgBinolla from "../../assets/img/binolla.png";
import ImgPocketOption from "../../assets/img/pocketOption.png";
import ImgOptinary from "../../assets/img/optinary.png";
import ImgBullex from "../../assets/img/bullex.png";
import {Link} from "react-router-dom";
import {AiFillStar} from "react-icons/ai";
import {BsCheckLg, BsWhatsapp} from "react-icons/bs";
import AnuncieAqui from "../../assets/img/anuncie_aqui.png";
import {ContainerBroker} from "../Brokers/styles";
import { useTranslation } from 'react-i18next';

export default function Home() {
    const { t } = useTranslation();
    const [year, setYear] = useState(null);
    const [logged, setLogged] = useState(null);

    useEffect(() => {
        document.getElementById('containerLayout').style.height = 'auto';
        const d = new Date();
        setYear(d.getFullYear());

        setLogged(localStorage.getItem('@user_token'));
    }, []);

    return (
        <>
            <Container>
                <header className="header_area">
                    <div className="main_menu">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <div className="container box_1620">
                                <a className="navbar-brand logo_h" href="#"><img src={logo} alt="Logo Gbinary" width={140}/></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                                    <ul className="nav navbar-nav menu_nav justify-content-center">
                                        <li className="nav-item"><a className="nav-link" href="#section-video">{t('home_header_to_use')}</a>
                                        </li>
                                        <li className="nav-item"><a className="nav-link" href="#section-recursos">{t('home_header_features')}</a>
                                        </li>
                                        <li className="nav-item"><a className="nav-link" href="#section-brockers">{t('home_header_binary_options')}</a>
                                        </li>
                                        <li className="nav-item"><a className="nav-link" href="https://play.google.com/store/apps/details?id=com.appgbinary">{t('home_header_download_app')}</a>
                                        </li>
                                    </ul>
                                    {!logged ?
                                        <>
                                            <ul className="nav navbar-nav navbar-right">
                                                <li className="nav-item"><Link to="/login" className="tickets_btn btn-entrar font-weight-bolder">{t('home_header_btn_enter')}</Link></li>
                                            </ul>
                                            <ul className="nav navbar-nav navbar-right ml-4">
                                                <li className="nav-item"><Link to="/register" className="tickets_btn btn-cadastrar font-weight-bolder">{t('home_header_btn_register')}</Link></li>
                                            </ul>
                                        </> :
                                        <>
                                            <ul className="nav navbar-nav navbar-right">
                                                <li className="nav-item">
                                                    <span onClick={()=> {
                                                        localStorage.clear();
                                                        window.location="/";
                                                    }} className="tickets_btn btn-entrar font-weight-bolder">
                                                        {t('home_header_btn_exit')}
                                                    </span>
                                                </li>
                                            </ul>
                                            <ul className="nav navbar-nav navbar-right ml-4">
                                                <li className="nav-item">
                                                    <li className="nav-item"><Link to="/dashboard" className="tickets_btn btn-cadastrar font-weight-bolder">{t('home_header_btn_enter')}</Link></li>
                                                </li>
                                            </ul>
                                        </>
                                    }

                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
                <section className="home_banner_area">
                    <div className="banner_inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="banner_content">
                                        <h2>{t('home_title')}</h2>
                                        <p className={"subtitle"}>
                                            {t('home_subtitle')}
                                        </p>
                                        <Link className="banner_btn btn-cadastrar font-weight-bolder" to={logged ? "/dashboard" : "/login"}>{t('home_btn_start')}</Link>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="home_left_img">
                                        <img className="img-fluid" src={ImgBanner} alt="Banner gbinary"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home_banner_area" id={"section-recursos"}>
                    <div className="banner_inner">
                        <div className="container">
                            <div className="container text-center pl-3 pr-3 pt-5" style={{ borderTop: '1px solid rgba(255,255,255,.09)' }}>
                                <div className="main_title pt-4">
                                    <h2>{t('home_section_features_title')}</h2>
                                    <p className={"text-subtitle"}>{t('home_section_features_subtitle')}</p>
                                </div>
                                <div className="row text-left">
                                    <ul className={"text-description ul-functions"}>
                                        <li><BsCheckLg  className={"mr-2"} color={"#1cc88a"}/> {t('home_section_features_item_1')}</li>
                                        <li><BsCheckLg  className={"mr-2"} color={"#1cc88a"}/> {t('home_section_features_item_2')}</li>
                                        <li><BsCheckLg  className={"mr-2"} color={"#1cc88a"}/> {t('home_section_features_item_3')}</li>
                                        <li><BsCheckLg  className={"mr-2"} color={"#1cc88a"}/> {t('home_section_features_item_4')}</li>
                                        <li><BsCheckLg  className={"mr-2"} color={"#1cc88a"}/> {t('home_section_features_item_5')}</li>
                                        <li><BsCheckLg  className={"mr-2"} color={"#1cc88a"}/> {t('home_section_features_item_6')}</li>
                                        <li><BsCheckLg  className={"mr-2"} color={"#1cc88a"}/> {t('home_section_features_item_7')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="screen_area text-center p_120" id={"section-video"}>
                    <div className="container">
                        <div className="main_title">
                            <h2>{t('home_section_use_title')}</h2>
                            <p className={"text-subtitle"}>
                                {t('home_section_use_subtitle')}
                            </p>
                        </div>
                        <div className="col-12 justify-content-center pb-4 pt-4" align={"center"}>
                            <VideoContainer>
                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/JbRVo26uUsI"
                                        title="A MELHOR PLANILHA DE GERENCIAMENTO PARA OPÇÕES BINÁRIAS" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen></iframe>
                            </VideoContainer>
                        </div>
                    </div>
                </section>
                <section className="work_area p_120" id={"section-brockers"}>
                    <div className="container text-center">
                        <div className="main_title">
                            <h2>{t('home_section_signature_title')} <span className={"text-warning"}>{t('home_section_signature_title_part_1')}</span>{t('home_section_signature_title_part_2')}</h2>
                            <p className={"text-description"}>
                                {t('home_section_signature_p_1')}
                            </p>
                            <br/>
                            <p className={"text-description"}>
                                {t('home_section_signature_p_2')}
                            </p>
                        </div>
                        <div className="work_inner row justify-content-center">
                            <ContainerBroker className="col-lg-3 col-md-5 mb-4">
                                <div className="card shadow mb-4 h-100 p-3">
                                    <div className={"text-center"}>
                                        <img src={ImgBinolla} width={'100%'} height={"100%"} alt={'Imagem corretora binolla'}/>
                                    </div>
                                    <ul style={{
                                        listStyle: 'none',
                                        padding: 0
                                    }}>
                                        <li>{t('signature_binolla_item_1')}</li>
                                        <li>{t('signature_binolla_item_2')}</li>
                                        <li>{t('signature_binolla_item_3')}</li>
                                        <li>{t('signature_binolla_item_4')}</li>
                                    </ul>
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            <a
                                                href={"https://binolla.com/signup?lid=199"} target={"_blank"}
                                                className="btn text-capitalize btn-outline-success font-weight-bold cursor-pointer btn-criar-conta"
                                            >
                                                {t('signature_broker_btn_create')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </ContainerBroker>
                            <ContainerBroker className="col-lg-3 col-md-5 mb-4">
                                <div className="card shadow mb-4 h-100 p-3">
                                    <div className={"text-center"}>
                                        <img src={ImgPocketOption} width={'100%'} height={"100%"} alt={'Imagem corretora Pocket Option'}/>
                                    </div>
                                    <ul style={{
                                        listStyle: 'none',
                                        padding: 0
                                    }}>
                                        <li>{t('signature_pocketoption_item_1')}</li>
                                        <li>{t('signature_pocketoption_item_2')}</li>
                                        <li>{t('signature_pocketoption_item_3')}</li>
                                        <li>{t('signature_pocketoption_item_4')}</li>
                                    </ul>
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            <a
                                                href={"https://po7.cash/register?utm_source=affiliate&a=ZGmB4bDdGNKlOP&ac=gbinary"} target={"_blank"}
                                                className="btn text-capitalize btn-outline-success font-weight-bold cursor-pointer btn-criar-conta"
                                            >
                                                {t('signature_broker_btn_create')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </ContainerBroker>
                            <ContainerBroker className="col-lg-3 col-md-5 mb-4">
                                <div className="card shadow mb-4 h-100 p-3">
                                    <div className={"text-center"}>
                                        <img src={ImgBullex} width={'100%'} height={"100%"} alt={'Imagem corretora Bullex'}/>
                                    </div>
                                    <ul style={{
                                        listStyle: 'none',
                                        padding: 0
                                    }}>
                                        <li>{t('signature_bullex_item_1')}</li>
                                        <li>{t('signature_bullex_item_2')}</li>
                                        <li>{t('signature_bullex_item_3')}</li>
                                        <li>{t('signature_bullex_item_4')}</li>
                                    </ul>
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            <a
                                                href={"https://trade.bull-ex.com/register?aff=381650&aff_model=revenue&afftrack="} target={"_blank"}
                                                className="btn text-capitalize btn-outline-success font-weight-bold cursor-pointer btn-criar-conta"
                                            >
                                                {t('signature_broker_btn_create')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </ContainerBroker>
                            <ContainerBroker className="col-lg-3 col-md-5 mb-4">
                                <div className="card shadow mb-4 h-100 p-3">
                                    <div className={"text-center"}>
                                        <img src={ImgOptinary} width={'100%'} height={"100%"} alt={'Imagem corretora optnary'}/>
                                    </div>
                                    <ul style={{
                                        listStyle: 'none',
                                        padding: 0
                                    }}>
                                        <li>{t('signature_bullex_item_1')}</li>
                                        <li>{t('signature_bullex_item_2')}</li>
                                        <li>{t('signature_bullex_item_3')}</li>
                                        <li>{t('signature_bullex_item_4')}</li>
                                    </ul>
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            <a
                                                href={"https://trade.optinary.com/register?aff=407200&aff_model=revenue&afftrack="} target={"_blank"}
                                                className="btn text-capitalize btn-outline-success font-weight-bold cursor-pointer btn-criar-conta"
                                            >
                                                {t('signature_broker_btn_create')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </ContainerBroker>
                        </div>
                    </div>
                </section>
                <section className="screen_area text-center p_120">
                    <div className="container">
                        <div className="main_title">
                            <h2>{t('home_feedback_title')}</h2>
                        </div>
                        <div className="col-12 justify-content-center pb-4 pt-4" align={"center"}>
                            <div id="demo" className="carousel slide" data-ride="carousel" data-interval="10000">
                                <ul className="carousel-indicators">
                                    <li data-target="#demo" data-slide-to="0" className="active"></li>
                                    <li data-target="#demo" data-slide-to="1"></li>
                                </ul>
                                <div className="carousel-inner">
                                    <div className="carousel-item active mb-4">
                                        <div className="owl-item active">
                                            <div className="row justify-content-center">
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-12 h-100 p-5 item-carousel">
                                                        <div className="card h-100 p-4">
                                                            <p>{t('home_feedback_card_1')}</p>
                                                            <h4>Francisco Nunes</h4>
                                                            <div className="rating">
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 h-100 p-5 d-md-block d-sm-none item-carousel">
                                                        <div className="card h-100 p-4">
                                                            <p>{t('home_feedback_card_2')}</p>
                                                            <h4>Emerson Santos</h4>
                                                            <div className="rating">
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item mb-4">
                                        <div className="owl-item">
                                            <div className="row justify-content-center">
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-12 h-100 p-5 item-carousel">
                                                        <div className="card h-100 p-4">
                                                            <p>{t('home_feedback_card_3')}</p>
                                                            <h4>Sillas Xavier</h4>
                                                            <div className="rating">
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 h-100 p-5 d-md-block d-sm-none item-carousel">
                                                        <div className="card h-100 p-4">
                                                            <p>{t('home_feedback_card_4')}</p>
                                                            <h4>Taynara Souza</h4>
                                                            <div className="rating">
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                                <AiFillStar color={"#f9a330"}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a className="carousel-control-prev" href="#demo" data-slide="prev">
                                    <span className="carousel-control-prev-icon"></span>
                                </a>
                                <a className="carousel-control-next" href="#demo" data-slide="next">
                                    <span className="carousel-control-next-icon"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <footer className="footer_area pt-5 pb-5">
                    <div className="container">
                        <div className="row footer_inner">
                            <div className="col-lg-4 col-sm-6">
                                <aside className="f_widget ab_widget">
                                    <div className="f_title">
                                        <h3>{t('support')}</h3>
                                    </div>
                                    <p>
                                        <div>
                                            <div>{t('email')}: app.gbinary@gmail.com</div>
                                            <div>
                                                {t('telephone')}:
                                                <a className="pl-1" style={{ color: '#777777'}} href="https://web.whatsapp.com/send?phone=38999361310" target="_blank">
                                                    <BsWhatsapp color={'#25D366'}/> <u>(38) 99936-1310</u>
                                                </a>
                                            </div>
                                        </div>
                                    </p>
                                </aside>
                            </div>
                            <div className="col-lg-4 text-center">
                                <aside className="f_widget social_widget">
                                    <p>
                                        CNPJ: 33.492.063/0001-61
                                        <br/>
                                        Copyright &copy; { year }
                                        <br/>
                                        {t('rights_reserved')}
                                        <br/>
                                        <Link to="/policy-privacy" style={{
                                            color: '#777'
                                        }}>{t('privacy_policy')}</Link>
                                    </p>
                                </aside>
                            </div>
                            <div className="col-lg-4 text-center d-none">
                                <aside className="f_widget social_widget">
                                    <img
                                        style={{ border: '1px solid #dddfeb'}}
                                        width={287} height={240} src={AnuncieAqui} alt={"Anuncie aqui"}
                                    />
                                </aside>
                            </div>
                        </div>
                    </div>
                </footer>
            </Container>
        </>
    );
}