import api from '../api';
import {toast} from "react-toastify";
import messageTypes from "../../constants/MessagesTypes";

export async function getStrategies(data) {
    return api.get(`/api/strategy/list`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return[];
    });
}

export async function getStrategy($id) {
    try {

        const response = await api.get(`/api/strategy/${$id}/find`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                auth: true
            },
        });

        return response.data.data ? response.data.data : {};

    } catch (err) {
        toast.error(messageTypes.ERROR_LOAD);
        throw err;
    }
}

export function edit($id, $strategy) {
    return api.post(`/api/strategy/edit/${$id}`, $strategy, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export function save($strategy) {
    return api.post('/api/strategy/new', $strategy, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export async function getReport(data) {
    return api.get(`/api/strategy/report`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}