import styled from 'styled-components';

export const Container = styled.div`
  label {
    color: #d3d1d1;
  }

  input {
    color: white;
  }

  .card {
    border-radius: 10px;
  }

  .btn-facebook, .btn-google {
    background: #f5f5f5;
    height: 50px;
    border-radius: 10px;
  }
  
  .btn-entrar {
    height: 50px;
    border-radius: 10px;
  }

  .card {
    border: none!important;
  }
`;

export const VideoContainer = styled.div`
  background-position: center;
  background-color: black;
  width: 90%;
  height: 230px;
  max-width: 387px;
  border-radius: 31px;
  border: 5px solid white;
  overflow: hidden;

  @media (max-width: 767px) {
    border-radius: 25px;
    border: 2px solid white;
    width: 50%;
    height: 174px;
    min-height: 174px;
    max-height: 174px;
    min-width: 318px;
    max-width: 318px;
  }
  
  @media (min-width: 769px) {
    border-radius: 25px;
    border: 2px solid white;
    height: 208px;
  }
`;
