import * as React from 'react';
import {useRef} from 'react';
import TextField from '@mui/material/TextField';
import {useField} from "@unform/core";

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateTimePicker} from "@mui/x-date-pickers";

export default function InputDateTimePicker({ name, label, ...props }) {
    const [value, setValue] = React.useState(props.value);
    const inputRef = React.useRef(null);
    const materialInputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    React.useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref, value) {
                ref.value = value;

                materialInputRef.current
                    .querySelector("label")
                    .classList.add("MuiFormLabel-filled", "MuiInputLabel-shrink")
                ;
            }
        });
    }, [fieldName, registerField, value]);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    inputFormat="DD/MM/YYYY HH:mm"
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                    label={label}
                    value={value}
                    inputRef={inputRef}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            helperText={error ? error : null}
                            name={fieldName}
                            error={!!error}
                            ref={materialInputRef}
                            defaultValue={defaultValue}
                            fullWidth
                        />
                    }
                />
            </LocalizationProvider>
        </>
    );
}
