import React, {useCallback, useEffect, useRef, useState} from "react";
import {Container} from "./styles";
import InputMask from "../../../components/Form/InputMask";
import {currencyFormat} from "../../../utils/masks";
import {InputAdornment} from "@material-ui/core";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import * as Yup from "yup";
import messageTypes from "../../../constants/MessagesTypes";
import CustomButton from "../../../components/Form/Button";
import {Form} from "@unform/web";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {useTranslation} from "react-i18next";

export default function Martingale() {
    const { coinType} = useSelector((state) => state.BankReducer);
    const formRef = useRef(null);
    const [value, setValue] = useState(10);
    const [payout, setPayout] = useState(100);
    const [list, setList] = useState(null);
    const { t } = useTranslation();

    async function handleSubmit(data) {
        try {

            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                value: Yup.string().required(t('input_required')),
                payout: Yup.string().required(t('input_required')),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const dataValue = parseFloat(data.value.replace(/[^0-9,-]*/g, '').replace(',', '.'));
            const dataPayout = parseFloat(data.payout);
            if (!parseInt(dataValue) || !parseInt(dataPayout)) {
                toast.warning(t('calc_invalid_values'));
                return;
            }
            setValue(dataValue);
            setPayout(dataPayout);

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    const updateList = useCallback(() => {
        let newList = [];
        let valueCalc = value;
        let resultCalc = valueCalc * payout / 100;
        let accumulated = valueCalc;
        for (let index = 0; index < 20; index++) {
            const item = {
                level: index + 1,
                value: valueCalc,
                result: resultCalc,
                accumulated: accumulated,
            };
            newList.push(item);


            valueCalc = valueCalc * (1+  (payout / 100)) / (payout / 100);
            resultCalc = valueCalc * payout / 100;
            accumulated = valueCalc + accumulated;
        }

        setList(newList);
    }, [payout, value]);

    useEffect(updateList, [payout, value]);

    return (
        <Container>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <div className="row justify-content-center mb-3">
                    <div className="col-xs-12 pull-right col-md-6 mt-3">
                        <div className="row">
                            <div className="col-6">
                                <InputMask
                                    size="small"
                                    type="text"
                                    name="value"
                                    label={t('calc_initial_value') + ' *'}
                                    mask={'currency'}
                                    defaultValue={currencyFormat(value)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{coinType}</InputAdornment>,
                                    }}
                                />
                            </div>
                            <div className="col-6">
                                <InputMask
                                    size="small"
                                    type="text"
                                    name="payout"
                                    label={t('calc_payout') + ' *'}
                                    mask={'percent'}
                                    defaultValue={(payout).toFixed(2)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position={'end'}>%</InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 mt-3">
                        <CustomButton
                            className="btn btn-primary font-weight-bold text-capitalize mr-2"
                            type="submit"
                        >
                            {t('calc_btn_calculate')}
                        </CustomButton>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-4">
                        <TableContainer component={Paper}>
                            <Table aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="font-weight-bold text-muted" align="center">{t('calc_level')}</TableCell>
                                        <TableCell className="font-weight-bold text-muted" align="center">{t('calc_investment')}</TableCell>
                                        <TableCell className="font-weight-bold text-muted" align="center">{t('calc_total_profit')}</TableCell>
                                        <TableCell className="font-weight-bold text-muted" align="center">{t('calc_total_loss')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list && list.length && list.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center" component="th" scope="row">
                                                {row.level}
                                            </TableCell>
                                            <TableCell align="center">
                                                {coinType} {currencyFormat(row.value)}
                                            </TableCell>
                                            <TableCell align="center">
                                                <span className={"text-success font-weight-bold"}>
                                                    {coinType} {currencyFormat(row.result)}
                                                </span>
                                            </TableCell>
                                            <TableCell align="center">
                                                <span className={"text-danger font-weight-bold"}>
                                                    {coinType} {currencyFormat(row.accumulated)}
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </Form>
        </Container>
    );
}