import React from "react";
import {Container} from "./styles";
import {FormHelperText} from "@material-ui/core";

function Fieldset({ children, iconLegend, textLegend }) {
    return (
        <Container>
            <legend className="scheduler-border">
                <FormHelperText>{ iconLegend } { textLegend }</FormHelperText>
            </legend>
            <div className="control-group">{ children }</div>
        </Container>
    );
}

export default Fieldset;