import api from '../api';
import {toast} from "react-toastify";
import messageTypes from "../../constants/MessagesTypes";

export function save($bank) {
    return api.post('/api/bank/save', $bank, {
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    });
}

export function newBank($bank) {
    return api.post('/api/bank/new', $bank, {
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    });
}

export async function getBank() {
    try {

        const response = await api.get('/api/bank', {
            headers: {
                'Content-Type': 'multipart/form-data',
                auth: true
            },
        });

        return response.data.data.length ? response.data.data[0] : {};

    } catch (err) {
        if (err.response.status === 401 || err.response.status === 403) {
            toast.warning(messageTypes.SESSION_EXPIRED);
            throw err;
        }

        toast.error(messageTypes.ERROR_LOAD_DATA_BANK);
        throw err;
    }
}

export async function getBanks() {
    try {

        const response = await api.get('/api/bank/list', {
            headers: {
                'Content-Type': 'multipart/form-data',
                auth: true
            },
        });

        return response.data.banks.length ? response.data.banks : {};

    } catch (err) {
        if (err.response.status === 401 || err.response.status === 403) {
            throw err;
        }

        toast.error(messageTypes.ERROR_LOAD_DATA_BANK);
    }
}

export function setDefaultBank(id) {
    return api.post(`/api/bank/set-default/${id}`, null, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export async function setHideAmount($data) {
    api.post('/api/bank/set-hide-amount', $data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR);
    });
}

export async function getMovements(data) {
    return api.get(`/api/bank/movements`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return[];
    });
}