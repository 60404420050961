import React, {useRef} from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
import i18n from "../../../services/i18n";

const CustomSelect = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
        },
    },
})(FormControl);

function InputSelect({ name, label, placeholder=i18n.t('placeholder_select'), ...props }) {
    const selectRef = React.useRef(null);
    const materialInputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    React.useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: 'value',
            setValue(ref, value) {
                ref.value = value;

                materialInputRef.current
                    .querySelector("label")
                    .classList.add("MuiFormLabel-filled", "MuiInputLabel-shrink")
                ;
            },
            getValue: (ref) => {
                return selectRef.current.value;
            },
        });
    }, [fieldName, registerField]);

    return (
        <CustomSelect sx={{ width: '100%' }} error={!!error}>
            <InputLabel>{label}</InputLabel>
            <Select
                name={fieldName}
                ref={materialInputRef}
                inputRef={selectRef}
                defaultValue={defaultValue}
                label={label}
                fullWidth
                {...props}
            >
                <MenuItem value="">
                    {placeholder}
                </MenuItem>
                {props.options.length && props.options.map((option, index) => (
                    <MenuItem key={option.key} value={option.key}>{option.value}</MenuItem>
                ))}
            </Select>
            <FormHelperText error={!!error}>{error}</FormHelperText>
        </CustomSelect>
    );
}

export default InputSelect;

InputSelect.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};
