import React, {useCallback, useEffect, useRef, useState} from "react";
import {getDataGroupByDaily} from "../../../services/Trading";
import {Alert} from "@material-ui/core";
import messageTypes from "../../../constants/MessagesTypes";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {getDataPerfomanceDailyFundamental} from "../../../services/FundamentalTrading";
import {Form} from "@unform/web";
import InputSelect from "../../Form/InputSelect";
import InputDate from "../../Form/InputDate";
import CustomButton from "../../Form/Button";
import {AiOutlineSearch} from "react-icons/ai";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {currencyFormat} from "../../../utils/masks";

export default function TradingPerfomanceDaily({...props}) {
    const [isData, setData] = useState(null);
    const [isDataFundamental, setDataFundamental] = useState(null);
    const [options, setOptions] = useState([]);
    const [resultAnalysis, setResultAnalysis] = useState(null);
    const formRef = useRef(null);
    const { t } = useTranslation();

    const nameMonth = [
        t('month_1'), t('month_2'), t('month_3'), t('month_4'), t('month_5'), t('month_6'),
        t('month_7'), t('month_8'), t('month_9'), t('month_10'), t('month_11'), t('month_12')
    ];

    const [filter, setFilter] = useState({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        typeAnalysis: 'TECHNICAL',
    });

    async function handleSubmit(data) {
        try {

            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                date: Yup.string().required(t('input_required')),
            });

            await schema.validate(data, {
                abortEarly: false,
            });


            const parts = data.date.split("/");
            setFilter({
                month: parseInt(parts[0]),
                year: parts[1],
                typeAnalysis: data.typeAnalysis,
            });

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    useEffect( () => {
        if (filter.typeAnalysis === 'FUNDAMENTAL') {
            getDataPerfomanceDailyFundamental({
                month: filter.month,
                year: filter.year,
            }).then((data) => {

                if (!data || data === '' || !data.data) {
                    setDataFundamental(false);
                    return;
                }

                setDataFundamental(true);

                var result = [];
                for (var key in data.data.data) {
                    var item = data.data.data[key];

                    console.log(item);
                    const qtdeWin = parseInt(item.data[0]['qtdeWin']);
                    const qtdeLoss = parseInt(item.data[0]['qtdeLoss']);

                    if (qtdeWin || qtdeLoss) {
                        const percent = (qtdeLoss + qtdeWin) ? qtdeWin * 100 / (qtdeLoss + qtdeWin) : 0;
                        result.push([item.day, percent]);
                    }
                }

                setResultAnalysis(result);
            });
        } else {
            getDataGroupByDaily({
                month: filter.month,
                year: filter.year,
            }).then((data) => {
                if (!data || data === '' || !data.data) {
                    setData(false);
                    return;
                }

                setData(true);

                var result = [];
                for (var key in data.data.data) {
                    var item = data.data.data[key];

                    const qtdeWin = parseInt(item.data[0]['qtdeWin']);
                    const qtdeLoss = parseInt(item.data[0]['qtdeLoss']);
                    const qtdeDraw = parseInt(item.data[0]['qtdeDraw']);

                    if (qtdeWin || qtdeLoss || qtdeDraw) {
                        const percent = (qtdeLoss + qtdeWin + qtdeDraw) ? qtdeWin * 100 / (qtdeLoss + qtdeWin + qtdeDraw) : 0;
                        result.push([item.day, percent]);
                    }
                }

                setResultAnalysis(result);
            });
        }
    }, [filter]);

    const updateChart = useCallback(() => {
        setOptions({
            title: {
                text: ''
            },
            subtitle: {
                text: ``
            },
            chart: {
                type: 'areaspline'
            },
            xAxis: {
                crosshair: false
            },
            series: [
                {
                    name: filter.typeAnalysis === 'FUNDAMENTAL' ? t('performance_graphic_day_name_series_fundamental_analysis') : t('performance_graphic_day_name_series_technical_analysis'),
                    data: resultAnalysis,
                    color: '#1cc88a',
                }
            ],
            yAxis: {
                min: 0,
                title: {
                    text: t('performance_graphic_day_title_yaxy')
                }
            },
            tooltip: {
                headerFormat: `<span style="font-size:10px">${t("day")} {point.key}</span><table>`,
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.1f}%</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                series: {
                    fillColor: {
                        linearGradient: [0, 0, 0, 400],
                        stops: [
                            [0, '#1cc88a'],
                            [
                                1,
                                Highcharts.color(Highcharts.getOptions().colors[0])
                                    .setOpacity(0)
                                    .get("rgba"),
                            ],
                        ],
                    },
                },
            },
        });
    }, [resultAnalysis, setOptions, filter]);

    useEffect(updateChart, [resultAnalysis]);

    return (
        <div {...props}>
            <div className="bg-gray-100">
                <div className="row">
                    <div className="col">
                        <Form ref={formRef} onSubmit={handleSubmit} className="p-3">
                            <div className="row">
                                <div className="col-md-12 pull-left d-flex align-items-center ml-1">
                                    <div className="font-weight-bold mb-1"><nobr><i className="fas fa-funnel-dollar"></i> {t('filter')}</nobr></div>
                                </div>
                                <div className={[
                                    'gleiton.egues204@gmail.com',
                                    'kleberbarantes@gmail.com',
                                    'queila.oliveira01@gmail.com',
                                    'teste@teste.com',
                                ].includes(localStorage.getItem('userKey')) ? "col-md-4 col-xs-12 mt-3" : "col-md-4 col-xs-12 mt-3 d-none"}>
                                    <InputSelect
                                        type="text"
                                        name="typeAnalysis"
                                        defaultValue={'TECHNICAL'}
                                        label={t('performance_filter_label_input_type_analysis')}
                                        options={[
                                            {key: 'FUNDAMENTAL', value: 'Fundamentalista'},
                                            {key: 'TECHNICAL', value: 'Técnica'},
                                        ]}
                                    />
                                </div>
                                <div className="col-md-4 col-xs-12 mt-3">
                                    <InputDate
                                        name="date"
                                        label={t('performance_filter_label_month')}
                                        inputFormat={'MM/YYYY'}
                                        views={['month', 'year']}
                                        value={new Date()}
                                    />
                                </div>
                                <CustomButton
                                    className="btn btn-primary float-right font-weight-bold text-capitalize mt-3 ml-3 mr-3"
                                    type="submit"
                                    startIcon={<AiOutlineSearch/>}
                                >
                                    {t('filter_btn_submmit')}
                                </CustomButton>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-12 pl-4 ml-1 mb-5">
                    <span className="m-0 font-weight-bold">
                        <i className="far fa-calendar-alt"/> {t('performance_graphic_day_title', {
                            'month': nameMonth[filter.month -1],
                            'year': filter.year,
                        })}
                    </span>
                </div>
            </div>
            {(isData || isDataFundamental) ?
                <>
                    <div className="col">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                </> :
                <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
            }
        </div>
    );
}
