import React, {useRef} from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import {TextField} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
    },
  },
})(TextField);

function Input({ name, label, ...props }) {
  const inputRef = React.useRef(null);
  const materialInputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  React.useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.value = value;

        materialInputRef.current
            .querySelector("label")
            .classList.add("MuiFormLabel-filled", "MuiInputLabel-shrink")
        ;
      }
    });
  }, [fieldName, registerField]);

  return (
      <CustomTextField
          name={fieldName}
          error={!!error}
          ref={materialInputRef}
          helperText={error ? error : null}
          inputRef={inputRef}
          defaultValue={defaultValue}
          label={label}
          fullWidth
          {...props}
      />
  );
}

export default Input;

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
