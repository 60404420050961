import styled from "styled-components";

export const ContainerBroker = styled.div`
  img {
    height: 130px;
    width: 130px;
    object-fit: contain;
  }
  
  .btn-criar-conta {
    text-transform: inherit!important;
  }
`;