import React, {useEffect, useRef, useState} from 'react';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {useHistory} from 'react-router-dom';
import {Form} from '@unform/web';
import CustomButton from '../../components/Form/Button';
import Input from '../../components/Form/Input';
import {load, save} from "../../services/Profile";
import AlertDelete from "../../components/Alert/AlertDelete";
import Fieldset from "../../components/Fieldset";
import {AiOutlineUnorderedList} from "react-icons/ai";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import brokerTypes from "../../constants/BrokerTypes";

function Profile() {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(false);
    const formRef = useRef(null);
    const history = useHistory();
    const [disableAccount, setDisableAccount] = useState(false);
    const [deleteBank, setDeleteBank] = useState(false);
    const {dueDate} = useSelector((state) => state.UserReducer);
    const {broker } = useSelector((state) => state.BankReducer);
    const { t } = useTranslation()
    async function handleRegister(data) {
        if (loading) {
            return;
        }
        setLoading(true);
        try {
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                name: Yup.string().required(t('input_required'))
            });
            await schema.validate(data, {
                abortEarly: false,
            });

            const formData = new FormData();
            formData.append('name', data.name);
            const response = await save(formData);

            if (response && response.status === 200) {
                localStorage.setItem('@name', response.data.data.name);
                toast.success(t('profile_alert_save_success'));
                history.push('/dashboard');
            } else {
                toast.error(t('profile_alert_save_error'));
            }
            setLoading(false);
        } catch (err) {
            toast.error(t('profile_alert_save_error'));
            setLoading(false);
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    }

    useEffect(() => {
        setLoading(true);
        load().then((data) => {
            setUser(data.data.data);
        }).finally(() => setLoading(false));
    }, []);

    function getBroker(value) {
        for (var index in brokerTypes) {
            if (brokerTypes[index].key === value) {
                return brokerTypes[index].value
            }
        }

        return null
    }

    return (
        <>
            <div className="container">
                {!loading && (
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-sm-12 col-md-10">
                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-header py-3">
                                    <h6 className="m-0 font-weight-bold">
                                        <i className="fas fa-user"/> {t('profile_card_title')}
                                    </h6>
                                </div>
                                <div className="card-body p-0">
                                    {dueDate &&
                                        <div className="row pt-4">
                                            <div className="col-12 text-center">
                                                <label className={"text-center"}>{t('profile_text_date_signature')} <b>{ dueDate }</b></label>
                                            </div>
                                        </div>
                                    }
                                    <div className="row pt-5 p-4">
                                        <div className="col-12">
                                            <Fieldset iconLegend={<AiOutlineUnorderedList/>} textLegend={t('profile_title_fieldset_options')} className="scheduler-border">
                                                <div className="row pt-4 pb-4">
                                                    <div className="col-12 justify-content-center d-flex">
                                                        <div className="col-6 justify-content-center d-flex">
                                                            <CustomButton
                                                                color="error"
                                                                onClick={() => {
                                                                    setDeleteBank(true)
                                                                }}
                                                                className="btn text-capitalize font-weight-bold">
                                                                <i className="fa fa-trash pr-2" aria-hidden="true"/> {t('profile_btn_delete_bank')}
                                                                {deleteBank && <AlertDelete
                                                                    functionCallbackCancel={() => {
                                                                        setDeleteBank(false);
                                                                    }}
                                                                    functionCallbackConfirm={() => {
                                                                        window.location = "/";
                                                                    }}
                                                                    text={t('alert_remove_bank', {
                                                                        'broker': getBroker(broker)
                                                                    })}
                                                                    urlDelete={process.env.REACT_APP_API_ENDPOINT + `/api/bank/delete`}
                                                                />}
                                                            </CustomButton>
                                                        </div>
                                                        <div className="col-6 justify-content-center d-flex">
                                                            <CustomButton
                                                                color="error"
                                                                onClick={() => { setDisableAccount(true)}}
                                                                className="btn text-capitalize font-weight-bold">
                                                                <i className="fa fa-trash pr-2" aria-hidden="true"/> {t('profile_btn_delete_account')}
                                                                {disableAccount && <AlertDelete
                                                                    functionCallbackCancel={() => {
                                                                        setDisableAccount(false);
                                                                    }}
                                                                    functionCallbackConfirm={() => {
                                                                        localStorage.clear();
                                                                        window.location = "/";
                                                                    }}
                                                                    text={t('alert_remove_account')}
                                                                    urlDelete={process.env.REACT_APP_API_ENDPOINT + `/api/user/delete`}
                                                                />}
                                                            </CustomButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fieldset>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="p-4">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4 font-weight-bold">
                                                        <i className="fas fa-edit"/> {t('profile_edit_user')}
                                                    </h1>
                                                </div>
                                                <Form ref={formRef} onSubmit={handleRegister}>
                                                    <div className="row mb-4">
                                                        <div className="col">
                                                            <Input
                                                                type="text"
                                                                name="name"
                                                                label={t('profile_edit_user_label_input_name') + ' *'}
                                                                defaultValue={user.name}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col text-center">
                                                            <CustomButton
                                                                className="btn btn-primary text-capitalize font-weight-bold"
                                                                loading={loading} type="submit">
                                                                {t('profile_edit_user_btn_save')}
                                                            </CustomButton>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Profile;
