import React, {useEffect, useRef, useState} from "react";
import DivDiagonal from "../../../components/DivDiagonal";
import {Form} from "@unform/web";
import * as Yup from "yup";
import {toast} from "react-toastify";
import messageTypes from "../../../constants/MessagesTypes";
import {AiOutlineSearch} from "react-icons/ai";
import CustomButton from "../../../components/Form/Button";
import {dateCurrent, dateFirstDayCurrent, firstDayMonth} from "../../../utils/date";
import CardManagementSuccess from "../../../components/Report/Disciplina/CardManagementSuccess";
import {getReport} from "../../../services/Disciplina";
import CardManagementFailure from "../../../components/Report/Disciplina/CardManagementFailure";
import TooltipDefault from "../../../components/TooltipDefault";
import {currencyFormat} from "../../../utils/masks";
import {useSelector} from "react-redux";
import InputDate from "../../../components/Form/InputDate";
import {useTranslation} from "react-i18next";

export default function TradingReport() {
    const formRef = useRef(null);
    const [totalDays, setTotalDays] = useState(null);
    const [dataManagementFailure, setDataManagementFailure] = useState({
        qtdeDays: 0
    });
    const { t } = useTranslation()

    const {isActiveSignature} = useSelector(
        (state) => state.UserReducer
    );

    const [dataManagementSuccess, setDataManagementSuccess] = useState({
        qtdeDays: 0
    });

    const [filter, setFilter] = useState([
        {
            startDate: dateFirstDayCurrent(),
            endDate: dateCurrent(),
        }
    ]);

    async function handleSubmit(data) {
        try {

            formRef.current.setErrors({});
            const schema = Yup.object().shape({});

            await schema.validate(data, {
                abortEarly: false,
            });

            setFilter(
                [{
                    startDate: data.startDate,
                    endDate: data.endDate,
                }]
            );

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect( () => {
        getReport({
            startDate: filter[0].startDate,
            endDate: filter[0].endDate,
        }).then((data) => {
            if (!data || data === '' || !data.data) {
                return;
            }

            setDataManagementFailure({
                qtdeDraw: parseInt(data.data.dataManagementFailure[0].qtdeDraw),
                qtdeWin: parseInt(data.data.dataManagementFailure[0].qtdeWin),
                qtdeLoss: parseInt(data.data.dataManagementFailure[0].qtdeLoss),
                profitObtained: parseFloat(data.data.dataManagementFailure[0].profitObtained),
                qtdeDays: data.data.dataManagementFailure.qtdeDays
            });

            setDataManagementSuccess({
                qtdeDraw: parseInt(data.data.dataManagementSuccess[0].qtdeDraw),
                qtdeWin: parseInt(data.data.dataManagementSuccess[0].qtdeWin),
                qtdeLoss: parseInt(data.data.dataManagementSuccess[0].qtdeLoss),
                profitObtained: parseFloat(data.data.dataManagementSuccess[0].profitObtained),
                qtdeDays: data.data.dataManagementSuccess.qtdeDays
            });

            setTotalDays(data.data.dataManagementSuccess.qtdeDays + data.data.dataManagementFailure.qtdeDays)
        });
    }, [filter]);

    return (
        <>
            {isActiveSignature && <>
                <DivDiagonal/>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="h3 mb-0 text-gray-400"><i className="fa fa-graduation-cap" aria-hidden="true"></i> {t('discipline_report_title')}</h1>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4 h-100">
                    <div className="card-body">
                        <div className="bg-gray-100">
                            <div className="row">
                                <div className="col">
                                    <Form ref={formRef} onSubmit={handleSubmit} className="p-3">
                                        <div className="row">
                                            <div className="col-md-12 pull-left d-flex align-items-center ml-1">
                                                <div className="font-weight-bold mb-1"><nobr><i className="fas fa-funnel-dollar"></i> {t('filter')}</nobr></div>
                                            </div>
                                            <div className="col-md-4 col-xs-12 mt-3">
                                                <InputDate
                                                    name="startDate"
                                                    label={t('filter_start_date')}
                                                    value={firstDayMonth()}
                                                />
                                            </div>
                                            <div className="col-md-4 col-xs-12 mt-3">
                                                <InputDate
                                                    name="endDate"
                                                    label={t('filter_end_date')}
                                                    value={new Date()}
                                                />
                                            </div>
                                            <CustomButton
                                                className="btn btn-primary float-right font-weight-bold text-capitalize mt-3 ml-3 mr-3"
                                                type="submit"
                                                startIcon={<AiOutlineSearch/>}
                                            >
                                                {t('filter_btn_submmit')}
                                            </CustomButton>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-12">
                                <div className="alert alert-dark" role="alert">
                                    <label>
                                        <small className={"text-muted"}>
                                            <b><i className="fa fa-info-circle" aria-hidden="true"></i> {t('discipline_observations')}</b>
                                            <br/>
                                            {t('discipline_observations_1')}
                                            <br/>
                                            {t('discipline_observations_2')}
                                            <br/>
                                            {t('discipline_observations_3')}
                                        </small>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-xl-4 mb-4 ">
                                <CardManagementSuccess
                                    className="card text-gray-800 h-100 py-2"
                                    filter={filter}
                                    data={dataManagementSuccess}
                                />
                            </div>
                            <div className="col-xl-4 mb-4 ">
                                <CardManagementFailure
                                    className="card text-gray-800 h-100 py-2"
                                    filter={filter}
                                    data={dataManagementFailure}
                                />
                            </div>
                            <div className="col-xl-4 mb-4 ">
                                <div className="row">
                                    <div className="col">
                                        <ul className="list-group">
                                            <li className="list-group-item d-flex justify-content-between align-items-center border-secondary  font-weight-bolder">
                                            <span>
                                                {t('discipline_assessment_title')}
                                            </span>
                                                <div className={"float-right"}>
                                                    <TooltipDefault
                                                        className={"ml-2 cursor-pointer"}
                                                        title={t('discipline_assessment_info')}>
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                    </TooltipDefault>
                                                </div>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-center align-items-center text-center">
                                                {totalDays ?
                                                    <>
                                                    {t('discipline_assessment_note', {
                                                        'note': dataManagementSuccess.qtdeDays ? currencyFormat((dataManagementSuccess.qtdeDays *  100 / totalDays) * 10 / 100) : 0
                                                    })}
                                                    </> :
                                                    t('alert_not_fount_register')
                                                }
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    );
}