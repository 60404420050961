import * as Yup from "yup";
import {toast} from "react-toastify";
import React, {useEffect, useRef, useState} from "react";
import messageTypes from "../../../constants/MessagesTypes";
import {Form} from "@unform/web";
import CustomButton from "../../../components/Form/Button";
import {Link, useHistory} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import {FaRegClock} from "react-icons/fa";
import {IoMdSave} from "react-icons/io";
import {edit, getFundamentalTrading, save} from "../../../services/FundamentalTrading";
import {getEvents} from "../../../services/Event";
import InputSelect from "../../../components/Form/InputSelect";
import Fieldset from "../../../components/Fieldset";
import CkeckboxInput from "../../../components/Form/Checkbox";
import InputDateTimePicker from "../../../components/Form/InputDateTimePicker";
import swal from "@sweetalert/with-react";
import {useTranslation} from "react-i18next";

export default function NewFundamentalTrading({match}) {
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState(null);
    const [fundamentalTrading, setFundamentalTrading] = useState(null);
    const formRef = useRef(null);
    const history = useHistory();
    const [m1, setM1] = useState(null);
    const [m2, setM2] = useState(null);
    const [m3, setM3] = useState(null);
    const [m4, setM4] = useState(null);
    const [m5, setM5] = useState(null);
    const [m15, setM15] = useState(null);

    const [imageM1, setImageM1] = useState(null);
    const [imageM2, setImageM2] = useState(null);
    const [imageM3, setImageM3] = useState(null);
    const [imageM4, setImageM4] = useState(null);
    const [imageM5, setImageM5] = useState(null);
    const [imageM15, setImageM15] = useState(null);

    const { t } = useTranslation();

    async function handleSubmmit(data) {
        try {
            if (loading) {
                return;
            }

            setLoading(true);
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                created: Yup.string().required(t('input_required')),
                eventId: Yup.string().required(t('input_required')),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const formData = new FormData();
            formData.append('created', data.created);
            formData.append('eventId', data.eventId);

            if (m1 && m1 !== '') {
                formData.append('resultM1', m1 === 'WIN' ? 'WIN' : 'LOSS');
            }
            if (imageM1 && imageM1 !== '') {
                formData.append('imageM1', imageM1);
            }

            if (m2 && m2 !== '') {
                formData.append('resultM2', m2 === 'WIN' ? 'WIN' : 'LOSS');
            }
            if (imageM2 && imageM2 !== '') {
                formData.append('imageM2', imageM2);
            }

            if (m3 && m3 !== '') {
                formData.append('resultM3', m3 === 'WIN' ? 'WIN' : 'LOSS');
            }
            if (imageM3 && imageM3 !== '') {
                formData.append('imageM3', imageM3);
            }

            if (m4 && m4 !== '') {
                formData.append('resultM4', m4 === 'WIN' ? 'WIN' : 'LOSS');
            }
            if (imageM4 && imageM4 !== '') {
                formData.append('imageM4', imageM4);
            }

            if (m5 && m5 !== '') {
                formData.append('resultM5', m5 === 'WIN' ? 'WIN' : 'LOSS');
            }
            if (imageM5 && imageM5 !== '') {
                formData.append('imageM5', imageM5);
            }

            if (m15 && m15 !== '') {
                formData.append('resultM15', m15 === 'WIN' ? 'WIN' : 'LOSS');
            }
            if (imageM15 && imageM15 !== '') {
                formData.append('imageM15', imageM15);
            }

            const response = (fundamentalTrading) ? await edit(fundamentalTrading.id, formData) : await save(formData);
            if (response && response.status === 200) {
                toast.success(messageTypes.SUCCESS);
                history.push('/economic-calendar');
            } else {
                toast.error(messageTypes.ERROR_SAVE);
            }
            setLoading(false);

        } catch (err) {
            setLoading(false);
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    useEffect(() => {

        const id = match.params.id;
        if (id) {
            setLoading(true);
            getFundamentalTrading(id).then((data) => {
                setFundamentalTrading(data);

                if (data.expireTimes && data.expireTimes.length) {
                    const expireTimes = data.expireTimes;
                    for (var index in expireTimes) {
                        const expireTime = expireTimes[index];
                        if (expireTime.time === 'M1') {
                            setM1(expireTime.win ? 'WIN' : 'LOSS');
                        } else if (expireTime.time === 'M2') {
                            setM2(expireTime.win ? 'WIN' : 'LOSS');
                        } else if (expireTime.time === 'M3') {
                            setM3(expireTime.win ? 'WIN' : 'LOSS');
                        } else if (expireTime.time === 'M4') {
                            setM4(expireTime.win ? 'WIN' : 'LOSS');
                        } else if (expireTime.time === 'M5') {
                            setM5(expireTime.win ? 'WIN' : 'LOSS');
                        }
                    }
                }

            }).finally(() => setLoading(false));
        }

        getEvents().then((data) => {
            if (data && data.data && data.data.data && data.data.data.length) {
                const list = data.data.data;
                let arrayList = [];
                for (var index in list) {
                    const events = list[index];
                    arrayList.push({
                        key: events.id,
                        value: events.name
                    });
                }
                setEvents(arrayList);
            } else {
                setEvents([]);
            }
        });

    }, []);

    function alertImageSize() {
        swal({
            text: t('fundamentalist_limit_img'),
        });
    }

    return (
        <>
            {!loading && <div className="row justify-content-center">
                <div className="col-lg-8 col-xs-12 col-xl-6">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold">
                                {fundamentalTrading ?
                                    <>
                                        <i className="fas fa-edit"/> Editar negociação
                                    </> :
                                    <>
                                        <i className="fas fa-plus"/> {t('fundamentalist_add')}
                                    </>
                                }
                            </h6>
                        </div>
                        <div className="card-body pt-4">
                            <Form ref={formRef} onSubmit={handleSubmmit}>
                                <div className="row mb-3">
                                    <div className="col">
                                        <InputDateTimePicker
                                            name="created"
                                            label={t('fundamentalist_label_input_date') + ' *'}
                                            value={fundamentalTrading ? new Date(fundamentalTrading.created.date) : new Date()}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        {events && <InputSelect
                                            type="text"
                                            name="eventId"
                                            label={t('fundamentalist_label_input_event')}
                                            options={events}
                                            defaultValue={fundamentalTrading && fundamentalTrading.event ? fundamentalTrading.event.id : ''}
                                        />}
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col">
                                        <Fieldset iconLegend={<FaRegClock/>} textLegend={t('fundamentalist_title_fiedset_time') + ' M1'} className="scheduler-border">
                                            <div className="form-check">
                                                <CkeckboxInput
                                                    id="expiryTimeM1"
                                                    name="expiryTimeM1"
                                                    setValue={setM1}
                                                    value={m1 ? m1 : ''}
                                                    options={[
                                                        {name: 'win', value: 'WIN', label: 'Win'},
                                                        {name: 'loss', value: 'LOSS', label: 'Loss'},
                                                    ]}
                                                />
                                                <div>
                                                    <span className={"text-muted"}>{t('fundamentalist_title_fiedset_img')}</span>
                                                    <div className={"cursor-pointer"}>
                                                        <input className={"text-muted"} type="file" accept="image/*" onChange={(event) => {
                                                            const file = event.target.files[0];
                                                            const maxSizeInBytes = 2 * 1024 * 1024;
                                                            if (file && file.size > maxSizeInBytes) {
                                                                alertImageSize();
                                                                event.target.value = null;
                                                                return;
                                                            }
                                                            setImageM1(event.target.files[0]);
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Fieldset>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col">
                                        <Fieldset iconLegend={<FaRegClock/>} textLegend={t('fundamentalist_title_fiedset_time') + ' M2'} className="scheduler-border">
                                            <div className="form-check">
                                                <CkeckboxInput
                                                    id="expiryTimeM2"
                                                    name="expiryTimeM2"
                                                    setValue={setM2}
                                                    value={m2 ? m2 : ''}
                                                    options={[
                                                        {name: 'win', value: 'WIN', label: 'Win'},
                                                        {name: 'loss', value: 'LOSS', label: 'Loss'},
                                                    ]}
                                                />
                                                <div>
                                                    <span className={"text-muted"}>{t('fundamentalist_title_fiedset_img')}</span>
                                                    <div className={"cursor-pointer"}>
                                                        <input className={"text-muted"} type="file" accept="image/*" onChange={(event) => {
                                                            const file = event.target.files[0];
                                                            const maxSizeInBytes = 2 * 1024 * 1024;
                                                            if (file && file.size > maxSizeInBytes) {
                                                                alertImageSize();
                                                                event.target.value = null;
                                                                return;
                                                            }
                                                            setImageM2(event.target.files[0]);
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Fieldset>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col">
                                        <Fieldset iconLegend={<FaRegClock/>} textLegend={t('fundamentalist_title_fiedset_time') + ' M3'} className="scheduler-border">
                                            <div className="form-check">
                                                <CkeckboxInput
                                                    id="expiryTimeM3"
                                                    name="expiryTimeM3"
                                                    setValue={setM3}
                                                    value={m3 ? m3 : ''}
                                                    options={[
                                                        {name: 'win', value: 'WIN', label: 'Win'},
                                                        {name: 'loss', value: 'LOSS', label: 'Loss'},
                                                    ]}
                                                />
                                                <div>
                                                    <span className={"text-muted"}>{t('fundamentalist_title_fiedset_img')}</span>
                                                    <div className={"cursor-pointer"}>
                                                        <input className={"text-muted"} type="file" accept="image/*" onChange={(event) => {
                                                            const file = event.target.files[0];
                                                            const maxSizeInBytes = 2 * 1024 * 1024;
                                                            if (file && file.size > maxSizeInBytes) {
                                                                alertImageSize();
                                                                event.target.value = null;
                                                                return;
                                                            }
                                                            setImageM3(event.target.files[0]);
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Fieldset>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col">
                                        <Fieldset iconLegend={<FaRegClock/>} textLegend={t('fundamentalist_title_fiedset_time') + ' M4'} className="scheduler-border">
                                            <div className="form-check">
                                                <CkeckboxInput
                                                    id="expiryTimeM4"
                                                    name="expiryTimeM4"
                                                    setValue={setM4}
                                                    value={m4 ? m4 : ''}
                                                    options={[
                                                        {name: 'win', value: 'WIN', label: 'Win'},
                                                        {name: 'loss', value: 'LOSS', label: 'Loss'},
                                                    ]}
                                                />
                                                <div>
                                                    <span className={"text-muted"}>{t('fundamentalist_title_fiedset_img')}</span>
                                                    <div className={"cursor-pointer"}>
                                                        <input className={"text-muted"} type="file" accept="image/*" onChange={(event) => {
                                                            const file = event.target.files[0];
                                                            const maxSizeInBytes = 2 * 1024 * 1024;
                                                            if (file && file.size > maxSizeInBytes) {
                                                                alertImageSize();
                                                                event.target.value = null;
                                                                return;
                                                            }

                                                            setImageM4(event.target.files[0]);
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Fieldset>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col">
                                        <Fieldset iconLegend={<FaRegClock/>} textLegend={t('fundamentalist_title_fiedset_time') + ' M5'} className="scheduler-border">
                                            <div className="form-check">
                                                <CkeckboxInput
                                                    id="expiryTimeM5"
                                                    name="expiryTimeM5"
                                                    setValue={setM5}
                                                    value={m5 ? m5 : ''}
                                                    options={[
                                                        {name: 'win', value: 'WIN', label: 'Win'},
                                                        {name: 'loss', value: 'LOSS', label: 'Loss'},
                                                    ]}
                                                />
                                                <div>
                                                    <span className={"text-muted"}>{t('fundamentalist_title_fiedset_img')}</span>
                                                    <div className={"cursor-pointer"}>
                                                        <input className={"text-muted"} type="file" accept="image/*" onChange={(event) => {
                                                            const file = event.target.files[0];
                                                            const maxSizeInBytes = 2 * 1024 * 1024;
                                                            if (file && file.size > maxSizeInBytes) {
                                                                alertImageSize();
                                                                event.target.value = null;
                                                                return;
                                                            }
                                                            setImageM5(event.target.files[0]);
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Fieldset>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col">
                                        <Fieldset iconLegend={<FaRegClock/>} textLegend={t('fundamentalist_title_fiedset_time') + ' M15'} className="scheduler-border">
                                            <div className="form-check">
                                                <CkeckboxInput
                                                    id="expiryTimeM15"
                                                    name="expiryTimeM15"
                                                    setValue={setM15}
                                                    value={m15 ? m15 : ''}
                                                    options={[
                                                        {name: 'win', value: 'WIN', label: 'Win'},
                                                        {name: 'loss', value: 'LOSS', label: 'Loss'},
                                                    ]}
                                                />
                                                <div>
                                                    <span className={"text-muted"}>{t('fundamentalist_title_fiedset_img')}</span>
                                                    <div className={"cursor-pointer"}>
                                                        <input className={"text-muted"} type="file" accept="image/*" onChange={(event) => {
                                                            const file = event.target.files[0];
                                                            const maxSizeInBytes = 2 * 1024 * 1024;
                                                            if (file && file.size > maxSizeInBytes) {
                                                                alertImageSize();
                                                                event.target.value = null;
                                                                return;
                                                            }
                                                            setImageM15(event.target.files[0]);
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Fieldset>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/economic-calendar">
                                            <CustomButton
                                                variant="outlined"
                                                className="btn btn-secondary col-4 float-left font-weight-bold text-capitalize"
                                                startIcon={<BiArrowBack/>}
                                            >
                                                {t('btn_back')}
                                            </CustomButton>
                                        </Link>
                                        <CustomButton
                                            className="btn btn-primary col-4 float-right font-weight-bold text-capitalize"
                                            loading={loading}
                                            type="submit"
                                            startIcon={<IoMdSave/>}
                                        >
                                            {t('btn_save')}
                                        </CustomButton>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}