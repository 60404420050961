import actionsTypes from "../constants/ActionTypes";

const initialState = {
    id: null,
    amount: 0,
    initialValue: 0,
    broker: null,
    coinType: 'R$',
    hideAmount: false,
    totalRetire: 0,
    totalDeposit: 0,
    totalValueLoss: 0,
    qtdeLoss: 0,
    totalValueWin: 0,
    qtdeWin: 0,
    investmentRate: 0,
    profitObtainedToday: null,
    banks: {},
    meta: null,
}

export const BankReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionsTypes.UPDATE_BANK:
            return {
                ...state,
                id: action.bank.id ? action.bank.id : 0,
                amount: parseFloat(action.bank.amount ? action.bank.amount : 0),
                initialValue: parseFloat(action.bank.initialValue ? action.bank.initialValue : 0),
                broker: action.bank.broker,
                hideAmount: action.bank.isHideAmount,
                coinType: action.bank.coinType,
                totalRetire: action.bank.totalRetire ? parseFloat(action.bank.totalRetire) : 0,
                totalDeposit: action.bank.totalDeposit ? parseFloat(action.bank.totalDeposit) : 0,
                totalValueLoss: action.bank.loss ? parseFloat(action.bank.loss.totalValue) : 0,
                investmentRate: action.bank.investmentRate ? parseFloat(action.bank.investmentRate) : 0,
                qtdeLoss: action.bank.loss ? parseInt(action.bank.loss.qtde) : 0,
                totalValueWin: action.bank.win ? parseFloat(action.bank.win.totalValue) : 0,
                qtdeWin: action.bank.win ? parseInt(action.bank.win.qtde) : 0,
                profitObtainedToday: action.bank.profitObtainedToday ? parseFloat(action.bank.profitObtainedToday) : null,
                meta: action.bank.meta ? action.bank.meta : null,
            };
        case actionsTypes.HIDE_AMOUNT:
            return {
                ...state,
                hideAmount: action.hideAmount
            };
        case actionsTypes.SET_BANKS:
            return {
                ...state,
                banks: action.banks
            };
        default:
            return state;
    }
};