import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import brokerTypes from "../../../constants/BrokerTypes";
import {currencyFormat} from "../../../utils/masks";
import {useTranslation} from "react-i18next";

export default function ViewBank() {
    const { t } = useTranslation();
    const {broker, initialValue, amount, coinType, investmentRate} = useSelector((state) => state.BankReducer);

    function getBroker() {
        for (var index in brokerTypes) {
            if (brokerTypes[index].key === broker) {
                return brokerTypes[index].value
            }
        }

        return t('bank_not_found')
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-5 col-sm-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col d-flex flex-column justify-content-center">
                                    <span className="m-0 font-weight-bold">
                                        <nobr><i className="fas fa-wallet"/> {t('bank_text_info')}</nobr>
                                    </span>
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <Link className="btn btn-xs btn-secondary font-weight-bold" to="/bank">
                                            <i className="fas fa-edit"/> {t('edit')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-4">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item pl-0 pr-0">
                                            {t('bank_broker')} <span className="float-right font-weight-bold">{getBroker()}</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center pl-0 pr-0">
                                            {t('bank_initial_value')}
                                            <span className="float-right font-weight-bold">{coinType} {currencyFormat(parseFloat(initialValue))}</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center pl-0 pr-0">
                                            {t('bank_current_value')}
                                            <span className="float-right font-weight-bold">{coinType} {currencyFormat(parseFloat(amount))}</span>
                                        </li>
                                        {investmentRate > 0 && amount ? <>
                                            <li className="list-group-item d-flex justify-content-between align-items-center pl-0 pr-0">
                                                {t('bank_investment_rate')}
                                                <span className="float-right font-weight-bold">{investmentRate.toFixed(2)}%</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between align-items-center pl-0 pr-0">
                                                {t('bank_investment_value')}
                                                <span className="float-right font-weight-bold">{coinType} {currencyFormat(parseFloat(investmentRate * amount / 100))}</span>
                                            </li>
                                        </> : <></>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}