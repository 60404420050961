import React, {useState} from "react";
import {ContainerNews} from "./styles";
import BullOne from "../../assets/img/bull-one.png";
import BullTwo from "../../assets/img/bull-two.png";
import BullThree from "../../assets/img/bull-three.png";
import {useSelector} from "react-redux";
import {Container} from "../Calculadora/styles";
import Events from "../../components/Events";
import FundamentalTrading from "../../components/FundamentalTrading";
import EventReport from "../../components/Report/EventReport";
import {useTranslation} from "react-i18next";

export default function EconomicCalendar() {
    const [deleteItem, setDeleteItem] = useState(null);
    const { t } = useTranslation();
    const {isActiveSignature} = useSelector(
        (state) => state.UserReducer
    );

    return (
        <>
            {isActiveSignature && <>
                <>
                    <Container className="row justify-content-center">
                        <div className="col-lg-12 col-xl-8">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <div className="row">
                                        <div className="col d-flex flex-column justify-content-center">
                                            <span className="m-0 font-weight-bold">
                                                <i className="fa fa-calendar-day"></i> {t('calendar_title')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-4 p-4">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        {[
                                            'gleiton.egues204@gmail.com',
                                            'kleberbarantes@gmail.com',
                                            'queila.oliveira01@gmail.com',
                                            'teste@teste.com'
                                        ].includes(localStorage.getItem('userKey')) && <>
                                            <li className="nav-item">
                                                <a className="nav-link active" id="calendar-notice-tab" data-toggle="tab" href="#calendar" role="tab"
                                                   aria-controls="juros" aria-selected="false">{t('calendar_tab_1')}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="report-events-tab" data-toggle="tab" href="#report-events" role="tab"
                                                   aria-controls="martingale" aria-selected="false">{t('calendar_tab_2')}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="fundamentalista-tab" data-toggle="tab" href="#fundamentalista" role="tab"
                                                   aria-controls="martingale" aria-selected="false">{t('calendar_tab_3')}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="events-tab" data-toggle="tab" href="#events" role="tab"
                                                   aria-controls="soros" aria-selected="false">{t('calendar_tab_4')}</a>
                                            </li>
                                            </>
                                        }
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active pt-4" id="calendar" role="tabpanel"
                                             aria-labelledby="calendar-notice-tab">
                                            <ContainerNews className="row justify-content-center">
                                                <div className={"m-2"}>
                                                    <div>
                                                        <div>
                                                            <div className="row legend">
                                                                <div className="col-md-12">
                                                                    <div className="alert alert-dark" role="alert">
                                                                        <label className={"pr-1"}><img src={BullOne} alt={"Um touro"} style={{ marginLeft: '-8px'}}/> - <b>{t('calendar_volatility_1')}</b></label>
                                                                        <label>
                                                                            <small className={"text-muted"}>{t('calendar_volatility_1_resume')}</small>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="alert alert-dark" role="alert">
                                                                        <label className={"pr-1"}><img src={BullTwo} alt={"Dois touro"} style={{ marginLeft: '-5px'}}/> - <b>{t('calendar_volatility_2')}</b></label>
                                                                        <label>
                                                                            <small className={"text-muted"}> {t('calendar_volatility_2_resume')}</small>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="alert alert-dark" role="alert">
                                                                        <label className={"pr-1"}><img src={BullThree} alt={"Três touro"} className={"pr-1"}/> - <b>{t('calendar_volatility_3')}</b></label>
                                                                        <label>
                                                                            <small className={"text-muted"}> {t('calendar_volatility_3_resume')}</small>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-md-12">
                                                                    <small><i className="fa fa-info-circle"></i> {t('calendar_alert_usd')}</small>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <iframe
                                                                        id={"iframe-calendar"}
                                                                        src="https://sslecal2.investing.com?ecoDayBackground=%23b3b3b3&columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&features=datepicker,timezone&countries=110,17,29,25,32,6,37,36,26,5,22,39,14,48,10,35,7,43,38,4,12,72&calType=day&timeZone=12&lang=12"
                                                                        width="650" height="467" frameBorder="0" allowTransparency="true"
                                                                        marginWidth="0" marginHeight="0">
                                                                    </iframe>
                                                                    <div className="poweredBy">
                                        <span>{t('calendar_credits_par_1')}
                                            <a
                                                href="https://br.investing.com/" rel="nofollow" target="_blank"
                                                className="underline_link">Investing.com Brasil
                                            </a>, {t('calendar_credits_par_2')}
                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ContainerNews>
                                        </div>
                                        <div className="tab-pane fade pt-4" id="report-events" role="tabpanel"
                                             aria-labelledby="report-events-tab">
                                            <EventReport deleteItem={deleteItem}/>
                                        </div>
                                        <div className="tab-pane fade pt-4" id="fundamentalista" role="tabpanel"
                                             aria-labelledby="fundamentalista-tab">
                                            <FundamentalTrading deleteItem={deleteItem} setDeleteItem={setDeleteItem}/>
                                        </div>
                                        <div className="tab-pane fade pt-4" id="events" role="tabpanel"
                                             aria-labelledby="events-tab">
                                            <Events/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </>
            </>}
        </>
    );
}