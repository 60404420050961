import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Container } from './styles';

export default function DefaultLayout({ children }) {
  return (
    <Wrapper id={"containerLayout"}>
      <Container className="d-flex flex-row justify-content-center align-items-center">{children}</Container>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
