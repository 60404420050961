import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {currencyFormat} from "../../../utils/masks";
import {getBank, getMovements} from "../../../services/Bank";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Alert, IconButton} from "@material-ui/core";
import {MdDeleteForever} from "react-icons/md";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {AiOutlineSearch} from "react-icons/ai";
import AlertDelete from "../../../components/Alert/AlertDelete";
import CustomButton from "../../../components/Form/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import messageTypes from "../../../constants/MessagesTypes";
import {actions} from "../../../redux/actions";
import {dateCurrent, dateFirstDayCurrent, firstDayMonth} from "../../../utils/date";
import {Form} from "@unform/web";
import InputDate from "../../../components/Form/InputDate";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export default function Movement() {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [deleteMovement, setDeleteMovement] = useState(null);
    const [totalRate, setTotalRate] = useState(0);
    const [totalRetire, setTotalRetire] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0)
    const {coinType} = useSelector((state) => state.BankReducer);
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const { t } = useTranslation();

    const [filter, setFilter] = useState({
        startDate: dateFirstDayCurrent(),
        endDate: dateCurrent(),
    });

    async function handleSubmit(data) {
        try {

            formRef.current.setErrors({});
            const schema = Yup.object().shape({});

            await schema.validate(data, {
                abortEarly: false,
            });

            setFilter({
                startDate: data.startDate,
                endDate: data.endDate,
            });

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    function updateResume(data) {
        let withdrawals = 0;
        let deposits = 0;
        let rate = 0;
        for (var index in data) {
            if (data[index].type === 'DEPOSIT') {
                deposits = deposits + parseFloat(data[index].value);
            } else {
                withdrawals = withdrawals + parseFloat(data[index].value)

                if (data[index].rate) {
                    rate = rate + parseFloat(data[index].rate)
                }
            }
        }

        setTotalRate(rate);
        setTotalRetire(withdrawals);
        setTotalDeposit(deposits);
    }

    useEffect(() => {
        setLoading(true);
        getMovements({
            page: page,
            per_page: 25,
            typeWinOrLoss: 'WIN',
            startDate: filter.startDate,
            endDate: filter.endDate,
        }).then((data) => {
            if (!data || data === '') {
                return;
            }

            setList(data.data);
            updateResume(data.data.data);
            setLoading(false);
        });

        return function cleanup() {
            setList([]);
        };
    }, [page, deleteMovement === true, filter]);

    useEffect(() => {
        getBank().then((data) => {
            dispatch(actions.updateBank(data));
        });
    }, [deleteMovement === true]);

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col d-flex flex-column justify-content-center">
                                    <span className="m-0 font-weight-bold">
                                        <i className="fas fa-exchange-alt"/> {t('moviment_card_title')}
                                    </span>
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <Link className="ml-2 btn btn-xs btn-danger font-weight-bold" to="/bank/retire">
                                            <i className="fas fa-minus"/> {t('moviment_retire')}
                                        </Link>
                                        <Link className="ml-2 btn btn-xs btn-success font-weight-bold" to="/bank/deposit">
                                            <i className="fas fa-plus"/> {t('moviment_deposit')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-4 p-0">
                            <div className="row p-3">
                                <div className="col-12">
                                    <div className="bg-gray-100">
                                        <div className="row">
                                            <div className="col">
                                                <Form ref={formRef} onSubmit={handleSubmit} className="p-3">
                                                    <div className="row">
                                                        <div className="col-md-12 pull-left d-flex align-items-center ml-1">
                                                            <div className="font-weight-bold mb-1"><nobr><i className="fas fa-funnel-dollar"></i> {t('filter')}</nobr></div>
                                                        </div>
                                                        <div className="col-md-4 col-xs-12 mt-3">
                                                            <InputDate
                                                                name="startDate"
                                                                label={t('filter_start_date')}
                                                                value={firstDayMonth()}
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-xs-12 mt-3">
                                                            <InputDate
                                                                name="endDate"
                                                                label={t('filter_end_date')}
                                                                value={new Date()}
                                                            />
                                                        </div>
                                                        <CustomButton
                                                            className="btn btn-primary float-right font-weight-bold text-capitalize mt-3 ml-3 mr-3"
                                                            type="submit"
                                                            startIcon={<AiOutlineSearch/>}
                                                        >
                                                            {t('filter_btn_submmit')}
                                                        </CustomButton>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="row mb-4 pl-4 pr-4">
                                <div className="col">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item pl-0 pr-0">
                                            {t('moviment_total_rate')} <span className="float-right h5"><b>{coinType} {currencyFormat(parseFloat(totalRate))}</b></span>
                                        </li>
                                        <li className="list-group-item pl-0 pr-0">
                                            {t('moviment_total_retire')} <span className="float-right h5"><b>{coinType} {currencyFormat(parseFloat(totalRetire))}</b></span>
                                        </li>
                                        <li className="list-group-item pl-0 pr-0">
                                            {t('moviment_total_deposit')} <span className="float-right h5"><b>{coinType} {currencyFormat(parseFloat(totalDeposit))}</b></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {loading ?
                                <div className="row pl-4 pr-4 pb-4 pt-0">
                                    <div className="col">
                                        <CustomButton
                                            className="btn btn-primary col-12 text-capitalize font-weight-bold"
                                            loading={loading}
                                            type="button"
                                        >
                                            {t('loading')}
                                        </CustomButton>
                                    </div>
                                </div> :
                                <>
                                    {list && list.data && list.data.length ?
                                        <TableContainer component={Paper}>
                                            <Table aria-label="caption table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className="font-weight-bold text-muted" align="center">{t('moviment_table_col_date')}</TableCell>
                                                        <TableCell className="font-weight-bold text-muted" align="center">{t('moviment_table_col_type')}</TableCell>
                                                        <TableCell className="font-weight-bold text-muted" align="center">{t('moviment_table_col_value')}</TableCell>
                                                        <TableCell className="font-weight-bold text-muted" align="center">{t('retire_label_input_rate')}</TableCell>
                                                        <TableCell className="font-weight-bold text-muted" align="center">{t('moviment_table_col_options')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {list.data.map((row) => (
                                                        <TableRow key={row.id}>
                                                            <TableCell align="center" component="th" scope="row">
                                                                {new Date(row.created.date).toLocaleDateString()}
                                                            </TableCell>
                                                            <TableCell align="center">{row.type === 'DEPOSIT' ? 'Depósito' : 'Retirada'}</TableCell>
                                                            <TableCell align="center">{coinType} {currencyFormat(row.value)}</TableCell>
                                                            <TableCell align="center">
                                                                {row.rate && row.rate > 0 && <>
                                                                    {coinType} {currencyFormat(row.rate)}
                                                                </>}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => {
                                                                        setDeleteMovement(row.id)
                                                                    }}
                                                                >
                                                                    <MdDeleteForever/>
                                                                    {row.id === deleteMovement && <AlertDelete
                                                                        text={row.type === 'DEPOSIT' ?
                                                                            t('deposit_delete', {
                                                                                'value': `${coinType} ${currencyFormat(row.value)}`
                                                                            }) :
                                                                            t('retire_delete', {
                                                                                'value': `${coinType} ${currencyFormat(row.value)}`
                                                                            })
                                                                        }
                                                                        setSuccessDelete={setDeleteMovement}
                                                                        urlDelete={process.env.REACT_APP_API_ENDPOINT+`/api/bank/movement/delete/${row.id}`}
                                                                    />}
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableCell colSpan="5">
                                                        <span className="text-muted text-info float-left pl-4 pt-2">
                                                            {t('table_pagination_text', {
                                                                'page': page,
                                                                'totalPage': list.total_page + 1
                                                            })}
                                                        </span>
                                                        <button
                                                            style={{ cursor: !list.next_page ? 'not-allowed' : 'pointer'}}
                                                            type="button"
                                                            onClick={() => setPage(page + 1)}
                                                            disabled={!list.next_page}
                                                            className="btn btn-light mr-3 float-right">
                                                            {t('table_pagination_next')} <IoIosArrowForward
                                                        />
                                                        </button>
                                                        <button
                                                            style={{ cursor: page <= 1 ? 'not-allowed' : 'pointer'}}
                                                            type="button"
                                                            onClick={() => setPage(page - 1)}
                                                            disabled={page <= 1}
                                                            className="btn btn-light mr-3 float-right"
                                                        >
                                                            <IoIosArrowBack/> {t('table_pagination_back')}
                                                        </button>
                                                    </TableCell>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer> :
                                        <div className="row pl-4 pr-4 pb-4 pt-0">
                                            <div className="col">
                                                <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}