import React, {useEffect, useState} from "react";
import {getReport} from "../../../services/Trading";
import {useTranslation} from "react-i18next";

export default function CardTotalDraw({filter, ...props}) {
    const [percentQtdeDraw, setPercentQtdeDraw] = useState(null);

    const [qtdeWin, setQtdeWin] = useState(null);
    const [qtdeLoss, setQtdeLoss] = useState(null);
    const [qtdeDraw, setQtdeDraw] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const value = (qtdeLoss + qtdeWin + qtdeDraw) ? qtdeDraw * 100 / (qtdeLoss + qtdeWin + qtdeDraw) : 0;
        setPercentQtdeDraw(value.toFixed(2));
    }, [qtdeWin, qtdeLoss, qtdeDraw]);

    useEffect(() => {
        getReport({
            startDate: filter[0].startDate,
            endDate: filter[0].endDate,
            orderBy: 'profitObtained',
            order: 'DESC'
        }).then((data) => {
            if (!data || data === '' || !data.data) {
                return;
            }

            setQtdeWin(data.data.win.qtde);
            setQtdeLoss(data.data.loss.qtde);
            setQtdeDraw(data.data.draw.qtde);
        });
    }, [filter]);

    return (
        <div {...props} style={{backgroundColor: 'rgba(192,192,192,0.3)'}}>
            <div className="card-body pt-0 pb-0">
                <div className="row no-gutters align-items-center">
                    <div className="col">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item pr-0 pl-0" style={{backgroundColor: 'rgba(192,192,192,0)'}}>
                                <div className="text-xs font-weight-bold mb-1">{t('traging_report_card_draw')}</div>
                                <div className="h5 mb-0 font-weight-bold">
                                    <span>{qtdeDraw}</span>
                                    <span className="float-right badge badge-secondary">{percentQtdeDraw}%</span>
                                </div>
                            </li>
                            <li className="list-group-item p-0 text-center pt-2" style={{backgroundColor: 'rgba(192,192,192,0)'}}>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
