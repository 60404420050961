import React, {useEffect, useRef, useState} from "react";
import Chart from "chart.js";
import {getReport} from "../../../services/Trading";
import {Alert} from "@material-ui/core";
import messageTypes from "../../../constants/MessagesTypes";
import {useTranslation} from "react-i18next";

export default function GeneralDoughnut({filter, ...props}) {
    const chartRef = useRef(null);
    const { t } = useTranslation();

    const [qtdeWin, setQtdeWin] = useState(0);
    const [qtdeLoss, setQtdeLoss] = useState(0);
    const [qtdeDraw, setQtdeDraw] = useState(0);
    const [isData, setData] = useState(null);
    const [percentDrawState, setPercentDrawState] = useState(0);
    const [percentLossState, setPercentLossState] = useState(0);
    const [percentWinState, setPercentWinState] = useState(0);

    useEffect(() => {
        let percentDraw = (qtdeLoss + qtdeWin + qtdeDraw) ? qtdeDraw * 100 / (qtdeLoss + qtdeWin + qtdeDraw) : 0;
        percentDraw = percentDraw.toFixed(2);
        setPercentDrawState(percentDraw);

        let percentLoss = (qtdeLoss + qtdeWin + qtdeDraw) ? qtdeLoss * 100 / (qtdeLoss + qtdeWin + qtdeDraw) : 0;
        percentLoss = percentLoss.toFixed(2);
        setPercentLossState(percentLoss);

        let percentWin = (qtdeLoss + qtdeWin + qtdeDraw) ? qtdeWin * 100 / (qtdeLoss + qtdeWin + qtdeDraw) : 0;
        percentWin = percentWin.toFixed(2);
        setPercentWinState(percentWin);

        if (!chartRef || !isData) {
            return
        }
        var xValues = [qtdeLoss + ` ${t('defeats')}`, qtdeDraw + ` ${t('draws')}`, qtdeWin + ` ${t('victories')}`];
        var yValues = [percentLoss, percentDraw, percentWin];
        var barColors = [
            "#e74a3b",
            "#848796",
            "#1cc88a",
        ];

        const myChartRef = chartRef.current.getContext("2d");
        new Chart(myChartRef, {
            type: "doughnut",
            data: {
                labels: xValues,
                datasets: [{
                    backgroundColor: barColors,
                    data: yValues,
                    display: true,
                }],
            },
            options: {
                legend: {
                    display: true,
                    position: 'right',
                }
            }
        });
    }, [qtdeWin, qtdeLoss, qtdeDraw]);

    useEffect(() => {
        getReport({
            startDate: filter[0].startDate,
            endDate: filter[0].endDate,
            direction: filter[0].direction,
            orderBy: 'profitObtained',
            order: 'DESC'
        }).then((data) => {
            if (!data || data === '' || !data.data) {
                setData(false);
                return;
            }

            setData((data.data.win.qtde + data.data.loss.qtde + data.data.draw.qtde) > 0);
            setQtdeWin(data.data.win.qtde);
            setQtdeLoss(data.data.loss.qtde);
            setQtdeDraw(data.data.draw.qtde);
        });
    }, [filter]);

    return (
        <div {...props}>
            {isData ?
                <>
                    <div className="col">
                        <canvas id="myAreaChart" ref={chartRef} style={{ width: '100%', maxWidth: '600px'}}/>
                    </div>
                    <div className="row">
                        <div className="col-4 text-muted">
                            <small>
                                <i className="fas fa-circle text-danger"/> {parseFloat(percentLossState).toFixed(2)}%
                            </small>
                        </div>
                        <br/>
                        <div className="col-4 text-muted">
                            <small>
                                <i className="fas fa-circle"/> {parseFloat(percentDrawState).toFixed(2)}%
                            </small>
                        </div>
                        <div className="col-4 text-muted">
                            <small>
                                <i className="fas fa-circle text-success"/> {parseFloat(percentWinState).toFixed(2)}%
                            </small>
                        </div>
                    </div>
                </> :
                <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
            }
        </div>
    );
}
