import styled from "styled-components";

export const Container = styled.div`
    .active {
      font-weight: bold;
    }
  
    a {
      color: #858796;
    }
`;