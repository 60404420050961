import React, {useRef, useState} from "react";
import {Container} from "./styles";
import InputMask from "../../../components/Form/InputMask";
import {currencyFormat} from "../../../utils/masks";
import {InputAdornment} from "@material-ui/core";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import * as Yup from "yup";
import messageTypes from "../../../constants/MessagesTypes";
import CustomButton from "../../../components/Form/Button";
import {Form} from "@unform/web";
import {useTranslation} from "react-i18next";

export default function Porcentagem() {
    const { coinType} = useSelector((state) => state.BankReducer);
    const formRef1 = useRef(null);
    const formRef2 = useRef(null);
    const { t } = useTranslation();
    const [resultCalc1, setResultCalc1] = useState(null);
    const [resultCalc2, setResultCalc2] = useState(null);

    async function handleSubmitCalc1(data) {
        try {

            formRef1.current.setErrors({});
            const schema = Yup.object().shape({
                value: Yup.string().required(t('input_required')),
                valuePercent: Yup.string().required(t('input_required')),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const value = parseFloat(data.value.replace(/[^0-9,-]*/g, '').replace(',', '.'));
            const valuePercent = parseFloat(data.valuePercent);
            if (!parseInt(value) || !parseInt(valuePercent)) {
                toast.warning(t('calc_invalid_values'));
                return;
            }

            const result = (valuePercent / 100) * value;
            setResultCalc1(currencyFormat(result));

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef1.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    async function handleSubmitCalc2(data) {
        try {

            formRef2.current.setErrors({});
            const schema = Yup.object().shape({
                value: Yup.string().required(t('input_required')),
                total: Yup.string().required(t('input_required')),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const value = parseFloat(data.value.replace(/[^0-9,-]*/g, '').replace(',', '.'));
            const total = parseFloat(data.total);
            if (!parseInt(value) || !parseInt(total)) {
                toast.warning(t('calc_invalid_values'));
                return;
            }

            let result = (value / total) * 100;
            setResultCalc2(result);

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef2.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    return (
        <Container>
            <Form ref={formRef1} onSubmit={handleSubmitCalc1}>
                <div className="row justify-content-center mb-3">
                    <div className="col-xs-12 col-md-6 mt-3">
                        <div className="row">
                            <div className="col-12 d-flex align-items-center">
                                <span className={'mr-2'}><nobr>Quanto é</nobr></span>
                                <InputMask
                                    size="small"
                                    type="text"
                                    name="valuePercent"
                                    label={' '}
                                    mask={'percent'}
                                    InputProps={{
                                        endAdornment: <InputAdornment position={'end'}>%</InputAdornment>,
                                    }}
                                />
                                <span className={'mr-2 ml-2'}>de</span>
                                <InputMask
                                    size="small"
                                    type="text"
                                    name="value"
                                    label={' '}
                                    mask={'currency'}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{coinType}</InputAdornment>,
                                    }}
                                />
                                <div>
                                    <CustomButton
                                        className="btn btn-primary font-weight-bold text-capitalize ml-3"
                                        type="submit"
                                    >
                                        {t('calc_btn_calculate')}
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center mt-4 h4">
                                Resultado: <span className={'font-weight-bold'}><b>{resultCalc1 ? coinType + ' ' + resultCalc1 : '____'}</b></span>
                            </div>
                        </div>
                        <hr/>
                    </div>
                </div>
            </Form>
            <Form ref={formRef2} onSubmit={handleSubmitCalc2}>
                <div className="row justify-content-center mb-3">
                    <div className="col-xs-12 col-md-8 mt-3">
                        <div className="row">
                            <div className="col-12 d-flex align-items-center">
                                <span className={'mr-2'}><nobr>O valor</nobr></span>
                                <InputMask
                                    size="small"
                                    type="text"
                                    name="value"
                                    label={' '}
                                    mask={'currency'}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{coinType}</InputAdornment>,
                                    }}
                                />
                                <span className={'mr-2 ml-2'}><nobr>é qual porcentagem de</nobr></span>
                                <InputMask
                                    size="small"
                                    type="text"
                                    name="total"
                                    label={' '}
                                    mask={'currency'}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{coinType}</InputAdornment>,
                                    }}
                                />
                                <div>
                                    <CustomButton
                                        className="btn btn-primary font-weight-bold text-capitalize ml-3"
                                        type="submit"
                                    >
                                        {t('calc_btn_calculate')}
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center mt-4 h4">
                                Resultado: <span
                                className={'font-weight-bold'}><b>{resultCalc2 ? resultCalc2 + '%' : '____'}</b></span>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Container>
    );
}