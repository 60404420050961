import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './services/i18n';

//Boostrap Libs
import 'bootstrap/dist/js/bootstrap.bundle.min';

//SBAdmin2 Style
import './styles/scss/sb-admin-2.scss';

//Redux
import { Provider } from 'react-redux';
import { Store } from './redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init('610362114240467', null, {
    autoConfig: true,
    debug: false,
});
ReactPixel.pageView();

ReactDOM.render(
<Provider store={Store}>
    <GoogleOAuthProvider clientId="766162823336-fnsughjqb21vkegorsb8fup8st0khups.apps.googleusercontent.com">
        <App />
    </GoogleOAuthProvider>
</Provider> , document.getElementById('root'));