import api from '../api';
import {toast} from "react-toastify";
import messageTypes from "../../constants/MessagesTypes";

export async function getEvents(data) {
    return api.get(`/api/event/list`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return[];
    });
}

export async function getEvent($id) {
    try {

        const response = await api.get(`/api/event/${$id}/find`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                auth: true
            },
        });

        return response.data.data ? response.data.data : {};

    } catch (err) {
        toast.error(messageTypes.ERROR_LOAD);
        throw err;
    }
}

export function edit($id, $event) {
    return api.post(`/api/event/edit/${$id}`, $event, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export function save($event) {
    return api.post('/api/event/new', $event, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}