import styled from 'styled-components';

export const Container = styled.fieldset`
    border: 1px solid #c0c0c0!important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
    box-shadow:  0px 0px 0px 0px #000;
    border-radius: 6px!important;
    color: ${({ theme }) => theme.colors.tertiary};

    legend {
        width:inherit;
        border-bottom:none;
        padding: 0px 10px 0;
    }
    
    span.MuiFormControlLabel-label {
        color: ${({ theme }) => theme.colors.tertiary}!important;
    }
`;
