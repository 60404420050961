import React, {useState} from "react";
import {Container} from "./styles";
import CustomButton from "../../components/Form/Button";
import {BsWhatsapp} from "react-icons/bs";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import messageTypes from "../../constants/MessagesTypes";
import {newSignature} from "../../services/Profile";
import {FaRegClock} from "react-icons/fa";
import Fieldset from "../../components/Fieldset";
import {MdOutlineSecurity} from "react-icons/md";
import mercadoPagoFormasPagamentoIMG from "../../assets/img/mercadoPagoFormasPagamento.webp";

export default function Plans() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    async function handleNewSignature(type) {
        try {
            setLoading(true);
            const types = ['FREE','SEMIANNUAL','YEARLY'];
            if (!types.includes(type)) {
                return;
            }

            if (type === 'FREE') {
                history.push('/dashboard');
                return;
            }

            const formData = new FormData();
            formData.append('type', type);

            const response = await newSignature(formData);
            const linkPayment = response.data && response.data.linkPayment ? response.data.linkPayment : null;
            if (linkPayment) {
                window.location.href = linkPayment;
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error(messageTypes.ERROR_SIGNATURE);
        }
    }
    return (
        <>
            <Container className={"card shadow container mb-4 col-md-8 col-sm-12"}>
                <div className="row mt-2 d-none">
                    <div className="col-12">
                        <nobr>
                            <small><i className="fa fa-exclamation-triangle" aria-hidden="true"/> Promoção por tempo limitado </small>
                        </nobr>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-12">
                        <div className="alert alert-primary pt-4" role="alert">
                            <p>
                                Caso desejar
                                <a className="pl-1 font-weight-bolder" href="https://web.whatsapp.com/send?phone=38999361310&text=Olá! Quero ativar minha assinatura na plataforma GBinary." target="_blank">
                                    <u>clique aqui</u>
                                </a> para solicitar a ativação da sua assinatura através do nosso suporte  via Whatsapp <BsWhatsapp color={'#25D366'}/>.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <div className="row pr-5 pl-5">
                        <div className="col-md-12 text-center col-lg-6 pt-3">
                            <div className="col-12 card-plan pt-4 pb-4">
                                <h6 className="lead font-weight-bold pricing-plan-title">Plano Semestral</h6>
                                <p className="font-weight-bold pricing-plan-amount">
                                    <nobr>R$ <span className="h1 font-weight-bolder">3,99</span>/mês</nobr>
                                </p>
                                <p className="small">
                                    Total semestral R$ 23,94<br/>
                                    Valor cobrado a cada 06 meses
                                </p>
                                {!loading && <CustomButton
                                    className="btn text-capitalize btn-outline-primary font-weight-bold cursor-pointer"
                                    type="button"
                                    onClick={() => {
                                        handleNewSignature('SEMIANNUAL');
                                    }}
                                >
                                    Assinar
                                </CustomButton>}
                            </div>
                        </div>
                        <div className="col-md-12 text-center col-lg-6 pt-3">
                            <div className="col-12 card-plan pt-4 pb-4">
                                <h6 className="lead font-weight-bold pricing-plan-title">Plano Anual</h6>
                                <p className="font-weight-bold pricing-plan-amount">
                                    <nobr>R$ <span className="h1 font-weight-bolder">2,99</span>/mês</nobr>
                                </p>
                                <p className="small">
                                    Total anual R$ 35,88<br/>
                                    Valor cobrado a cada 12 meses
                                </p>
                                {!loading && <CustomButton
                                    className="btn text-capitalize btn-outline-primary font-weight-bold cursor-pointer"
                                    type="button"
                                    onClick={() => {
                                        handleNewSignature('YEARLY');
                                    }}
                                >
                                    Assinar
                                </CustomButton>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-12">
                        <Fieldset iconLegend={<MdOutlineSecurity/>} textLegend={'Pagamento seguro'} className="scheduler-border">
                            <div className="col-12 text-center">
                                <img  src={mercadoPagoFormasPagamentoIMG}/>
                            </div>
                        </Fieldset>
                    </div>
                </div>
                <div className="row pb-5">
                    <div className="col-12">
                        <p>
                            A realização do pagamento do produto/serviço não ocorrerá por meio da Empresa, mas sim por meio de Gateway de Pagamento (processador e facilitador de pagamento) Mercado Pago. Os dados bancários solicitados ficarão na posse desse Gateway de Pagamento, de modo que a Empresa só terá a informação se o pagamento foi efetuado com sucesso, ou não.
                        </p>
                        <p className={"text-center pt-4"}>
                            Alguma dúvida? Fale conosco
                            <b>
                                <a className="text-muted pl-1" href="https://web.whatsapp.com/send?phone=38999361310" target="_blank">
                                    <BsWhatsapp color={'#25D366'}/> <u>(38) 99936-1310</u>
                                </a>
                            </b>
                            <br/>
                            <div>E-mail para contato: <b>app.gbinary@gmail.com</b></div>
                        </p>
                    </div>
                </div>
            </Container>
        </>
    );
}