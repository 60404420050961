import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import CustomButton from "../Form/Button";
import {currencyFormat} from "../../utils/masks";
import {cancelImport, importDataQuotex} from "../../services/Trading";
import messageTypes from "../../constants/MessagesTypes";
import {Alert} from "@material-ui/core";
import swal from "@sweetalert/with-react";
import {toast} from "react-toastify";
import CircularProgressWithLabel from "../CircularProgressWithLabel";
import {useTranslation} from "react-i18next";

export default function QuotexCSV({fileCSV}) {
    const [progress, setProgress] = React.useState(0);
    const [itemsFile, setItemsFile] = useState(null);
    const [importId, setImportId] = useState(null);
    const [valueFile, setValueFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const {coinType, amount} = useSelector((state) => state.BankReducer);
    const fileReader = new FileReader();
    const history = useHistory();
    const { t } = useTranslation();

    const parseCSV = (text) => {
        const result = {
            header: [],
            data: []
        };

        const [header, ...content] = text.split('\n');
        result.header = header.split(',');
        const maxCols = result.header.length;

        content.forEach((item) => {
            result.data.push(item.split(',').slice(0, maxCols));
        });

        return result;
    };

    async function importStartFile(indexStart = 0, importIdResponse = null) {

        try {
            setLoading(true);
            var qtdeMaxImport = 10;
            var indexEnd = indexStart + qtdeMaxImport;
            const newProgress = indexStart / (itemsFile.length - 1) * 100;
            setProgress(newProgress > 100 ? 100 : newProgress);

            var data = {
                importId: importIdResponse,
                qtdeItems: itemsFile.length,
                value: valueFile,
                items: itemsFile.slice(indexStart, indexEnd)
            };

            const formData = new FormData();
            formData.append('data', JSON.stringify(data));

            const response = await importDataQuotex(formData);
            importIdResponse = response.data && response.data.importId ? response.data.importId : importIdResponse;
            if (!importId) {
                setImportId(importIdResponse);
            }

            if (indexStart < itemsFile.length - 1) {
                importStartFile(indexStart + data.items.length, importIdResponse);
            } else {
                setProgress(100);
                toast.success(messageTypes.SUCCESS_IMPORT);
                setTimeout(() => {
                    history.push('/trading');
                }, 1000);
            }
        } catch (e) {

            try {
                await cancelImport(importId);
                toast.error(messageTypes.ERROR_IMPORT);
            } catch (e) {
                toast.error(messageTypes.ERROR_IMPORT);
            }
        }
    }

    useEffect(() => {
        if (!fileCSV) {
            return;
        }

        fileReader.onload = function (event) {
            const csvOutput = event.target.result;
            const result = parseCSV(csvOutput);
            setItemsFile(result.data);
        };

        fileReader.readAsText(fileCSV);
    }, [fileCSV]);

    useEffect(() => {
        if (!itemsFile) {
            return;
        }

        let addValueBank = 0;
        for (let index = 0; index < itemsFile.length - 1; index++) {
            for (let i = 0; i < itemsFile[index].length; i++) {
                itemsFile[index][i] = (itemsFile[index][i]).replaceAll("\"", "");
            }
            const profitPrejudice = parseFloat(itemsFile[index][9]) - parseFloat(itemsFile[index][8]);
            addValueBank = addValueBank + profitPrejudice;
        }

        setValueFile(addValueBank);
    }, [itemsFile]);

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-7 col-xl-6 col-sm-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col d-flex flex-column justify-content-center">
                                    <span className="m-0 font-weight-bold">
                                        <nobr> CSV - {t('traging_import_card_title')}</nobr>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-4">
                            {itemsFile && itemsFile.length ?
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item pl-0 pr-0">
                                                    {t('traging_import_tradings')} <span
                                                    className="float-right font-weight-bold">{itemsFile && itemsFile.length - 1 > 0 ? itemsFile.length - 1 : ''}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center pl-0 pr-0">
                                                    {t('traging_import_tradings_value')}
                                                    <span
                                                        className="float-right font-weight-bold">{coinType} {currencyFormat(valueFile)}</span>
                                                </li>
                                                <li className="list-group-item list-group-item-info d-flex border-bottom-info pl-3 pr-3 mt-4 font-weight-bold justify-content-between align-items-center pl-0 pr-0">
                                                    {t('traging_import_bank_balance')}
                                                    <span
                                                        className="float-right font-weight-bold">{coinType} {currencyFormat(amount + valueFile)}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <hr/>
                                    {!loading ? <>
                                            <div className="row">
                                                <div className="col-12">
                                                    <Link to="/trading">
                                                        <CustomButton
                                                            variant="outlined"
                                                            className="btn btn-secondary float-left font-weight-bold text-capitalize"
                                                        >
                                                            {t('btn_back')}
                                                        </CustomButton>
                                                    </Link>
                                                    <CustomButton
                                                        loading={loading}
                                                        onClick={() => {
                                                            swal(
                                                                {
                                                                    title: t('traging_import_alert_start_title'),
                                                                    text: t('traging_import_alert_start_text'),
                                                                    icon: "warning",
                                                                    buttons: [t('traging_import_alert_start_cancel'), t('traging_import_alert_start_confirm')],
                                                                }
                                                            ).then((isConfirm) => {
                                                                if (isConfirm) {
                                                                    importStartFile()
                                                                }
                                                            });
                                                        }}
                                                        className="btn btn-primary float-right font-weight-bold text-capitalize"
                                                    >
                                                        {t('traging_import_start')}
                                                    </CustomButton>
                                                </div>
                                            </div>
                                        </> :
                                        <>
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-center">
                                                    <CircularProgressWithLabel value={progress}/>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <span>{t('traging_import_start_text')}</span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </> :
                                <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}