import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 1000; /* Certifique-se de que o botão esteja acima de outros elementos */

    :hover {
        background-color: #128C7E;
    }
`;