import React from 'react';

import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import PropTypes from "prop-types";

export default function Layout({ children }) {
    return (
        <div>
            <div id="wrapper" >
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Topbar/>
                        <div className="container-fluid">
                            { children }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.element.isRequired,
};