import React, {useEffect} from 'react';
import {ThemeProvider} from 'styled-components';
import {Router} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import history from './services/Navigate/history';
import Routes from './routes';
import GlobalStyle from './styles/global';
import theme from './styles/theme';
import '../node_modules/react-toastify/dist/ReactToastify.css';
import swal from "@sweetalert/with-react";
import {isMobile} from "react-device-detect";
import googlePlayImg from "./assets/img/google-play.png";
import WhatsAppButton from "./components/WhatsAppButton";

function App() {

    useEffect(() => {
        const signed = localStorage.getItem('@user_token');
        if (!signed && !(
            window.location.pathname === '/policy-privacy' ||
            window.location.pathname === '/login' ||
            window.location.pathname === '/register' ||
            window.location.pathname === '/' ||
            window.location.pathname === ''
        )) {
            window.location.href = '/';
        }

        if (isMobile) {
            swal(
                {
                    button: false,
                    content: <div>
                        <div className="work_item">
                            <div className={"img-trading-modal"}>
                                <h4 className={"mb-4"}>Para ter uma melhor experiência, baixe nosso App</h4>
                                <a href={"https://play.google.com/store/apps/details?id=com.appgbinary"}>
                                    <img
                                        className={"cursor-pointer"}
                                        src={googlePlayImg}
                                        width={'100%'}
                                        height={"100%"}
                                        alt={'Imagem Google Play'}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>,
                }
            );
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Router history={history}>
                <Routes />
            </Router>
            <ToastContainer />
            <WhatsAppButton/>
            <GlobalStyle />
        </ThemeProvider>
    );
}

export default App;
