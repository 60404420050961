export function dateCurrent() {
    return ((new Date()).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' }));
}

export function firstDayMonth() {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function dateFirstDayCurrent() {
    return firstDayMonth().toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' });
}