import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {currencyFormat} from "../../../utils/masks";
import {BiSmile} from "react-icons/bi";
import {CgSmileSad} from "react-icons/cg";
import {getReport} from "../../../services/Trading";
import {useTranslation} from "react-i18next";

export default function CardProfitPrejudice({filter, ...props}) {
    const {coinType, initialValue} = useSelector((state) => state.BankReducer);
    const [profitPrejudice, setProfitPrejudice] = useState(null);
    const [percentTotalValue, setPercentTotalValue] = useState(null);

    const [qtdeWin, setQtdeWin] = useState(null);
    const [qtdeLoss, setQtdeLoss] = useState(null);
    const [qtdeDraw, setQtdeDraw] = useState(null);
    const { t } = useTranslation();

    const updateData = useCallback(() => {
        getReport({
            startDate: filter[0].startDate,
            endDate: filter[0].endDate,
            orderBy: 'profitObtained',
            order: 'DESC'
        }).then((data) => {
            if (!data || data === '' || !data.data) {
                return;
            }

            const valueWin = parseFloat(data.data.win.totalValue ? data.data.win.totalValue : 0);
            const valueLoss = parseFloat(data.data.loss.totalValue ? data.data.loss.totalValue : 0);

            setQtdeWin(data.data.win.qtde);
            setQtdeLoss(data.data.loss.qtde);
            setQtdeDraw(data.data.draw.qtde);

            setProfitPrejudice(valueWin + valueLoss);
            let value = valueWin + valueLoss;
            value = (value && initialValue) ? value * 100 / initialValue : 0;
            setPercentTotalValue(value.toFixed(2));
        });
    }, [initialValue, filter]);

    useEffect(updateData, [filter, initialValue]);

    return (
        <div {...props}
             className={`
                h-100 py-2 card ${profitPrejudice > 0 ? 'bg-success text-gray-800' : ''} 
                ${profitPrejudice < 0 ? 'bg-danger text-gray-800' : ''} 
                ${profitPrejudice === 0 ? 'bg-secondary text-white' : ''}`
             }
        >
            <div  style={{border: 'none'}} className={`
                h-100 py-2 card ${profitPrejudice > 0 ? 'bg-success' : ''} 
                ${profitPrejudice < 0 ? 'bg-danger' : ''} 
                ${profitPrejudice === 0 ? 'bg-secondary' : ''}`
            }>
                <div className="card-body pt-0 pb-0">
                    <div className="row no-gutters align-items-center">
                        <div className="col">
                            <ul className="list-group list-group-flush text-white">
                                <li className={
                                    `list-group-item pr-0 pl-0
                                    h-100 py-2 card ${profitPrejudice > 0 ? 'bg-success' : ''}
                                    ${profitPrejudice < 0 ? 'bg-danger' : ''}
                                    ${profitPrejudice === 0 ? 'bg-secondary' : ''}
                                `}>
                                    <div className="text-xs font-weight-bold mb-1">{t('traging_report_card_resume_total')}</div>
                                    <div className="h5 mb-0 font-weight-bold">
                                        <span>{qtdeLoss + qtdeWin + qtdeDraw}</span>
                                    </div>
                                </li>
                                <li className={
                                    `list-group-item pr-0 pl-0
                                    h-100 py-2 card ${profitPrejudice > 0 ? 'bg-success' : ''}
                                    ${profitPrejudice < 0 ? 'bg-danger' : ''}
                                    ${profitPrejudice === 0 ? 'bg-secondary' : ''}
                                `}>
                                    {profitPrejudice > 0 && <div className="text-xs font-weight-bold mb-1">
                                        {t('traging_report_card_resume_profit')} <BiSmile size={20} className="ml-1"/>
                                    </div>}
                                    {profitPrejudice < 0 && <div className="text-xs font-weight-bold mb-1">
                                        {t('traging_report_card_resume_prejudice')} <CgSmileSad size={20} className="ml-1"/>
                                    </div>}
                                    {profitPrejudice === 0 && <div className="text-xs font-weight-bold mb-1">
                                        {t('traging_report_card_resume_profit_prejudice')}
                                    </div>}
                                    <div className="h5 mb-0 font-weight-bold">
                                        <span>{coinType} {currencyFormat(profitPrejudice)}</span>
                                        <span className={"float-right"} style={{ marginTop: '-20px'}}>
                                            <div className="text-xs font-weight-bold mb-1">
                                                {t('banking_growth')}
                                            </div>
                                            {profitPrejudice > 0 && <span className="float-right badge badge-success">
                                            + {percentTotalValue}%
                                            </span>}
                                            {profitPrejudice < 0 && <span className="float-right badge badge-danger">
                                                {percentTotalValue}%
                                            </span>}
                                            {profitPrejudice === 0 && <span className="float-right badge badge-secondary">
                                            {percentTotalValue}%
                                        </span>}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
