import React, {useCallback, useEffect, useRef, useState} from "react";
import {getDataGroupByDaily} from "../../../services/Trading";
import {Alert} from "@material-ui/core";
import messageTypes from "../../../constants/MessagesTypes";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {getDataPerfomanceDailyFundamental} from "../../../services/FundamentalTrading";
import {Form} from "@unform/web";
import InputDate from "../../Form/InputDate";
import CustomButton from "../../Form/Button";
import {AiOutlineSearch} from "react-icons/ai";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export default function TradingPerfomanceAgroupDailyGeneral({...props}) {
    const [isData, setData] = useState(null);
    const [options, setOptions] = useState([]);
    const [resultAnalysis, setResultAnalysis] = useState(null);
    const [year,] = useState();
    const [month,] = useState();
    const formRef = useRef(null);
    const { t } = useTranslation();

    const nameMonth = [
        t('month_1'), t('month_2'), t('month_3'), t('month_4'), t('month_5'), t('month_6'),
        t('month_7'), t('month_8'), t('month_9'), t('month_10'), t('month_11'), t('month_12')
    ];

    const [filter, setFilter] = useState({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
    });

    async function handleSubmit(data) {
        try {

            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                date: Yup.string().required(t('input_required')),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const parts = data.date.split("/");
            setFilter({
                month: parseInt(parts[0]),
                year: parts[1],
            });

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    useEffect( () => {
        getDataPerfomanceDailyFundamental({
            month: month,
            year: year,
        }).then((dataFundamental) => {

            if (!dataFundamental || dataFundamental === '' || !dataFundamental.data) {
                return;
            }

            setData(true);
            var dataFundamental = dataFundamental.data.data;
            getDataGroupByDaily({
                month: filter.month,
                year: filter.year,
            }).then((data) => {
                if (!data || data === '' || !data.data) {
                    setData(false);
                    return;
                }

                setData(true);

                var resultGeneral = [];
                for (var index in dataFundamental) {
                    var itemFundamental = dataFundamental[index];
                    for (var key in data.data.data) {
                        var item = data.data.data[key];

                        if (item.day === itemFundamental.day) {
                            const qtdeWin = item.data[0]['qtdeWin'] ? parseInt(item.data[0]['qtdeWin']) : 0;
                            const qtdeLoss = item.data[0]['qtdeLoss'] ? parseInt(item.data[0]['qtdeLoss']) : 0;
                            const qtdeDraw = item.data[0]['qtdeDraw'] ? parseInt(item.data[0]['qtdeDraw']) : 0;

                            resultGeneral.push({
                                day: item.day,
                                qtdeLoss: parseInt(itemFundamental.data[0].qtdeLoss ? itemFundamental.data[0].qtdeLoss : 0) + qtdeLoss,
                                qtdeWin: parseInt(itemFundamental.data[0].qtdeWin ? itemFundamental.data[0].qtdeWin : 0) + qtdeWin,
                                qtdeDraw: qtdeDraw,
                            });

                            break;
                        }
                    }
                }

                var result = [];
                for (var i in resultGeneral) {
                    var itemGeneral = resultGeneral[i];

                    const qtdeWin = parseInt(itemGeneral['qtdeWin']);
                    const qtdeLoss = parseInt(itemGeneral['qtdeLoss']);
                    const qtdeDraw = parseInt(itemGeneral['qtdeDraw']);

                    if (qtdeWin || qtdeLoss || qtdeDraw) {
                        const percent = (qtdeLoss + qtdeWin + qtdeDraw) ? qtdeWin * 100 / (qtdeLoss + qtdeWin + qtdeDraw) : 0;
                        result.push([itemGeneral.day, percent]);
                    }
                }

                setResultAnalysis(result);
            });
        });
    }, [filter]);

    const updateChart = useCallback(() => {
        setOptions({
            title: {
                text: ''
            },
            subtitle: {
                text: ``
            },
            chart: {
                type: 'areaspline'
            },
            xAxis: {
                crosshair: false
            },
            series: [
                {
                    name: t('performance_text_fundamental_technical_analysis'),
                    data: resultAnalysis,
                    color: '#1cc88a',
                }
            ],
            yAxis: {
                min: 0,
                title: {
                    text: t('performance_graphic_day_title_yaxy')
                }
            },
            tooltip: {
                headerFormat: `<span style="font-size:10px">${t("day")} {point.key}</span><table>`,
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.1f}%</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                series: {
                    fillColor: {
                        linearGradient: [0, 0, 0, 400],
                        stops: [
                            [0, '#1cc88a'],
                            [
                                1,
                                Highcharts.color(Highcharts.getOptions().colors[0])
                                    .setOpacity(0)
                                    .get("rgba"),
                            ],
                        ],
                    },
                },
            },
        });
    }, [resultAnalysis, setOptions, filter]);

    useEffect(updateChart, [resultAnalysis]);

    return (
        <div {...props}>
            <div className="bg-gray-100">
                <div className="row">
                    <div className="col">
                        <Form ref={formRef} onSubmit={handleSubmit} className="p-3">
                            <div className="row">
                                <div className="col-md-12 pull-left d-flex align-items-center ml-1">
                                    <div className="font-weight-bold mb-1"><nobr><i className="fas fa-funnel-dollar"></i> {t('filter')}</nobr></div>
                                </div>
                                <div className="col-md-4 col-xs-12 mt-3">
                                    <InputDate
                                        name="date"
                                        label={t('month')}
                                        inputFormat={'MM/YYYY'}
                                        views={['month', 'year']}
                                        value={new Date()}
                                    />
                                </div>
                                <CustomButton
                                    className="btn btn-primary float-right font-weight-bold text-capitalize mt-3 ml-3 mr-3"
                                    type="submit"
                                    startIcon={<AiOutlineSearch/>}
                                >
                                    {t('filter_btn_submmit')}
                                </CustomButton>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-12 pl-4 ml-1 mb-5">
                    <span className="m-0 font-weight-bold">
                        <i className="far fa-calendar-alt"/> {t('performance_graphic_day_title', {
                        'month': nameMonth[filter.month -1],
                        'year': filter.year,
                    })}
                    </span>
                </div>
            </div>
            {(isData) ?
                <>
                    <div className="col">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                </> :
                <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
            }
        </div>
    );
}
