import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import PTBR from './locales/pt/pt-br.json';
import ENUS from './locales/en/en-us.json';
import ES from "./locales/es/es.json";

const resources = {
    'pt' : PTBR,
    'en' : ENUS,
    'es' : ES,
}

const lang = navigator.language;
let languageUsed = 'pt';

if (lang === 'en' || lang.startsWith('en-')) {
    languageUsed = 'en';
} else if (lang === 'es' || lang.startsWith('es-')) {
    languageUsed = 'es';
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: languageUsed,
        interpolation: {
            escapeValue: false
        }
    })
export default i18n;