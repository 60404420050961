import i18n from '../services/i18n';

const brokerTypes = [
    {key: 'BINOMO', value: 'Binomo'},
    {key: 'BINOLLA', value: 'Binolla'},
    {key: 'BULLEX', value: 'Bullex'},
    {key: 'DERIV', value: 'Deriv'},
    {key: 'DUKASCOPY', value: 'Dukascopy'},
    {key: 'EBINEX', value: 'Ebinex'},
    {key: 'EXNOVA', value: 'Exnova'},
    {key: 'EXPERT_OPTION', value: 'Expert Option'},
    {key: 'GREAT_TRADE', value: 'Great Trade'},
    {key: 'IQ_OPTION', value: 'IQ Option'},
    {key: 'OLIMP_TRADE', value: 'Olymp Trade'},
    {key: 'OPTNARY', value: 'Optinary'},
    {key: 'POCKET_OPTION', value: 'Pocket Option'},
    {key: 'QUOTEX', value: 'Quotex'},
    {key: 'OUTRA', value: i18n.t('other')},
];

export default brokerTypes;