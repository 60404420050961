import React, {useEffect, useState} from "react";
import {getRanking} from "../../../services/Trading";
import CustomButton from "../../Form/Button";
import {Alert} from "@material-ui/core";
import messageTypes from "../../../constants/MessagesTypes";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function CardRankingWin({filter, ...props}) {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const { t } = useTranslation();

    const {isActiveSignature} = useSelector(
        (state) => state.UserReducer
    );

    useEffect( () => {
        setLoading(true);

        getRanking({
            typeWinOrLoss: 'WIN',
            startDate: filter[0].startDate,
            endDate: filter[0].endDate,
            direction: filter[0].direction,
        }).then((data) => {
            if (!data || data === '') {
                return;
            }

            setList(data.data);
            setLoading(false);
        });

        return function cleanup() {
            setList([]);
        };
    }, [filter]);

    return (
        <div {...props}>
            <div className="card shadow mb-4 h-100">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col d-flex flex-column justify-content-center">
                            <span className="m-0 font-weight-bold">
                                <nobr>
                                    <i className="far fa-smile fa-1x"/> {t('report_best_currency_pairs_title')}
                                </nobr>
                            </span>
                        </div>
                        <div className="col">
                            <div className="float-right">
                                <span className="badge badge-success">Top 5</span><br/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pb-0 mb-0">
                    {isActiveSignature ?
                        <div className="row">
                            <div className="col">
                                {loading ?
                                    <CustomButton
                                        className="btn btn-primary col-12 text-capitalize font-weight-bold"
                                        loading={loading}
                                        type="button"
                                    >
                                        {t('loading')}
                                    </CustomButton> :
                                    <>
                                        {list && list.data && list.data.length ?
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item pr-0 pl-0">
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                        <span className="text-xs font-weight-bold float-left text-muted">{t('report_best_currency_pairs_col_1')}</span>
                                                        <span className="text-xs font-weight-bold float-right text-muted">{t('currency_pairs')}</span>
                                                    </div>
                                                </li>
                                                {list.data.map((row, index) => (
                                                    <li className="list-group-item pr-0 pl-0" key={row.id}>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{width: `${row.count * 100 / row.qtdeTotal}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                                        <span className="text-xs font-weight-bold float-left text-white">{row.count}/{row.qtdeTotal}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <span className="text-xs font-weight-bold float-right text-muted"><nobr>{row.currencyPair}</nobr></span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul> :
                                            <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        : <>
                            <div className="alert alert-primary text-center" role="alert">
                                {t('signature_text_acess_all_resources')}
                                <br/>
                                <Link className="btn btn-success btn-sm font-weight-bold mt-4" to="/plans">
                                    {t('signature_btn')}
                                </Link>
                            </div>
                        </>
                    }

                </div>
            </div>
        </div>
    );
}