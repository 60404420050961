import api from '../api';

export function register($user) {
  const data = api.post('/register', $user, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export function login($user) {
  const data = api.post('/login_check', $user);
  return data;
}
