import React, {useEffect} from "react";
import DivDiagonal from "../../../components/DivDiagonal";
import {useSelector} from "react-redux";
import TradingPerformanceMonth from "../../../components/Charts/TradingPerformanceMonth";
import TradingPerfomanceDaily from "../../../components/Charts/TradingPerfomanceDaily";
import TradingPerfomanceAgroupDailyGeneral from "../../../components/Charts/TradingPerfomanceAgroupDailyGeneral";
import {Container} from "./styles";
import {useTranslation} from "react-i18next";

export default function PerformanceReport() {
    const { t } = useTranslation();
    const {isActiveSignature} = useSelector(
        (state) => state.UserReducer
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {isActiveSignature && [
                    'gleiton.egues204@gmail.com',
                    'kleberbarantes@gmail.com',
                    'queila.oliveira01@gmail.com',
                    'teste@teste.com',
                ].includes(localStorage.getItem('userKey')) ? <>
                <DivDiagonal/>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="h3 mb-0 text-gray-400"><i className="fas fa-chart-line"></i> {t('performance_report_title')}</h1>
                        </div>
                    </div>
                </div>
                <Container className="row justify-content-center">
                    <div className="col-lg-12 col-xl-8">
                        <div className="card shadow mb-4">
                            <div className="card-body pt-4 p-4">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="day-tab" data-toggle="tab" href="#day" role="tab"
                                           aria-controls="juros" aria-selected="false">{t('performance_tab_day')}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="month-tab" data-toggle="tab" href="#month" role="tab"
                                           aria-controls="martingale" aria-selected="false">{t('performance_tab_month')}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="general-tab" data-toggle="tab" href="#general" role="tab"
                                           aria-controls="soros" aria-selected="false">{t('performance_tab_general')}</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active pt-4" id="day" role="tabpanel"
                                         aria-labelledby="day-tab">
                                        <TradingPerfomanceDaily/>
                                    </div>
                                    <div className="tab-pane fade pt-4" id="month" role="tabpanel"
                                         aria-labelledby="month-tab">
                                        <TradingPerformanceMonth/>
                                    </div>
                                    <div className="tab-pane fade pt-4" id="general" role="tabpanel"
                                         aria-labelledby="general-tab">
                                        <TradingPerfomanceAgroupDailyGeneral/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </> :
                <>
                    <DivDiagonal/>
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="h3 mb-0 text-gray-400"><i className="fa fa-chart-line" aria-hidden="true"></i> {t('report_performance_title')}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow mb-4 h-100 d-sm-flex">
                        <div className="card-body">
                            <TradingPerfomanceDaily/>
                        </div>
                    </div>
                </>
            }
        </>
    );
}