import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {currencyFormat} from "../../../utils/masks";
import {getReport} from "../../../services/Trading";
import {useTranslation} from "react-i18next";

export default function CardTotalLoss({filter, ...props}) {
    const {coinType, amount} = useSelector((state) => state.BankReducer);
    const [percentQtdeLoss, setPercentQtdeLoss] = useState(null);
    const [, setPercentTotalValueLoss] = useState(null);

    const [totalValueWin, setTotalValueWin] = useState(null);
    const [totalValueLoss, setTotalValueLoss] = useState(null);
    const [qtdeWin, setQtdeWin] = useState(null);
    const [qtdeLoss, setQtdeLoss] = useState(null);
    const [qtdeDraw, setQtdeDraw] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const value = (qtdeLoss + qtdeWin + qtdeDraw) ? qtdeLoss * 100 / (qtdeLoss + qtdeWin + qtdeDraw) : 0;
        setPercentQtdeLoss(value.toFixed(2));
    }, [qtdeWin, qtdeLoss, qtdeDraw]);

    useEffect(() => {
        const value = (amount && totalValueLoss) ? totalValueLoss * 100 / ((amount - totalValueWin) - totalValueLoss) : 0;
        setPercentTotalValueLoss(value.toFixed(2));
    }, [totalValueLoss, totalValueWin, amount]);

    useEffect(() => {
        getReport({
            startDate: filter[0].startDate,
            endDate: filter[0].endDate,
            orderBy: 'profitObtained',
            order: 'DESC'
        }).then((data) => {
            if (!data || data === '' || !data.data) {
                return;
            }

            setTotalValueWin(parseFloat(data.data.win.totalValue ? data.data.win.totalValue : 0));
            setTotalValueLoss(parseFloat(data.data.loss.totalValue ? data.data.loss.totalValue : 0));

            setQtdeWin(data.data.win.qtde);
            setQtdeLoss(data.data.loss.qtde);
            setQtdeDraw(data.data.draw.qtde);
        });
    }, [filter]);

    return (
        <div {...props} style={{backgroundColor: 'rgba(192,192,192,0.3)'}}>
            <div className="card-body pt-0 pb-0">
                <div className="row no-gutters align-items-center">
                    <div className="col">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item pr-0 pl-0" style={{backgroundColor: 'rgba(192,192,192,0)'}}>
                                <div className="text-xs font-weight-bold mb-1">{t('traging_report_card_loss_defeats')}</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                    <span>{qtdeLoss}</span>
                                    <span className="float-right badge badge-danger">{percentQtdeLoss}%</span>
                                </div>
                            </li>
                            <li className="list-group-item pr-0 pl-0" style={{backgroundColor: 'rgba(192,192,192,0)'}}>
                                <div className="text-xs font-weight-bold mb-1">{t('traging_report_card_loss_value_defeats')}</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                    <span>{coinType} {currencyFormat(parseFloat(totalValueLoss))}</span>
                                    {/*<span className="float-right badge badge-danger">{percentTotalValueLoss}%</span>*/}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
