import React from "react";
import {Container} from "./styles";

function DivDiagonal({ children, isDiagonal}) {
    return (
        <Container isDiagonal={isDiagonal} className="p-0">
            { children }
        </Container>
    );
}

export default DivDiagonal;