import i18n from '../services/i18n';

const currencyPairTypes = [
    {key: 'AUD/CAD', value: 'AUD/CAD'},
    {key: 'AUD/CHF', value: 'AUD/CHF'},
    {key: 'AUD/JPY', value: 'AUD/JPY'},
    {key: 'AUD/NZD', value: 'AUD/NZD'},
    {key: 'AUD/USD', value: 'AUD/USD'},
    {key: 'APPLE', value: 'Apple'},
    {key: 'BITCOIN', value: 'Bitcoin'},
    {key: 'CAD/CHF', value: 'CAD/CHF'},
    {key: 'CAD/JPY', value: 'CAD/JPY'},
    {key: 'CHF/JPY', value: 'CHF/JPY'},
    {key: 'CHF/NOK', value: 'CHF/NOK'},
    {key: 'CRUDE_OIL_WTI', value: 'Crude Oil WTI'},
    {key: 'DASH', value: 'Dash'},
    {key: 'EOS', value: 'EOS'},
    {key: 'ETHEREUM', value: 'Ethereum'},
    {key: 'EUR/AUD', value: 'EUR/AUD'},
    {key: 'EUR/CAD', value: 'EUR/CAD'},
    {key: 'EUR/CHF', value: 'EUR/CHF'},
    {key: 'EUR/GBP', value: 'EUR/GBP'},
    {key: 'EUR/JPY', value: 'EUR/JPY'},
    {key: 'EUR/NZD', value: 'EUR/NZD'},
    {key: 'EUR/RUB', value: 'EUR/RUB'},
    {key: 'EUR/RUF', value: 'EUR/RUF'},
    {key: 'EUR/TRY', value: 'EUR/TRY'},
    {key: 'EUR/USD', value: 'EUR/USD'},
    {key: 'GBP/AUD', value: 'GBP/AUD'},
    {key: 'GBP/CAD', value: 'GBP/CAD'},
    {key: 'GBP/CHF', value: 'GBP/CHF'},
    {key: 'GBP/JPY', value: 'GBP/JPY'},
    {key: 'GBP/NZD', value: 'GBP/NZD'},
    {key: 'GBP/USD', value: 'GBP/USD'},
    {key: 'LITECOIN', value: 'Litecoin'},
    {key: 'NZD/CAD', value: 'NZD/CAD'},
    {key: 'NZD/CHF', value: 'NZD/CHF'},
    {key: 'NZD/JPY', value: 'NZD/JPY'},
    {key: 'NZD/USD', value: 'NZD/USD'},
    {key: 'OURO', value: 'Ouro'},
    {key: 'RIPPLE', value: 'Ripple'},
    {key: 'USD/CAD', value: 'USD/CAD'},
    {key: 'USD/CHF', value: 'USD/CHF'},
    {key: 'USD/CPL', value: 'USD/CPL'},
    {key: 'USD/CNH', value: 'USD/CNH'},
    {key: 'USD_CURRENCY', value: 'USD Currency'},
    {key: 'USD/JPY', value: 'USD/JPY'},
    {key: 'USD/MXN', value: 'USD/MXN'},
    {key: 'USD/NOK', value: 'USD/NOK'},
    {key: 'USD/SGD', value: 'USD/SGD'},
    {key: 'USD/TRY', value: 'USD/TRY'},
    {key: 'American Express (OTC)', value: 'American Express (OTC)'},
    {key: 'AED/CNY (OTC)', value: 'AED/CNY (OTC)'},
    {key: 'AUD/CAD (OTC)', value: 'AUD/CAD (OTC)'},
    {key: 'AUD/CHF (OTC)', value: 'AUD/CHF (OTC)'},
    {key: 'AUD/JPY (OTC)', value: 'AUD/JPY (OTC)'},
    {key: 'AUD/NZD (OTC)', value: 'AUD/NZD (OTC)'},
    {key: 'AUD/USD (OTC)', value: 'AUD/USD (OTC)'},
    {key: 'BHD/CNY (OTC)', value: 'BHD/CNY (OTC)'},
    {key: 'BITCOIN (OTC)', value: 'Bitcoin (OTC)'},
    {key: 'Boeing Company (OTC)', value: 'Boeing Company (OTC)'},
    {key: 'CAD/CHF (OTC)', value: 'CAD/CHF (OTC)'},
    {key: 'CAD/JPY (OTC)', value: 'CAD/JPY (OTC)'},
    {key: 'CHF/JPY (OTC)', value: 'CHF/JPY (OTC)'},
    {key: 'CHF/NOK (OTC)', value: 'CHF/NOK (OTC)'},
    {key: 'EUR/AUD (OTC)', value: 'EUR/AUD (OTC)'},
    {key: 'EUR/CAD (OTC)', value: 'EUR/CAD (OTC)'},
    {key: 'EUR/CHF (OTC)', value: 'EUR/CHF (OTC)'},
    {key: 'EUR/GBP (OTC)', value: 'EUR/GBP (OTC)'},
    {key: 'EUR/JPY (OTC)', value: 'EUR/JPY (OTC)'},
    {key: 'EUR/NZD (OTC)', value: 'EUR/NZD (OTC)'},
    {key: 'EUR/RUB (OTC)', value: 'EUR/RUB (OTC)'},
    {key: 'EUR/RUF (OTC)', value: 'EUR/RUF (OTC)'},
    {key: 'EUR/HUF (OTC)', value: 'EUR/HUF (OTC)'},
    {key: 'EUR/TRY (OTC)', value: 'EUR/TRY (OTC)'},
    {key: 'EUR/USD (OTC)', value: 'EUR/USD (OTC)'},
    {key: 'FACEBOOK INC (OTC)', value: 'FACEBOOK INC (OTC)'},
    {key: 'GBP/AUD (OTC)', value: 'GBP/AUD (OTC)'},
    {key: 'GBP/CAD (OTC)', value: 'GBP/CAD (OTC)'},
    {key: 'GBP/CHF (OTC)', value: 'GBP/CHF (OTC)'},
    {key: 'GBP/JPY (OTC)', value: 'GBP/JPY (OTC)'},
    {key: 'GBP/NZD (OTC)', value: 'GBP/NZD (OTC)'},
    {key: 'GBP/USD (OTC)', value: 'GBP/USD (OTC)'},
    {key: 'GOLD (OTC)', value: 'Gold (OTC)'},
    {key: 'Intel (OTC)', value: 'Intel (OTC)'},
    {key: 'Johnson & Johnson (OTC)', value: 'Johnson & Johnson (OTC)'},
    {key: 'JPY_CURRENCY_INDEX', value: 'JPY Currency Index'},
    {key: "McDonald's (OTC)", value: "McDonald's (OTC)"},
    {key: "Microsoft (OTC)", value: "Microsoft (OTC)"},
    {key: 'NZD/CAD (OTC)', value: 'NZD/CAD (OTC)'},
    {key: 'NZD/CHF (OTC)', value: 'NZD/CHF (OTC)'},
    {key: 'NZD/JPY (OTC)', value: 'NZD/JPY (OTC)'},
    {key: 'NZD/USD (OTC)', value: 'NZD/USD (OTC)'},
    {key: 'Pfizer Inc (OTC)', value: 'Pfizer Inc (OTC)'},
    {key: 'UDCRUDE (OTC)', value: 'USCrude (OTC)'},
    {key: 'UKBrent (OTC)', value: 'UKBrent (OTC)'},
    {key: 'USD/ARS (OTC)', value: 'USD/ARS (OTC)'},
    {key: 'USD/BRL (OTC)', value: 'USD/BRL (OTC)'},
    {key: 'USD/BDT (OTC)', value: 'USD/BDT (OTC)'},
    {key: 'USD/CAD (OTC)', value: 'USD/CAD (OTC)'},
    {key: 'USD/COP (OTC)', value: 'USD/COP (OTC)'},
    {key: 'USD/CPL (OTC)', value: 'USD/CPL (OTC)'},
    {key: 'USD/CHF (OTC)', value: 'USD/CHF (OTC)'},
    {key: 'USD/CHN (OTC)', value: 'USD/CHN (OTC)'},
    {key: 'USD/DZD (OTC)', value: 'USD/DZD (OTC)'},
    {key: 'USD/EGP (OTC)', value: 'USD/EGP (OTC)'},
    {key: 'USD/HKD (OTC)', value: 'USD/HKD (OTC)'},
    {key: 'USD/IDR (OTC)', value: 'USD/IDR (OTC)'},
    {key: 'USD/INR (OTC)', value: 'USD/INR (OTC)'},
    {key: 'USD/JPY (OTC)', value: 'USD/JPY (OTC)'},
    {key: 'USD/MXN (OTC)', value: 'USD/MXN (OTC)'},
    {key: 'USD/NGN (OTC)', value: 'USD/NGN (OTC)'},
    {key: 'USD/NOK (OTC)', value: 'USD/NOK (OTC)'},
    {key: 'USD/PHP (OTC)', value: 'USD/PHP (OTC)'},
    {key: 'USD/PKR (OTC)', value: 'USD/PKR (OTC)'},
    {key: 'USD/RUB (OTC)', value: 'USD/RUB (OTC)'},
    {key: 'USD/SGD (OTC)', value: 'USD/SGD (OTC)'},
    {key: 'USD/TRY (OTC)', value: 'USD/TRY (OTC)'},
    {key: 'USD/ZAR (OTC)', value: 'USD/ZAR (OTC)'},
    {key: 'SILVER (OTC)', value: 'Silver (OTC)'},
    {key: 'VISA (OTC)', value: 'VISA (OTC)'},
    {key: 'OTC', value: 'OTC'},
    {key: 'TORNEIO', value: i18n.t('text_tournament')},
    {key: 'OUTRO', value: i18n.t('other')},
];

export default currencyPairTypes;