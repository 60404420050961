import React, {useEffect, useState} from "react";
import {BsWhatsapp} from "react-icons/bs";
import {useTranslation} from "react-i18next";

export default function Copyright() {
    const [year, setYear] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const d = new Date();
        setYear(d.getFullYear());
    }, []);


    return (
        <>
            {year && <div className="col-12 d-flex flex-column align-items-center small text-center">
                <div>{t('copyright_phone')}
                    <a className="text-muted pl-1" href="https://web.whatsapp.com/send?phone=38999361310" target="_blank">
                        <BsWhatsapp color={'#25D366'}/> <u>(38) 99936-1310</u>
                    </a>
                </div>
                <div>{t('copyright_email')} app.gbinary@gmail.com</div>
                <div>Copyright © { year } | CNPJ: 33.492.063/0001-61 <br/>{t('rights_reserved')}</div>
            </div>}
        </>
    );
}