import React from "react";
import {useSelector} from "react-redux";
import {currencyFormat} from "../../../../utils/masks";
import TooltipDefault from "../../../TooltipDefault";
import {useTranslation} from "react-i18next";

export default function CardManagementFailure({data}) {
    const {coinType} = useSelector((state) => state.BankReducer);
    const { t } = useTranslation();

    return (
        <>
            <div className="row">
                <div className="col">
                    <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-center text-danger border-secondary  font-weight-bolder">
                            <div>
                                {t('discipline_management_not_respected')}
                            </div>
                            <div className={"float-right"}>
                                <TooltipDefault
                                    className={"ml-2 cursor-pointer"}
                                    title={t('discipline_management_not_respected_info')}
                                >
                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                </TooltipDefault>
                            </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {t('discipline_management_number_times')}
                            <span>{data.qtdeDays ? data.qtdeDays : 0}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {t('discipline_management_draw_operations')}
                            <span>{ data.qtdeDraw ? data.qtdeDraw : 0 }</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {t('discipline_management_win_operations')}
                            <span>{ data.qtdeWin ? data.qtdeWin : 0 }</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {t('discipline_management_loss_operations')}
                            <span>{ data.qtdeLoss ? data.qtdeLoss : 0 }</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {t('discipline_management_assertiveness')}
                            <span>
                                {parseFloat((data.qtdeLoss + data.qtdeWin + data.qtdeDraw) ? data.qtdeWin * 100 / (data.qtdeLoss + data.qtdeWin + data.qtdeDraw) : 0).toFixed(2)}%
                            </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {t('discipline_management_profit_prejudice')}
                            <span>{coinType} {currencyFormat(data.profitObtained ? data.profitObtained : 0)} </span>
                        </li>
                    </ul>

                </div>
            </div>
        </>
    );
}