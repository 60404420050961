import api from '../api';
import {toast} from "react-toastify";
import messageTypes from "../../constants/MessagesTypes";

export async function getMeta() {
    try {

        const response = await api.get(`/api/meta/view`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                auth: true
            },
        });

        return response.data.data ? response.data.data : {};

    } catch (err) {
        toast.error(messageTypes.ERROR_LOAD);
        throw err;
    }
}

export function edit($id, $meta) {
    return api.post(`/api/meta/edit/${$id}`, $meta, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export function save($meta) {
    return api.post('/api/meta/new', $meta, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}