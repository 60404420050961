import React, {useEffect, useState} from "react";
import CustomButton from "../../Form/Button";
import {Alert} from "@material-ui/core";
import messageTypes from "../../../constants/MessagesTypes";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getReport} from "../../../services/Strategy";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {currencyFormat} from "../../../utils/masks";
import {useTranslation} from "react-i18next";

export default function StrategyReport({filter, ...props}) {
    const {isActiveSignature} = useSelector(
        (state) => state.UserReducer
    );
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const {coinType} = useSelector((state) => state.BankReducer);
    const { t } = useTranslation();

    useEffect(() => {

        setLoading(true);
        getReport({
            startDate: filter[0].startDate,
            endDate: filter[0].endDate,
            direction: filter[0].direction,
        }).then((data) => {
            if (!data || data === '' || !data.data) {
                return;
            }

            setList(data.data.data);
            setLoading(false);
        });

        return function cleanup() {
            setList([]);
        };
    }, [filter]);

    return (
        <div {...props}>
            {isActiveSignature ?
                <div className="col-md-12 p-0">
                    {loading ?
                        <CustomButton
                            className="btn btn-primary col-12 text-capitalize font-weight-bold"
                            loading={loading}
                            type="button"
                        >
                            {t('loading')}
                        </CustomButton> :
                        <>
                            {list && list.length ?
                                <TableContainer component={Paper}>
                                    <Table aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    className="font-weight-bold text-muted"
                                                >
                                                </TableCell>
                                                <TableCell
                                                    className="font-weight-bold text-muted"
                                                >
                                                    <nobr>{t('operations')}</nobr>
                                                </TableCell>
                                                <TableCell
                                                    className="font-weight-bold text-muted"
                                                >
                                                    <nobr>{t('assertiveness')}</nobr>
                                                </TableCell>
                                                <TableCell
                                                    className="font-weight-bold text-muted"
                                                >
                                                    <nobr>{t('profit_prejudice')}</nobr>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {list.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{row.nameStrategy}</TableCell>
                                                    <TableCell>
                                                        {row.count} {t('appetizer')}<br/>
                                                        <span className="text-muted text-xs">{row.qtdeWin} {t('victories')} / </span>
                                                        <span className="text-muted text-xs">{row.qtdeLoss} {t('defeats')} / </span>
                                                        <span className="text-muted text-xs">{row.qtdeDraw} {t('draws')}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        {parseInt(row.qtdeLoss) + parseInt(row.qtdeWin) + parseInt(row.qtdeDraw) ?
                                                            parseFloat((parseInt(row.qtdeLoss) + parseInt(row.qtdeWin) + parseInt(row.qtdeDraw)) ? row.qtdeWin * 100 / (parseInt(row.qtdeLoss) + parseInt(row.qtdeWin) + parseInt(row.qtdeDraw)) : 0).toFixed(2) + '%'
                                                            : ''
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {parseFloat(row.profitObtained) > 0 ? <span className="text-success">{coinType} {currencyFormat(parseFloat(row.profitObtained))}</span> : ''}
                                                        {parseFloat(row.profitObtained) === 0 ? <span>{coinType} {currencyFormat(parseFloat(row.profitObtained))}</span> : ''}
                                                        {parseFloat(row.profitObtained) < 0 ? <span className="text-danger">{coinType} {currencyFormat(parseFloat(row.profitObtained))}</span> : ''}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer> :
                                <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                            }
                        </>
                    }
                </div>
                : <>
                    <div className="alert alert-primary text-center" role="alert">
                        {t('signature_text_acess_all_resources')}
                        <br/>
                        <Link className="btn btn-success btn-sm font-weight-bold mt-4" to="/plans">
                            {t('signature_btn')}
                        </Link>
                    </div>
                </>
            }
        </div>
    );
}