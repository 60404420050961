import * as Yup from "yup";
import {toast} from "react-toastify";
import React, {useEffect, useRef, useState} from "react";
import messageTypes from "../../../constants/MessagesTypes";
import {Form} from "@unform/web";
import CustomButton from "../../../components/Form/Button";
import {Link, useHistory} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import {IoMdSave} from "react-icons/io";
import Fieldset from "../../../components/Fieldset";
import CkeckboxInput from "../../../components/Form/Checkbox";
import {edit, getDisciplina, save} from "../../../services/Disciplina";
import Input from "../../../components/Form/Input";
import InputDate from "../../../components/Form/InputDate";
import {useTranslation} from "react-i18next";

export default function NewDisciplina({match}) {
    const [loading, setLoading] = useState(false);
    const [disciplina, setDisciplina] = useState(0);
    const [isManagementFollowed, setManagementFollowed] = useState(null);
    const formRef = useRef(null);
    const history = useHistory();
    const { t } = useTranslation();

    async function handleSubmmit(data) {
        try {
            if (isManagementFollowed === null) {
                toast.warning(t('discipline_alert_is_management_followed'));
                return;
            }

            if (loading) {
                return;
            }

            setLoading(true);
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                created: Yup.string().required(t('input_required')),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const formData = new FormData();
            if (data.errorDescription) {
                formData.append('errorDescription', data.errorDescription);
            }

            formData.append('created', data.created);
            formData.append('isManagementFollowed', isManagementFollowed);
            const response = (disciplina) ? await edit(disciplina.id, formData) : await save(formData);

            if (response && response.status === 200) {
                toast.success(messageTypes.SUCCESS);
                history.push('/disciplina');
            } else {
                toast.error(messageTypes.ERROR_SAVE);
            }
            setLoading(false);

        } catch (err) {
            setLoading(false);
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    useEffect(() => {

        const id = match.params.id;
        if (id) {
            setLoading(true);
            getDisciplina(id).then((data) => {
                setDisciplina(data);
            }).finally(() => setLoading(false));
        }

    }, []);

    return (
        <>
            {!loading && <div className="row justify-content-center">
                <div className="col-lg-5">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold">
                                {disciplina ?
                                    <>
                                        <i className="fas fa-edit"/> {t('discipline_card_edit_title')}
                                    </> :
                                    <>
                                        <i className="fas fa-plus"/> {t('discipline_card_new_title')}
                                    </>
                                }
                            </h6>
                        </div>
                        <div className="card-body pt-4">
                            <Form ref={formRef} onSubmit={handleSubmmit}>
                                <div className="row mb-4">
                                    <div className="col">
                                        <InputDate
                                            name="created"
                                            label={t('discipline_label_input_data') + ' *'}
                                            value={(disciplina) ? new Date(disciplina.created.date) : new Date()}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col">
                                        <Fieldset textLegend={t('discipline_fieldset_title') + ' *'} className="scheduler-border">
                                            <div className="form-check">
                                                <CkeckboxInput
                                                    id="isManagementFollowed"
                                                    name="isManagementFollowed"
                                                    setValue={setManagementFollowed}
                                                    value={disciplina && disciplina.isManagementFollowed !== null ?
                                                        disciplina.isManagementFollowed == true ? 'true' : 'false'
                                                        : null
                                                    }
                                                    options={[
                                                        {name: 'yes', value: 'true', label: t('discipline_fieldset_option_1')},
                                                        {name: 'not', value: 'false', label: t('discipline_fieldset_option_2')},
                                                    ]}
                                                />
                                            </div>
                                        </Fieldset>
                                    </div>
                                </div>
                                {isManagementFollowed === 'false' && <>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <Input
                                                type="text"
                                                name="errorDescription"
                                                defaultValue={disciplina.errorDescription}
                                                label={t('discipline_label_input_management_followed')}
                                                placeholder={t('discipline_label_input_management_followed_placeholder')}
                                                multiline
                                                rows={4}
                                            />
                                        </div>
                                    </div>
                                </>}
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/disciplina">
                                            <CustomButton
                                                variant="outlined"
                                                className="btn btn-secondary col-4 float-left font-weight-bold text-capitalize"
                                                startIcon={<BiArrowBack/>}
                                            >
                                                {t('btn_back')}
                                            </CustomButton>
                                        </Link>
                                        <CustomButton
                                            className="btn btn-primary col-4 float-right font-weight-bold text-capitalize"
                                            loading={loading}
                                            type="submit"
                                            startIcon={<IoMdSave/>}
                                        >
                                            {t('btn_save')}
                                        </CustomButton>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}