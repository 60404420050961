import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import DefaultLayout from '../pages/_layouts/default';
import LayoutBase from "../pages/Layout";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const signed = localStorage.getItem('@user_token');

  if (signed && (window.location.pathname === '/login' || window.location.pathname === '/register')) {
    return <Redirect to="/" />;
  }

  const Layout = signed && window.location.pathname !== '/' ? LayoutBase : DefaultLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.defaultProps = {
  isPrivate: false,
};

/*
RouteWrapper.propTypes = {
  component: PropTypes.element.isRequired,
  isPrivate: PropTypes.bool,
};
* */
