import React from 'react';
import PropTypes from 'prop-types';

import {Button} from "@material-ui/core";
import {useTranslation} from "react-i18next";

function CustomButton({ loading, children, ...props }) {
  const { t } = useTranslation();
  return (
    <Button variant="contained" {...props} disabled={loading}>
      {loading ? t('loading') : children}
    </Button>
  );
}

export default CustomButton;

CustomButton.defaultProps = {
  loading: false,
};

CustomButton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.string.isRequired,
};
