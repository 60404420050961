import React, {useEffect, useState} from "react";
import CustomButton from "../../Form/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {currencyFormat} from "../../../utils/masks";
import {Alert} from "@material-ui/core";
import {RiArrowUpDownFill} from "react-icons/ri";
import messageTypes from "../../../constants/MessagesTypes";
import {getReport} from "../../../services/Trading";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export default function ReportTrading({filter}) {
    const [loading, setLoading] = useState(false);
    const [orderBy, setOrderBy] = useState('profitObtained');
    const [order, setOrder] = useState('DESC');
    const [list, setList] = useState([]);
    const {coinType} = useSelector((state) => state.BankReducer);
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        getReport({
            startDate: filter[0].startDate,
            endDate: filter[0].endDate,
            orderBy: orderBy,
            order: order
        }).then((data) => {
            if (!data || data === '' || !data.data) {
                return;
            }

            setList(data.data.tradings);
            setLoading(false);
        });

        return function cleanup() {
            setList([]);
        };
    }, [orderBy, order, filter]);

    return (
        <>
            <div className="col-md-12 p-0">
                {loading ?
                    <CustomButton
                        className="btn btn-primary col-12 text-capitalize font-weight-bold"
                        loading={loading}
                        type="button"
                    >
                        {t('loading')}
                    </CustomButton> :
                    <>
                        {list && list.length ?
                            <TableContainer component={Paper}>
                                <Table aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                className="font-weight-bold text-muted"
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                    setOrderBy('currencyPair');
                                                    setOrder(order === 'DESC' ? 'ASC' : 'DESC')
                                                }}
                                            >
                                                <nobr>{t('traging_report_table_col_currency_pair')} <RiArrowUpDownFill/></nobr>
                                            </TableCell>
                                            <TableCell
                                                style={{cursor: 'pointer'}}
                                                className="font-weight-bold text-muted"
                                                onClick={() => {
                                                    setOrderBy('count');
                                                    setOrder(order === 'DESC' ? 'ASC' : 'DESC')
                                                }}
                                            >
                                                <nobr>{t('traging_report_table_col_operations')} <RiArrowUpDownFill/></nobr>
                                            </TableCell>
                                            <TableCell
                                                style={{cursor: 'pointer'}}
                                                className="font-weight-bold text-muted"
                                                onClick={() => {
                                                    setOrderBy('investmentValue');
                                                    setOrder(order === 'DESC' ? 'ASC' : 'DESC')
                                                }}
                                            >
                                                <nobr>{t('traging_report_table_col_total')} <RiArrowUpDownFill/></nobr>
                                            </TableCell>
                                            <TableCell
                                                className="font-weight-bold text-muted"
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                    setOrderBy('profitObtained');
                                                    setOrder(order === 'DESC' ? 'ASC' : 'DESC')
                                                }}
                                            >
                                                <nobr>{t('traging_report_table_col_profit_prejudice')} <RiArrowUpDownFill/></nobr>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{row.currencyPair}</TableCell>
                                                <TableCell>
                                                    {row.count} entradas<br/>
                                                    <span className="text-muted text-xs">{row.qtdeWin} {t('victories')} / </span>
                                                    <span className="text-muted text-xs">{row.qtdeDraw} {t('draws')} / </span>
                                                    <span className="text-muted text-xs">{row.qtdeLoss} {t('defeats')}</span>
                                                </TableCell>
                                                <TableCell>{coinType} {currencyFormat(parseFloat(row.investmentValue))}</TableCell>
                                                <TableCell>
                                                    {parseFloat(row.profitObtained) > 0 ? <span className="text-success">{coinType} {currencyFormat(parseFloat(row.profitObtained))}</span> : ''}
                                                    {parseFloat(row.profitObtained) === 0 ? <span>{coinType} {currencyFormat(parseFloat(row.profitObtained))}</span> : ''}
                                                    {parseFloat(row.profitObtained) < 0 ? <span className="text-danger">{coinType} {currencyFormat(parseFloat(row.profitObtained))}</span> : ''}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> :
                            <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                        }
                    </>
                }
            </div>
        </>
    );
}
