import React, {useState} from 'react';
import InputSelect from "../../../components/Form/InputSelect";
import {Form} from "@unform/web";
import {Link} from "react-router-dom";
import CustomButton from "../../../components/Form/Button";
import {BiArrowBack} from "react-icons/bi";
import {toast} from "react-toastify";
import messageTypes from "../../../constants/MessagesTypes";
import QuotexCSV from "../../../components/QuotexCSV";
import IqOptionCSV from "../../../components/IqOptionCSV";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export default function ImportTrading() {
    const [fileCSV, setFileCSV] = useState(null);
    const [broker, setBroker] = useState(null);
    const [isSubmit, setSubmit] = useState(false);

    const {isActiveSignature} = useSelector(
        (state) => state.UserReducer
    );

    const { t } = useTranslation();

    async function handleSubmit() {
        if (!fileCSV || !broker) {
            toast.warning(messageTypes.REQUIRED_FIELDS);
            return;
        }

        setSubmit(true);
    }

    return (
        <>
            {isActiveSignature && <>
                {!isSubmit && <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-8 col-sm-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold">
                                    CSV - {t('traging_import_card_title')}
                                </h6>
                            </div>
                            <div className="card-body pt-4">
                                <div className="row mb-3">
                                    <div className="col">
                                        <Form onSubmit={handleSubmit}>
                                            <div className="row mb-4">
                                                <div className="col">
                                                    <input
                                                        accept=".csv"
                                                        name="fileCSV"
                                                        onChange={(e) => {
                                                            setFileCSV(e.target.files[0]);
                                                        }}
                                                        type="file"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <InputSelect
                                                        type="file"
                                                        name="broker"
                                                        onChange={(e) => {
                                                            setBroker(e.target.value);
                                                        }}
                                                        label={t('traging_import_card_title') + ' *'}
                                                        options={[
                                                            {key: 'EXNOVA', value: 'Exnova'},
                                                            {key: 'IQ_OPTION', value: 'IQ Option'},
                                                            {key: 'QUOTEX', value: 'Quotex'},
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="row">
                                                <div className="col-12">
                                                    <Link to="/trading">
                                                        <CustomButton
                                                            variant="outlined"
                                                            className="btn btn-secondary col-4 float-left font-weight-bold text-capitalize"
                                                            startIcon={<BiArrowBack/>}
                                                        >
                                                            {t('btn_back')}
                                                        </CustomButton>
                                                    </Link>
                                                    <CustomButton
                                                        className="btn btn-primary col-4 float-right font-weight-bold text-capitalize"
                                                        type="submit"
                                                    >
                                                        {t('traging_import_btn_next')}
                                                    </CustomButton>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {broker === 'QUOTEX' && isSubmit && <QuotexCSV fileCSV={fileCSV}/>}
                {['IQ_OPTION', 'EXNOVA'].includes(broker) && isSubmit && <IqOptionCSV fileCSV={fileCSV}/>}
            </>}
        </>
    );
}