import styled from 'styled-components';

export const Container = styled.div`
    .tooltip-resources-info {
      cursor: pointer;
      position: absolute;
      margin-top: 4px;
    }
  
  .card-plan {
    border: 3px solid rgba(0, 0, 0, 0.07);
    border-radius: 20px;
  }
`;
