import React, {useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Form} from "@unform/web";
import InputMask from "../../../components/Form/InputMask";
import {InputAdornment} from "@material-ui/core";
import {AiOutlineCheck} from "react-icons/ai";
import {BiArrowBack} from "react-icons/bi";
import CustomButton from "../../../components/Form/Button";
import * as Yup from "yup";
import {toast} from "react-toastify";
import messageTypes from "../../../constants/MessagesTypes";
import AlertConfirm from "../../../components/Alert/AlertConfirm";
import {currencyFormat} from "../../../utils/masks";
import {getBank} from "../../../services/Bank";
import {actions} from "../../../redux/actions";
import InputDate from "../../../components/Form/InputDate";
import {useTranslation} from "react-i18next";

export default function Deposit() {
    const { coinType} = useSelector((state) => state.BankReducer);
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const [value, setValue] = useState(null);
    const [created, setCreated] = useState(null);
    const { t } = useTranslation();

    async function handleDeposit(data) {
        try {
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                value: Yup.string().required(t('input_required')),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            setValue(data.value.replace(/[^0-9,-]*/g, '').replace(',', '.'));
            setCreated(data.created);

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-8 col-md-10 col-sm-10 col-xl-6">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col d-flex flex-column justify-content-center">
                                    <span className="m-0 font-weight-bold">
                                        <i className="fas fa-plus"></i> {t('deposit_card_title')}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-4">
                            <Form ref={formRef} onSubmit={handleDeposit}>
                                <div className="row mb-4 justify-content-center">
                                    <div className="col-lg-6 mb-4">
                                        <InputDate
                                            name="created"
                                            label={t('deposit_label_input_date') + ' *'}
                                            value={new Date()}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <InputMask
                                            type="text"
                                            name="value"
                                            label={t('deposit_label_input_date') + ' *'}
                                            mask={'currency'}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">{coinType}</InputAdornment>,
                                            }}
                                            autoFocus
                                        />
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/bank/movements">
                                            <CustomButton
                                                variant="outlined"
                                                className="btn btn-secondary col-4 float-left font-weight-bold text-capitalize"
                                                startIcon={<BiArrowBack/>}
                                            >
                                                {t('btn_back')}
                                            </CustomButton>
                                        </Link>
                                        <CustomButton
                                            className="btn btn-success col-4 float-right font-weight-bold text-capitalize"
                                            type="submit"
                                            startIcon={<AiOutlineCheck style={{paddingLeft: '6px'}}/>}
                                        >
                                            {t('btn_conclude')}
                                        </CustomButton>
                                        {value && value !== true && created && <AlertConfirm
                                            functionCallbackCancel={() => {
                                                setValue(null);
                                            }}
                                            urlCallback='/bank/movements'
                                            functionCallback={() => {
                                                getBank().then((data) => {
                                                    dispatch(actions.updateBank(data));
                                                })
                                            }}
                                            text={t('deposit_msg_confirm', {
                                                'value': `${coinType} ${currencyFormat(value)}`
                                            })}
                                            urlAction={process.env.REACT_APP_API_ENDPOINT+`/api/bank/deposit/${value}?created=${created}`}
                                        />}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}