import * as Yup from "yup";
import {toast} from "react-toastify";
import React, {useEffect, useRef, useState} from "react";
import messageTypes from "../../../constants/MessagesTypes";
import {Form} from "@unform/web";
import CustomButton from "../../../components/Form/Button";
import {Link, useHistory} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import {IoMdSave} from "react-icons/io";
import Input from "../../../components/Form/Input";
import {edit, getEvent, save} from "../../../services/Event";
import {useTranslation} from "react-i18next";

export default function NewEvent({match}) {
    const [loading, setLoading] = useState(false);
    const [event, setEvent] = useState(0);
    const formRef = useRef(null);
    const history = useHistory();
    const { t } = useTranslation();

    async function handleSubmmit(data) {
        try {
            if (loading) {
                return;
            }

            setLoading(true);
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                name: Yup.string().required(t('input_required')),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const formData = new FormData();
            formData.append('name', data.name);
            const response = (event) ? await edit(event.id, formData) : await save(formData);

            if (response && response.status === 200) {
                toast.success(messageTypes.SUCCESS);
                history.push('/economic-calendar');
            } else {
                toast.error(messageTypes.ERROR_SAVE);
            }
            setLoading(false);

        } catch (err) {
            setLoading(false);
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    useEffect(() => {

        const id = match.params.id;
        if (id) {
            setLoading(true);
            getEvent(id).then((data) => {
                setEvent(data);
            }).finally(() => setLoading(false));
        }

    }, []);

    return (
        <>
            {!loading && <div className="row justify-content-center">
                <div className="col-lg-5">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold">
                                {event ?
                                    <>
                                        <i className="fas fa-edit"/> {t('event_edit')}
                                    </> :
                                    <>
                                        <i className="fas fa-plus"/> {t('event_add')}
                                    </>
                                }
                            </h6>
                        </div>
                        <div className="card-body pt-4">
                            <Form ref={formRef} onSubmit={handleSubmmit}>
                                <div className="row mb-3">
                                    <div className="col">
                                        <Input
                                            type="text"
                                            name="name"
                                            defaultValue={event.name}
                                            label={t('event_input_label_name')}
                                            placeholder={t('event_input_label_name')}
                                        />
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/economic-calendar">
                                            <CustomButton
                                                variant="outlined"
                                                className="btn btn-secondary col-4 float-left font-weight-bold text-capitalize"
                                                startIcon={<BiArrowBack/>}
                                            >
                                                {t('btn_back')}
                                            </CustomButton>
                                        </Link>
                                        <CustomButton
                                            className="btn btn-primary col-4 float-right font-weight-bold text-capitalize"
                                            loading={loading}
                                            type="submit"
                                            startIcon={<IoMdSave/>}
                                        >
                                            {t('btn_save')}
                                        </CustomButton>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}