import React, {useCallback, useEffect, useRef, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {MdDeleteForever, MdEdit, MdImage,} from "react-icons/md";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {WiDirectionDown, WiDirectionUp} from "react-icons/wi";
import {AiOutlineSearch} from "react-icons/ai";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {currencyFormat} from "../../utils/masks";
import {loadList} from "../../services/Trading";
import CustomButton from "../../components/Form/Button";
import {useDispatch, useSelector} from "react-redux";
import {Alert, IconButton} from "@material-ui/core";
import AlertDelete from "../../components/Alert/AlertDelete";
import {actions} from "../../redux/actions";
import {getBank} from "../../services/Bank";
import messageTypes from "../../constants/MessagesTypes";
import {Form} from "@unform/web";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {dateCurrent, dateFirstDayCurrent, firstDayMonth} from "../../utils/date";
import swal from "@sweetalert/with-react";
import InputDate from "../../components/Form/InputDate";
import InputMask from "../../components/Form/InputMask";
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";

export default function Trading() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [deleteTrading, setDeleteTrading] = useState(null);
    const [total, setTotal] = useState(0);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const {coinType, profitObtainedToday, meta, amount, hideAmount} = useSelector((state) => state.BankReducer);
    const dispatch = useDispatch();
    const [payout, setPayout] = useState(100);
    const { t } = useTranslation();

    const {isActiveSignature} = useSelector(
        (state) => state.UserReducer
    );

    const formRef = useRef(null);

    const [filter, setFilter] = useState([
        {
            startDate: dateFirstDayCurrent(),
            endDate: dateCurrent(),
        }
    ]);

    useEffect(() => {
        setLoading(true);

        loadList({
            page: page,
            per_page: 25,
            typeWinOrLoss: 'WIN',
            startDate: filter[0].startDate,
            endDate: filter[0].endDate,
        }).then((data) => {
            if (!data || data === '') {
                return;
            }

            setTotal(data.data.total);
            setList(data.data);
            setLoading(false);
        });

        return function cleanup() {
            setList([]);
        };
    }, [page, deleteTrading === true, filter]);

    useEffect(() => {
        getBank().then((data) => {
            dispatch(actions.updateBank(data));
        });
    }, [deleteTrading === true]);

    async function handleSubmit(data) {
        try {

            formRef.current.setErrors({});
            const schema = Yup.object().shape({});

            await schema.validate(data, {
                abortEarly: false,
            });

            setFilter(
                [{
                    startDate: data.startDate,
                    endDate: data.endDate,
                }]
            );

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    const handleImportCSV = useCallback(() => {
        if (isActiveSignature) {
            history.push('/trading/import');
            return;
        }

        swal(
            {
                icon: "info",
                text: t('alert_signature'),
                buttons: [t('alert_signature_btn_close'), t('alert_signature_confirm')],
            }
        ).then((isConfirm) => {
            if (isConfirm) {
                history.push('/plans');
            }
        });

    }, [isActiveSignature, history]);

    function showImage(urlImage, currencyPair) {
        swal(
            {
                showCloseButton: true,
                className: 'trading-modal',
                confirmButtonText: 'Fechar',
                content: <div>
                    <h3 className={"font-weight-bolder"}>{currencyPair}</h3>
                    <br/>
                    <div className={"img-trading-modal"}>
                        <img src={urlImage} alt={'Imagem da negociação'}/>
                    </div>
                </div>,
            }
        );
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-11 col-md-12">
                <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col d-flex flex-column justify-content-center">
                                    <span className="m-0 font-weight-bold">
                                        <i className="fas fa-chart-bar"/> {t('trading_card_title')} <span className="badge badge-secondary">{total}</span>
                                    </span>
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <button
                                            onClick={() => {handleImportCSV();}}
                                            className={"btn btn-xs btn-secondary font-weight-bold mr-2 import-csv"}
                                        >
                                            <nobr>
                                                {t('strategy_btn_import_CSV')}
                                                {!isActiveSignature && <>
                                                    <span className="badge badge-success pl-2 ml-1 pr-2 font-weight-bolder">PRO</span>
                                                </>}
                                            </nobr>
                                        </button>
                                        <Link className="btn btn-xs btn-success font-weight-bold" to="/trading/new">
                                            <i className="fas fa-plus"/> {t('add')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-4 p-0">
                            {meta &&
                                <div className="row p-3 justify-content-center">
                                    {profitObtainedToday < parseFloat(meta.stopWin) && <>
                                        <div className="col-xl-4">
                                            <div className="card mb-4 h-100 text-center" style={{
                                                border: 'none',
                                            }}>
                                                <div className="col-12 card-plan pt-4 pb-4" style={{
                                                    border: '3px solid rgba(0, 0, 0, 0.07)',
                                                    borderRadius: '20px',
                                                }}>
                                                    <div>
                                                        <h6 className="lead font-weight-bold pricing-plan-title mb-4">{t('trading_goal_suggestions')}</h6>
                                                        <div className="row justify-content-center d-flex mb-4">
                                                            <div className="col-xs-12 col-md-4 col-xl-6 text-center align-items-center">
                                                                <Form onSubmit={() => {}}>
                                                                    <InputMask
                                                                        size="small"
                                                                        type="text"
                                                                        name="payout"
                                                                        id="payout"
                                                                        label="Payout"
                                                                        mask={'percent'}
                                                                        defaultValue={(payout ? payout : 100).toFixed(2)}
                                                                        InputProps={{
                                                                            endAdornment: <><Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                                                                <IconButton aria-label="directions" onClick={() => {
                                                                                    setPayout(parseFloat(document.getElementById('payout').value))
                                                                                }}>
                                                                                    <i className="fa fa-check text-xs" aria-hidden="true"></i>
                                                                                </IconButton></>,
                                                                        }}
                                                                    />
                                                                </Form>
                                                            </div>
                                                        </div>
                                                        {Array.from({ length: 5 }, (_, index) => (
                                                            <p className="small" key={index}>
                                                                {payout && <>
                                                                    {t('trading_goal_inputs', {
                                                                        'qtdeInput': index + 1,
                                                                        'value': `${coinType} ${currencyFormat(parseFloat(profitObtainedToday ? Math.abs(parseFloat(profitObtainedToday) - parseFloat(meta.stopWin)) :  parseFloat(meta.stopWin)) / ((index + 1) * (payout/100)))}`
                                                                    })}<br/>
                                                                </>}
                                                            </p>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                    <div className="col-xl-4">
                                        <div className="card mb-4 h-100 text-center" style={{
                                            border: 'none',
                                        }}>
                                            <div className="col-12 card-plan pt-4 pb-4" style={{
                                                border: '3px solid rgba(0, 0, 0, 0.07)',
                                                borderRadius: '20px',
                                            }}>
                                                <div>
                                                    <p className="font-weight-bold pricing-plan-amount">
                                                        <nobr><span className="h1 font-weight-bolder text-success">
                                                        {coinType} {currencyFormat(parseFloat(profitObtainedToday ? Math.abs(parseFloat(profitObtainedToday) - parseFloat(meta.stopWin)) :  parseFloat(meta.stopWin)))}
                                                    </span></nobr>
                                                    </p>
                                                    <h6 className="lead font-weight-bold pricing-plan-title">{t('trading_goal_remaining_value')}</h6>
                                                    <p className="small">
                                                        {t('trading_goal_day')} {coinType} {currencyFormat(parseFloat(meta ? meta.stopWin : 0))}<br/>
                                                        {t('trading_goal_day_profit_prejudice')} {coinType} {currencyFormat(parseFloat(profitObtainedToday ? profitObtainedToday : 0))}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {!hideAmount &&
                                        <div className="col-xl-4">
                                            <div className="card mb-4 h-100 text-center" style={{
                                                border: 'none',
                                            }}>
                                                <div className="col-12 card-plan pt-4 pb-4" style={{
                                                    border: '3px solid rgba(0, 0, 0, 0.07)',
                                                    borderRadius: '20px',
                                                }}>
                                                    <div>
                                                        <p className="font-weight-bold pricing-plan-amount">
                                                            <nobr><span className={amount >= 0 ? "h1 font-weight-bolder text-success" : "h1 font-weight-bolder text-danger"}>
                                                        {coinType} {currencyFormat(amount)}
                                                    </span></nobr>
                                                        </p>
                                                        <h6 className="lead font-weight-bold pricing-plan-title">{t('trading_goal_value_current_bank')}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            <div className="row p-3">
                                <div className="col-12">
                                    <div className="bg-gray-100">
                                        <div className="row">
                                            <div className="col">
                                                <Form ref={formRef} onSubmit={handleSubmit} className="p-3">
                                                    <div className="row">
                                                        <div className="col-md-12 pull-left d-flex align-items-center ml-1">
                                                            <div className="font-weight-bold mb-1"><nobr><i className="fas fa-funnel-dollar"></i> {t('filter')}</nobr></div>
                                                        </div>
                                                        <div className="col-md-4 col-xs-12 mt-3">
                                                            <InputDate
                                                                name="startDate"
                                                                label={t('filter_start_date')}
                                                                value={firstDayMonth()}
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-xs-12 mt-3">
                                                            <InputDate
                                                                name="endDate"
                                                                label={t('filter_end_date')}
                                                                value={new Date()}
                                                            />
                                                        </div>
                                                        <CustomButton
                                                            className="btn btn-primary float-right font-weight-bold text-capitalize mt-3 ml-3 mr-3"
                                                            type="submit"
                                                            startIcon={<AiOutlineSearch/>}
                                                        >
                                                            {t('filter_btn_submmit')}
                                                        </CustomButton>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            {loading ?
                                <div className="row pl-4 pr-4 pb-4 pt-0">
                                    <div className="col">
                                        <CustomButton
                                            className="btn btn-primary col-12 text-capitalize font-weight-bold"
                                            loading={loading}
                                            type="button"
                                        >
                                            {t('loading')}
                                        </CustomButton>
                                    </div>
                                </div> :
                                <>
                                    {list && list.data && list.data.length ?
                                        <TableContainer component={Paper}>
                                            <Table sx={{minWidth: 650}} aria-label="caption table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className="font-weight-bold text-muted" align="center"></TableCell>
                                                        <TableCell className="font-weight-bold text-muted" align="center">{t('trading_table_col_date')}</TableCell>
                                                        <TableCell className="font-weight-bold text-muted" align="center">{t('trading_table_col_currency_pair')}</TableCell>
                                                        <TableCell className="font-weight-bold text-muted" align="center">{t('trading_table_col_strategy')}</TableCell>
                                                        <TableCell className="font-weight-bold text-muted" align="center">{t('trading_table_col_value_invested')}</TableCell>
                                                        <TableCell className="font-weight-bold text-muted" align="center">{t('trading_table_col_profit_prejudice')}</TableCell>
                                                        <TableCell className="font-weight-bold text-muted" align="left">{t('trading_table_col_options')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {list.data.map((row) => (
                                                        <TableRow key={row.id} style={{/*
                                                                row.profitObtained > 0 ? { background: 'rgb(191 245 226)'} :
                                                                !row.profitObtained ? { background: 'rgb(248 249 252)'} :
                                                                row.profitObtained < 0 ? { background: 'rgb(251 223 221)'} : {}
                                                            }*/}}
                                                        >
                                                            <TableCell align="center">
                                                                {row.profitObtained > 0 && <span className="badge badge-success">Win</span>}
                                                                {!row.profitObtained && <span className="badge badge-light">{t('draw')}</span>}
                                                                {row.profitObtained < 0&& <span className="badge badge-danger">Loss</span>}
                                                            </TableCell>
                                                            <TableCell align="center" component="th" scope="row">
                                                                {new Date(row.created.date).toLocaleDateString()}
                                                                <br/>
                                                                <small>{row.observation ? t('trading_table_observation')+": "+ row.observation : ''}</small>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {row.currencyPair} {row.direction && row.direction === 'COMPRA' && <><WiDirectionUp size={25}/></>}{row.direction && row.direction === 'VENDA' && <><WiDirectionDown size={38}/></>}
                                                            </TableCell>
                                                            <TableCell align="center">{row.strategy ? row.strategy.name : t('text_not_info')}</TableCell>
                                                            <TableCell align="center">{ coinType } {currencyFormat(row.investmentValue)}</TableCell>
                                                            <TableCell align="center" className="font-weight-bold">
                                                                {row.profitObtained > 0 && <span className="text-success">{coinType} {currencyFormat(row.profitObtained)}</span>}
                                                                {!row.profitObtained && <span>{coinType} {currencyFormat(row.profitObtained)}</span>}
                                                                {row.profitObtained < 0 && <span className="text-danger">{coinType} {currencyFormat(row.profitObtained)}</span>}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Link to={`/trading/${row.id}/edit`}>
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                    >
                                                                        <MdEdit/>
                                                                    </IconButton>
                                                                </Link>
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => {
                                                                        setDeleteTrading(row.id)
                                                                    }}
                                                                >
                                                                    <MdDeleteForever/>
                                                                    {row.id === deleteTrading && <AlertDelete
                                                                        setSuccessDelete={setDeleteTrading}
                                                                        urlDelete={process.env.REACT_APP_API_ENDPOINT+`/api/trading/delete/${row.id}`}
                                                                    />}
                                                                </IconButton>
                                                                {row.image && <>
                                                                    <IconButton
                                                                        aria-label="view-image"
                                                                        onClick={() => {
                                                                            showImage(`${process.env.REACT_APP_API_ENDPOINT}/${row.image}`, row.currencyPair);
                                                                        }}
                                                                    >
                                                                        <MdImage/>
                                                                    </IconButton>
                                                                </>}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableCell colSpan="5">
                                                        <span className="text-muted text-info float-left pl-4 pt-2">
                                                            {t('table_pagination_text', {
                                                                'page': page,
                                                                'totalPage': list.total_page + 1
                                                            })}
                                                        </span>
                                                        <button
                                                            style={{ cursor: !list.next_page ? 'not-allowed' : 'pointer'}}
                                                            type="button"
                                                            onClick={() => setPage(page + 1)}
                                                            disabled={!list.next_page}
                                                            className="btn btn-light mr-3 float-right">
                                                            {t('table_pagination_next')} <IoIosArrowForward
                                                        />
                                                        </button>
                                                        <button
                                                            style={{ cursor: page <= 1 ? 'not-allowed' : 'pointer'}}
                                                            type="button"
                                                            onClick={() => setPage(page - 1)}
                                                            disabled={page <= 1}
                                                            className="btn btn-light mr-3 float-right"
                                                        >
                                                            <IoIosArrowBack/> {t('table_pagination_back')}
                                                        </button>
                                                    </TableCell>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer> :
                                        <div className="row pl-4 pr-4 pb-4 pt-0">
                                            <div className="col">
                                                <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}