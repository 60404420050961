import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    timeout: 4000,
});

api.interceptors.request.use(
    (request) => {
        if (request.headers) {
            if (request.headers.auth) {
                const auth_token = localStorage.getItem('@user_token');
                request.headers.authorization = `Bearer ${auth_token}`;

                delete request.headers.auth;
            } else {
                delete request.headers.auth;
            }
        }

        return request;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    async response => {
        return response;
    },
    async error => {
        if ((error.response.status === 401 || error.response.status === 403) && window.location.href !== process.env.REACT_APP_API_ENDPOINT) {
            localStorage.removeItem('@user_token');

            const username = localStorage.getItem('username');
            const password = localStorage.getItem('password');
            if (username && password) {
                localStorage.setItem('redirectUrl', window.location.pathname);
                window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    }
);

export default api;
