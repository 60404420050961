const theme = {
  colors: {
    primary: '#2e3851',
    secondary: '#f3f3',
    tertiary: '#f8f9fa',
    light: '#FFFFFF',
    background: '#F2F2F2',
    inputs: '#fff',
  },
  fonts: {
    families: {
      primary: 'Roboto',
    },
    heights: {
      primary: '19px',
    },
    sizes: {
      primary: '16px',
      secondary: '32px',
      tertiary: '18px',
    },
    weights: {
      primary: 'bold',
      secondary: '700',
      normal: '500',
      light: '400',
    },
    spacings: {
      primary: '0.26px',
    },
  },
  borders: {
    primary: '4px',
  },
  breakpoints: {
    lg: 1450,
    md: 1100,
    sm: 950,
    xs: 470,
    xxs: 410,
  },
};

export default theme;
