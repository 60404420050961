import React from "react";
import {Container} from "./styles";
import Soros from "./Soros";
import Martingale from "./Martingale";
import JurosCompostos from "./JurosCompostos";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Porcentagem from "./Porcentagem";

export default function Calculadora() {
    const { t } = useTranslation();
    const {isActiveSignature} = useSelector(
        (state) => state.UserReducer
    );

    return (
        <>
            {isActiveSignature && <>
                <Container className="row justify-content-center">
                    <div className="col-lg-12 col-xl-8">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <div className="row">
                                    <div className="col d-flex flex-column justify-content-center">
                                <span className="m-0 font-weight-bold">
                                    <i className="fa fa-calculator" aria-hidden="true"></i> {t('calc_card_title')}
                                </span>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-4 p-4">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="juros-tab" data-toggle="tab" href="#porcentagem" role="tab"
                                           aria-controls="porcentagem" aria-selected="false">Porcentagem</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="juros-tab" data-toggle="tab" href="#juros" role="tab"
                                           aria-controls="juros" aria-selected="false">{t('calc_tab_compound_interest')}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="martingale-tab" data-toggle="tab" href="#martingale" role="tab"
                                           aria-controls="martingale" aria-selected="false">{t('calc_tab_martingale')}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="soros-tab" data-toggle="tab" href="#soros" role="tab"
                                           aria-controls="soros" aria-selected="false">{t('calc_tab_soros')}</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active pt-4" id="porcentagem" role="tabpanel"
                                         aria-labelledby="porcentagem-tab">
                                        <Porcentagem/>
                                    </div>
                                    <div className="tab-pane fade pt-4" id="juros" role="tabpanel"
                                         aria-labelledby="juros-tab">
                                        <JurosCompostos/>
                                    </div>
                                    <div className="tab-pane fade pt-4" id="martingale" role="tabpanel"
                                         aria-labelledby="martingale-tab">
                                        <Martingale/>
                                    </div>
                                    <div className="tab-pane fade pt-4" id="soros" role="tabpanel"
                                         aria-labelledby="soros-tab">
                                        <Soros/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </>}
        </>
    );
}