import styled from 'styled-components';

export const ContainerNews = styled.div`
  iframe {
    width: 100%;
  }
  
  .legend ul {
    list-style-type: none;
  }
  
  .poweredBy {
    span {
      font-size: 0.85rem;
      color: #333333;
      text-decoration: none;
      
      .underline_link {
        padding-left: 5px;
        color: #06529D; 
        font-weight: bold;
      }
    }
  }
`;
