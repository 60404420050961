import api from '../api';
import {toast} from "react-toastify";
import messageTypes from "../../constants/MessagesTypes";

export async function getDisciplinas(data) {
    return api.get(`/api/disciplina/list`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return[];
    });
}

export async function getDisciplina($id) {
    try {

        const response = await api.get(`/api/disciplina/${$id}/find`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                auth: true
            },
        });

        return response.data.data ? response.data.data : {};

    } catch (err) {
        toast.error(messageTypes.ERROR_LOAD);
        throw err;
    }
}

export function edit($id, $disciplina) {
    return api.post(`/api/disciplina/edit/${$id}`, $disciplina, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export function save($disciplina) {
    return api.post('/api/disciplina/new', $disciplina, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export async function getReport(data) {
    return api.get(`/api/disciplina/report`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}